import React from 'react';

import './NoAccess.css';

function NoAccess() {
  return (
    <div className="BrokenPage">
      <div className="ui image">
        <img alt="Broken page" src="/assets/images/no-access.svg" />
        <div className="text">
          <div className="ui large green button" onClick={() => {
            window.location.replace(window.location.origin)
          }}>Вернуться на главную</div>
        </div>
      </div>
    </div>
  );
}

export default NoAccess;
