import gql from 'graphql-tag';

export const SKIP_DISH_RATING = gql`
    mutation skipDishRating {
        skipDishRating 
    }
`;


export const RATE_DISHES = gql`
    mutation rateDishes($rates: [DishRate]) {
        rateDishes(rates: $rates) 
    }
`;