import gql from 'graphql-tag';
import { FRAGMENT_VACATION_INFO, FRAGMENT_SICK_INFO } from '../';


export const SCHEDULE_VACATIONS = gql`
    query scheduleVacations($startAt: String!, $endAt: String!) {
        scheduleVacations(startAt: $startAt, endAt: $endAt) {
            vacations {...VacationInfo}
            sick {...SickInfo}
        }
    }
    ${FRAGMENT_VACATION_INFO}
    ${FRAGMENT_SICK_INFO}
`;
