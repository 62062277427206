import gql from 'graphql-tag';
import { FRAGMENT_SETTINGS_INFO } from '../';

export const SETTINGS_ORDER_TIME = gql`
    query orderTime {
        orderTime {
            ...SettingsInfo
        }
    }
    ${FRAGMENT_SETTINGS_INFO}
`;

export const SETTINGS = gql`
    query settings {
        settings {
            ...SettingsInfo
        }
    }
    ${FRAGMENT_SETTINGS_INFO}
`;
