import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import moment from 'moment';
import 'moment/locale/ru';

import './ProfileSalaryFormula.css';
import { SALARY_STATISTIC } from '../../../gql';
import { Loader, MonthsSelector, YearsSelector } from '../../';
import { caseOfMonth } from '../../../helpers';

const months = moment.localeData('ru').months();
const currentMonth = moment().month();
const currentYear = moment().year();

class ProfileSalaryFormula extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formulasData: {
        countOfDays: 0,
        vacationDays: 0,
        sickDays: 0,
        lunch: 0,
        bonus: 0,
        rate: 1,
      },
      selectedMonth: currentMonth,
      selectedYear: currentYear,
    };
  }

  createFormulaData = ({
    daysOfMonth: { monthWorkdays } = 30,
    vacations: { month: vacations } = 0,
    sicks: { month: sicks } = 0,
    orders: { month: orders } = 0,
  } = {}) => {
    const workRate = (monthWorkdays - vacations - sicks) / monthWorkdays;
    const sickRate = sicks / (2 * monthWorkdays);
    const rate = Math.round((workRate + sickRate) * 100) / 100;
    const total = orders.totalCost - orders.payByCompany + orders.additionalCost;
    return ({
      countOfDays: monthWorkdays,
      vacationDays: vacations,
      sickDays: sicks,
      lunch: Intl.NumberFormat().format(orders.totalCost - orders.payByCompany),
      additionalLunch: orders.additionalCost,
      total,
      bonus: 0,
      rate,
    });
  }
  onChangeSelectedYear = (year) => {
    if (year === this.state.selectedYear) return;
    this.setState({ selectedYear: year })
  }
  onChangeSelectedMonth = (month) => {
    if (month === this.state.selectedMonth) return;
    this.setState({ selectedMonth: month })
  }

  render() {
    return (
      <Query
        query={SALARY_STATISTIC}
        variables={{ id: this.props.profileOwner.id, year: this.state.selectedYear, month: this.state.selectedMonth }}
      >
        {({loading, error, data}) => {
          if (loading) return <Loader />;
          if (error) throw new Error(error);

          const formulasData = this.createFormulaData(data.salaryStatistic);
          return (
            <div className="ProfileSalaryFormula">
              <div className="ui grid">
                <div className="sixteen wide row date-selector-block">
                  <div>
                    <label><b>За год</b></label>
                    <YearsSelector year={this.state.selectedYear} changeYear={this.onChangeSelectedYear} className="small-dropdown" />
                  </div>
                  <div>
                    <label><b>За месяц</b></label>
                    <MonthsSelector month={this.state.selectedMonth} changeMonth={this.onChangeSelectedMonth} className="small-dropdown" />
                  </div>

                </div>
                <div className="ui column sixteen wide">
                  <div><b>В {caseOfMonth(months[this.state.selectedMonth])} месяце {this.state.selectedYear} года:</b></div>
                  <ul>
                    <li>Количество рабочих дней: <b>{formulasData.countOfDays}</b></li>
                    <li>Количество дней в отпуске: <b>{formulasData.vacationDays}</b></li>
                    <li>Количество дней на больничном: <b>{formulasData.sickDays}</b></li>
                    <li>
                      Списание за обеды: <b>
                        {formulasData.lunch}p
                        {formulasData.additionalLunch > 0 && <b className="red"> +{formulasData.additionalLunch}p</b>}
                      </b>
                    </li>
                    <li>Премия: <b>{formulasData.bonus}р</b></li>
                    <li>Коэффициент для умножения на ставку: <b>{formulasData.rate}</b></li>
                  </ul>
                  <div><b>Результат: Ставка
                    * {formulasData.rate} {formulasData.lunch > 0 && `- ${formulasData.total}p`} {formulasData.bonus > 0 && `+ ${formulasData.bonus}p`}</b>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

ProfileSalaryFormula.propTypes = {
  profileOwner: PropTypes.shape().isRequired,
};

ProfileSalaryFormula.defaultProps = {
};

export default ProfileSalaryFormula;
