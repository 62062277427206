import PropTypes from 'prop-types';
import React from 'react';

import { Column, Table, AutoSizer, SortDirection, SortIndicator } from 'react-virtualized';
import 'react-virtualized/styles.css';
import { sortList } from '../../helpers';
import './Table.css';

class VirtualizedSortableTable extends React.PureComponent {
  static propTypes = {
    list: PropTypes.arrayOf(PropTypes.shape).isRequired,
    tableHeader: PropTypes.arrayOf(PropTypes.shape),
    disableHeader: PropTypes.bool,
    onRowClick: PropTypes.func,
    rowClassName: PropTypes.func,
    headerHeight: PropTypes.number,
    rowHeight: PropTypes.number,
  };
  static defaultProps = {
    tableHeader: [],
    disableHeader: false,
    onRowClick: () => {},
    rowClassName: () => {},
    headerHeight: 30,
    rowHeight: 40,
  };

  constructor(props) {
    super(props);

    this.state = {
      sortBy: '',
      sortDirection: SortDirection.ASC,
      sortedList: props.list,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if(nextProps.list.length) {
      return { sortedList: sortList({ sortBy: prevState.sortBy, sortDirection: prevState.sortDirection, list: nextProps.list }) };
    } else {
      return { sortedList: [] };
    }
  }

  sort = ({ sortBy, sortDirection }) => {
    const sortedList = sortList({ sortBy, sortDirection, list: this.state.sortedList });
    this.setState({ sortBy, sortDirection, sortedList });
  }

  rowClassName = ({ index, rowData }) => {
    if (index < 0) {
      return 'headerRow';
    }
    const handleClassName = this.props.rowClassName(this.state.sortedList[index])
    if (handleClassName) {
      return handleClassName;
    }
    return index % 2 === 0 ? 'evenRow' : 'oddRow';
  }

  noRowsRenderer = () => <div className="noRows">Нет записей</div>;

  renderDefaultsColumns = () => {
    const columns = Object.keys(this.props.list[0]).map(e => !e.includes('__') && <Column
      label={e}
      dataKey={e}
      width={300}
    />);
    return columns;
  }

  renderHandleColumns = () => {
    const columns = this.props.tableHeader.map(e => <Column {...e} />);
    return columns;
  }

  render() {
    const {
      sortBy,
      sortDirection,
      sortedList,
    } = this.state;

    const {
      headerHeight,
      rowHeight,
    } = this.props;
    return (

      <AutoSizer >
        {({ width, height }) => (
          <Table
            className="Table"
            width={width}
            height={height}
            headerHeight={headerHeight}
            rowHeight={rowHeight}
            rowCount={sortedList.length}
            rowGetter={({ index }) => sortedList[index]}
            sort={this.sort}
            sortBy={sortBy}
            sortDirection={sortDirection}
            onRowClick={data => this.props.onRowClick(data)}
            rowClassName={this.rowClassName}
            noRowsRenderer={this.noRowsRenderer}
            disableHeader={this.props.disableHeader}
            headerClassName="headerColumn"
          >
            {this.props.tableHeader.length > 0 ?
              this.renderHandleColumns()
              :
              this.renderDefaultsColumns()
            }
          </Table>
        )}
      </AutoSizer>

    );
  }

}
export default VirtualizedSortableTable;
