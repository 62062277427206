import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ApolloConsumer } from 'react-apollo';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

import { getErrorMessages, showErrors, setDataToStorage } from '../../helpers';
import { LOGIN } from '../../gql';
import './Login.css';

class Login extends Component {
  static propTypes = {
    history: PropTypes.shape().isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      formError: new Map(),
      form: new Map(),
      isLogging: false,
    };
  }

  onLogin = (data) => {
    this.setState({ isLogging: false })
    if (!data.login) {
      const { formError } = this.state;
      formError.set('response', 'Неверный логин или пароль');
      this.captcha.reset();
      this.setState({ formError });
      return;
    }
    const {
      firstName, lastName, id, phone,
    } = data.login;
    setDataToStorage({
      token: data.login.token,
      user: JSON.stringify({
        firstName, lastName, id, phone,
      }),
    });
    this.login.value = '';
    this.password.value = '';
    this.props.history.replace('/');
  }

  onSubmit = async (client, reCaptcha) => {
    setDataToStorage({
      token: '',
      user: '',
    });
    try {
      const { data } = await client.query({
        query: LOGIN,
        variables: {
          reCaptcha,
          login: this.login.value,
          password: this.password.value,
        },
      });
      this.onLogin(data);
    } catch (error) {
      const errorMessage = getErrorMessages(error);
      const { formError } = this.state;
      formError.set('response', errorMessage[0]);
      this.captcha.reset();
      this.setState({ formError, isLogging: false });
    }
  }

  onCaptcha = () => {
    const { formError } = this.state;
    let isError = false;
    if (!this.login.value) {
      formError.set('login', 'Введите логин');
      isError = true;
    }
    if (!this.password.value) {
      formError.set('password', 'Введите пароль');
      isError = true;
    }
    if (isError) {
      this.setState({ formError });
    } else {
      this.setState({ isLogging: true })
      this.captcha.execute();
    }
  }

  isSpaceEnable = value => value !== value.replace(/\s/g, '');

  validate = ({ target: { name, value } }) => {
    const { formError, form } = this.state;
    form.set(name, value);
    formError.has('response') && formError.delete('response');

    if (!value) {
      formError.has(name) && formError.delete(name);
      form.delete(name);
    } else if (this.isSpaceEnable(value)) {
      formError.set(name, 'Пожалуйста не используйте пробелы.');
    } else {
      formError.has(name) && formError.delete(name);
    }

    this.setState({ form, formError });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.onCaptcha();
  }

  render() {
    return (
      <ApolloConsumer>
        {client => (
          <div className="Login">
            <div className="ui center aligned grid">
              <div className="column">
                <form
                  className={`ui large form ${this.state.formError.size && 'error'}`}
                  onSubmit={e => this.handleSubmit(e)}
                >
                  <div className="ui segment">
                    <h2 className="ui">
                      Войдите в ваш аккаунт
                    </h2>
                    <div className={`field ${this.state.formError.has('login') && 'error'}`}>
                      <label className="leftLabel">Логин или номер телефона(без восьмерки)</label>
                      <div className="ui left icon input">
                        <i className="user icon" />
                        <input
                          type="text"
                          name="login"
                          placeholder="Логин или номер телефона"
                          ref={(node) => {
                          this.login = node;
                        }}
                          onChange={this.validate}
                        />
                      </div>
                    </div>
                    <div className={`field ${this.state.formError.has('password') && 'error'}`}>
                      <label className="leftLabel">Пароль</label>
                      <Link to="/forgot_password" className="rightLabel">Забыли пароль?</Link>
                      <div className="ui left icon input">
                        <i className="lock icon" />
                        <input
                          type="password"
                          name="password"
                          ref={(node) => {
                          this.password = node;
                        }}
                          placeholder="Пароль"
                          onChange={this.validate}
                        />
                      </div>
                    </div>
                    <button
                      className={`ui fluid large green submit button ${this.state.isLogging && 'loading'} ${(this.state.isLogging || this.state.formError.size) && 'disabled'}`}
                      type="submit"
                    >
                      Войти
                    </button>
                    <div className="ui signup-link">
                      Впервые здесь? <Link to="/signup">Зарегистрируйтесь</Link>
                    </div>
                  </div>

                  <div className="ui error message">
                    <ul className="list">
                      {showErrors(this.state.formError)}
                    </ul>
                  </div>
                  <ReCAPTCHA
                    ref={(el) => { this.captcha = el; }}
                    size="invisible"
                    sitekey="6LfVi1YUAAAAAC7MlG-PIcC1oqdnNeHOz81-qhxP"
                    onChange={reCaptcha => this.onSubmit(client, reCaptcha)}
                  />
                </form>


              </div>
            </div>
          </div>
        )}
      </ApolloConsumer>
    );
  }
}

export default Login;
