import React, { memo, useState } from "react";
import './UserCard.scss';
import { Mutation, Query } from "react-apollo";
import Modal from "../Modal";
import { REMOVE_USER_STAR } from "../../gql/bestUser/mutation";
import { BEST_USER } from "../../gql";

const UserCard = ({ user, editable, id, blockTitle }) => {
  const [active, setActive] = useState(false);
  const onSubmit = (removeStarUser) => {
    removeStarUser({
      variables: {
        id,
      },
      refetchQueries: [
        { query: BEST_USER },
      ],
    });
    setActive((prev) => !prev)
  }

 const getAvatarUrl = (url, width = 96) => {
    if (url == null) return '/assets/images/avatars/defaultAvatar.png';
    if (!url.includes('googleusercontent.com')) {
      return url;
    }
    const [rawUrl] = url.split('=');
    // https://developers.google.com/people/image-sizing
    return `${rawUrl}=w${width}`;
  };
  

  return (
  <div key={user.id} className='handbook-contacts__item'>
    
     {editable &&
      <>  
        <button className="ui red button" onClick={()=> setActive((prev)=>!prev)}><i className="icon button remove" /></button>
        <Mutation mutation={REMOVE_USER_STAR}>
          {(removeStarUser, { loading, data, error }) => {
            return (
              <Modal
                active={active}
                onClose={() => setActive((prev) => !prev)}
                onSuccess={() => onSubmit(removeStarUser)}
                header="Удалить работника из списка"
                body={<p>После подтвердения работник будет удален из блока <b>{blockTitle}</b>. </p>}
                size="small"
              />
            );
          }}
        </Mutation>
      </>
     }
    <img className="handbook-contacts__item-image" src={getAvatarUrl(user.avatarUrl, 200)} alt={user.firstName} referrerPolicy="no-referrer" />
    <div className="handbook-contacts__item-content">
      <div className="handbook-contacts__item-name">{user.firstName} {user.lastName}</div>
    </div>
  </div>)
}

export default memo(UserCard);