import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { EventTypesSelector, EventView, UserContext, Checkbox } from '../../index';
import { getErrorMessages, showErrors } from '../../../helpers';
import { PAGINATION_EVENTS, CREATE_EVENT } from '../../../gql';
import formValidator from '../../../hoc/formValidator';

import './CreateEventForm.css';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/javascript/javascript';

class CreateEventForm extends Component {
  static propTypes = {
    changeContent: PropTypes.func.isRequired,
    form: PropTypes.shape(),
    event: PropTypes.shape(),
  }
  static defaultProps = {
    form: {
      validate: () => {},
      onError: () => {},
      updateState: () => {},
      data: {},
      errors: {},
    },
    event: undefined,
  }

  constructor(props) {
    super(props);
    this.state = {
      checkbox: {
        editor: !!props.event,
      },
      subData: {
        guests: [],
        editor: props.event ? props.event.description : '',
      },
      tabIndex: 0,
      description: '',
      isEventSaving: false,
    };
    this.eventView = React.createRef();
  }

  componentDidMount() {
    const form = new Map();
    const defaultEvent = {
      minParticipants: '',
      eventTypeId: '1',
      eventStartAt: moment(),
      endRegistrationsAt: moment().subtract(1, 'hour'),
      name: '',
    };
    const { event = defaultEvent } = this.props;

    form.set('name', event.name);
    form.set('minParticipants', event.minParticipants);
    form.set('eventTypeId', event.eventTypeId);
    form.set('eventStartAt', moment(event.eventStartAt));
    form.set('endRegistrationsAt', moment(event.endRegistrationsAt));
    this.props.form.updateState({ form });
  }

  onSubmit = (e, createRequest) => {
    e.preventDefault();
    const { data, errors } = this.props.form;
    if (errors.size) return;
    this.setState({ isEventSaving: true });

    createRequest({
      variables: {
        event: {
          id: this.props.event && this.props.event.id,
          name: data.get('name'),
          description: this.state.subData.editor || process(this.eventView.current.description.current.outerHTML),
          eventTypeId: data.get('eventTypeId'),
          endRegistrationsAt: data.get('endRegistrationsAt'),
          eventStartAt: data.get('eventStartAt'),
          minParticipants: Number(data.get('minParticipants')),
        },
      },
    });
  }

  handleChange = (name, value) => {
    this.props.form.validate({ target: { name, value } });
  }

  renderInfoBlock = () => (
    <div className="ui floating message info">

      <p>События могут быть одним из нескольких типов: спорт, корпоративчик, и что-то другое</p>
      <p>В оформлении события вы можете использовать HTML и вёрстку <a href="https://semantic-ui.com/">SemanticUI</a></p>
      <p>Есть ограничение на минимальное количество человек. Стоит ли организовывать событие, если вам достаточно троих чтобы сообразить ;-)</p>

    </div>
  )

  getObjectForm =(owner) => {
    const obj = {
      owner,
      participants: [],
    };
    for (const [k, v] of this.props.form.data) {
      obj[k] = v;
    }
    obj.subData = this.state.subData;
    return obj;
  }

  saveSubData = ({ target: { name, value } }) => {
    if (name) {
      this.setState(prevState => ({
        ...prevState,
        subData: {
          ...prevState.subData,
          [name]: value,
        },
      }));
    }
  }

  renderMainInfoBlock = () => {
    const { form } = this.props;

    return (
      <div className="ui segments flex-1-to-4">
        <div className="ui segment">
          <h3 className="margin-15">Основная информация</h3>
        </div>

        <div className="ui segment">
          <div className={`field ${form.errors.has('name') && 'error'}`}>
            <label htmlFor="name">Название</label>
            <div className="ui left input">
              <input
                placeholder="Будь креативным"
                type="text"
                name="name"
                value={form.data.get('name')}
                onChange={form.validate}
              />
            </div>
          </div>

          <div className={`field ${form.errors.has('eventTypeId') && 'error'}`}>
            <label htmlFor="eventTypeId">Тип мероприятия</label>
            <div className="ui left input ">
              <EventTypesSelector defaultValue={form.data.get('eventTypeId')} changeEventType={eventType => this.handleChange('eventTypeId', eventType.id)} />
            </div>
          </div>

          <div className={`field ${form.errors.has('endRegistrationsAt') && 'error'}`}>
            <label htmlFor="endRegistrationsAt">Дата окончания регистрации</label>
            <div className="ui left input datepicker">
              <DatePicker
                selected={form.data.get('endRegistrationsAt')}
                onChange={value => this.handleChange('endRegistrationsAt', value)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={30}
                dateFormat="LLL"
                timeCaption="time"
              />
            </div>
          </div>

          <div className={`field ${form.errors.has('eventStartAt') && 'error'}`}>
            <label htmlFor="eventStartAt">Дата проведения события</label>
            <div className="ui left input datepicker">
              <DatePicker
                selected={form.data.get('eventStartAt')}
                onChange={value => this.handleChange('eventStartAt', value)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={30}
                dateFormat="LLL"
                timeCaption="time"
              />
            </div>
          </div>

          <div className={`field ${form.errors.has('days') && 'minParticipants'}`}>
            <label htmlFor="minParticipants">Минимальное количество человек</label>
            <div className="ui left input">
              <input
                type="number"
                name="minParticipants"
                placeholder="7"
                value={form.data.get('minParticipants')}
                onChange={form.validate}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderGuest = (guest, index) => (
    <div className="flex-column padding-between" key={`guest${index}`}>
      <div className="ui margin-bottom-5 input ">
        <input
          placeholder={`Ссылка на картинку (Гость ${index + 1})`}
          type="text"
          name="guests"
          value={guest.link}
          onChange={({ target: { name, value } }) => {
            const { guests } = this.state.subData;
            guests.push({});
            guests[index].link = value;
            if (!Object.keys(guests[guests.length - 1]).length) guests.length -= 1;
            this.saveSubData({ target: { name, value: guests } });
          }}
        />
      </div>
      <div className="ui left input ">
        <input
          placeholder={`Фамилия Имя (Гость ${index + 1})`}
          type="text"
          name="guests"
          value={guest.name}
          onChange={({ target: { name, value } }) => {
            const { guests } = this.state.subData;
            guests.push({});
            guests[index].name = value;
            if (!Object.keys(guests[guests.length - 1]).length) guests.length -= 1;
            this.saveSubData({ target: { name, value: guests } });
          }}
        />
      </div>
    </div>
  )

  handleChangeCheckbox = (name) => {
    this.setState(prevState => ({
      ...prevState,
      checkbox: {
        ...prevState.checkbox,
        [name]: !prevState.checkbox[name],
      },
    }));
  }

  renderSubInfoBlock = () => {
    const { form } = this.props;
    const { subData, checkbox } = this.state;

    return (
      <div className={`ui segments ${this.state.checkbox.editor && 'custom-disabled disabled-background'}`}>
        <div className="ui segment">
          <h3 className="margin-15">Описание события</h3>
        </div>
        <div className="ui segment flex-1-to-4">

          <div className={`field ${!checkbox.imageLink && 'custom-disabled'}`}>
            <Checkbox onChange={() => this.handleChangeCheckbox('imageLink')} />
            <label className="ui" htmlFor="link">Картинка</label>
            <div className="ui left input ">
              <input
                placeholder="Ссылка на картинку"
                type="text"
                name="imageLink"
                value={subData.imageLink}
                onChange={this.saveSubData}
              />
            </div>
          </div>

          <div className={`field ${!checkbox.videoLink && 'custom-disabled'}`}>
            <Checkbox onChange={() => this.handleChangeCheckbox('videoLink')} />
            <label className="ui" htmlFor="link">Видео</label>
            <div className="ui left input ">
              <input
                placeholder="Ссылка на видео"
                type="text"
                name="videoLink"
                value={subData.videoLink}
                onChange={this.saveSubData}
              />
            </div>
          </div>

          <div className={`field ${!checkbox.where && 'custom-disabled'}`}>
            <Checkbox onChange={() => this.handleChangeCheckbox('where')} />
            <label className="ui" htmlFor="link">Где?</label>
            <div className="ui left input ">
              <input
                placeholder="А действительно где?"
                type="text"
                name="where"
                value={subData.where}
                onChange={this.saveSubData}
              />
            </div>
          </div>

          <div className={`field ${!checkbox.why && 'custom-disabled'}`}>
            <Checkbox onChange={() => this.handleChangeCheckbox('why')} />
            <label className="ui" htmlFor="link">Зачем?</label>
            <div className="ui left input ">
              <input
                placeholder="Веские причины"
                type="text"
                name="why"
                value={subData.why}
                onChange={this.saveSubData}
              />
            </div>
          </div>

          <div className={`field ${!checkbox.description && 'custom-disabled'}`}>
            <Checkbox
              onChange={() => this.handleChangeCheckbox('description')}
              style={{ alignSelf: 'self-start', marginTop: '10px' }}
            />
            <label className="label-on-top ui" htmlFor="link">Описание</label>
            <div className="ui left input ">
              <textarea
                placeholder="Описание"
                type="text"
                name="description"
                rows={3}
                value={subData.description}
                onChange={this.saveSubData}
              />
            </div>
          </div>

          <div className={`field ${!checkbox.guests && 'custom-disabled'}`}>
            <Checkbox
              onChange={() => this.handleChangeCheckbox('guests')}
              style={{ alignSelf: 'self-start', marginTop: '10px' }}
            />
            <label htmlFor="link" className="label-on-top ui">Гости</label>
            <div className="flex-column">
              {[...subData.guests, { name: '', link: '' }].map((guest, index) => this.renderGuest(guest, index))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleEditorCheckbox = () => {
    this.setState(prevState => ({
      ...prevState,
      tabIndex: prevState.checkbox.editor ? 0 : 1,
      checkbox: {
        ...prevState.checkbox,
        editor: !prevState.checkbox.editor,
      },
      subData: {
        ...prevState.subData,
        editor: prevState.checkbox.editor ? '' : process(this.eventView.current.description.current.outerHTML),
      },
    }));
  }

  render() {
    const { changeContent, form } = this.props;
    const options = {
      mode: 'xml',
      theme: 'material',
      lineNumbers: true,
      focus: true,
      autofocus: true,
      indentWithTabs: true,
    };
    return (
      <div className="CreateEventForm">
        {!this.props.event && this.renderInfoBlock()}
        <h2 className="margin-15">{this.props.event ? 'Редактировать событие' : 'Создать новое событие'}</h2>
        <Mutation
          mutation={CREATE_EVENT}
          onCompleted={() => {
            form.updateState({ form: new Map() });
            this.setState({ isEventSaving: false });
            changeContent('future');
          }}
          onError={() => {
            this.setState({ isEventSaving: false });
            form.onError();
          }}
        >
          {createEvent => (
            <form
              className={`ui large form segment ${form.errors.size && 'error'}`}
              onSubmit={e => this.onSubmit(e, createEvent)}
            >
              <div className="ui two column grid">
                <div className="ui column">
                  {this.renderMainInfoBlock()}
                </div>
                <div className="ui column">
                  {this.renderSubInfoBlock()}
                </div>
              </div>

              <div className="field description-checkbox">
                <Checkbox onChange={this.handleEditorCheckbox} checked={this.state.checkbox.editor} />
                <label className="ui" htmlFor="link">
                  Используйте режим редактирования если шаблонных данных недостаточно или если вы хотите создать что-то невероятное
                  {this.state.checkbox.editor && <i className="red"> (отключив режим редактирования вы потеряете все внесенные изменения)</i>}
                </label>
              </div>

              <UserContext.Consumer>
                {({ user }) => (
                  <div className="ui one column grid">
                    <div className="ui column">
                      <div className="ui top attached tabular menu">
                        <div
                          className={`item ${this.state.tabIndex === 0 && 'active'}`}
                          onClick={() => this.setState({ tabIndex: 0 })}
                        >
                          <h3 className="ui header">
                            <div className="content">
                              Предпросмотр
                            </div>
                          </h3>
                        </div>
                        <div
                          className={`item ${this.state.tabIndex === 1 && 'active'} ${!this.state.checkbox.editor && 'disabled'}`}
                          onClick={() => this.setState({ tabIndex: 1 })}
                        >
                          <h3 className="ui header">
                            <div className="content">
                              Редактирование
                            </div>
                          </h3>
                        </div>
                      </div>
                      <div className={`ui attached tab segment  ${this.state.tabIndex === 0 && 'active'}`}>
                        <EventView ref={this.eventView} event={this.getObjectForm(user)} checkbox={this.state.checkbox} />
                      </div>
                      <div className={`ui attached tab segment  ${this.state.tabIndex === 1 && 'active'}`}>
                        {this.state.tabIndex === 1 && <CodeMirror
                          value={this.state.subData.editor}
                          options={options}
                          onBeforeChange={(editor, data, value) => {
                            this.saveSubData({ target: { name: 'editor', value } });
                          }}
                          onChange={(editor, data, value) => {
                            console.log('====C=', editor, data, value);
                          }}
                        />}
                      </div>
                    </div>
                  </div>
                )}
              </UserContext.Consumer>

              <button
                type="submit"
                className={`
                  ui large green submit left button
                  ${(this.state.isEventSaving || form.errors.size || moment(form.data.get('eventStartAt')).diff(moment(), 'minutes') < 0) && 'disabled'}
                  ${this.state.isEventSaving && 'loading'}
                `}
              >
                {this.props.event ? 'Редактировать мерояприятие' : 'Создать мерояприятие'}
              </button>
              <div className="ui error message">
                <ul className="list">
                  {showErrors(form.errors)}
                </ul>
              </div>
            </form>
          )}
        </Mutation>
      </div>
    );
  }
}

export default formValidator(CreateEventForm);

function process(str) {
  const div = document.createElement('div');
  div.innerHTML = str.trim();

  return format(div, 0).firstElementChild.outerHTML;
}

function format(node, level) {
  const indentBefore = new Array(level++ + 1).join('  ');
  const indentAfter = new Array(level - 1).join('  ');
  let textNode;

  for (let i = 0; i < node.children.length; i++) {
    textNode = document.createTextNode(`\n${indentBefore}`);
    node.insertBefore(textNode, node.children[i]);

    format(node.children[i], level);

    if (node.lastElementChild === node.children[i]) {
      textNode = document.createTextNode(`\n${indentAfter}`);
      node.appendChild(textNode);
    }
  }

  return node;
}
