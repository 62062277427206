import React from 'react';
import { Redirect } from 'react-router-dom';

import {
  ErrorBoundary,
  UserContext,
} from '../components';
import { AdminSidebar, AdminRoutes, AdminContext, AdminWorkDaysNotification } from './components';

const AdminContainer = () => (
  <UserContext.Consumer>
    {({ isRole }) => {
      if (!isRole(['admin', 'manager', 'hrmanager', 'hr', 'bookkeeper', 'officeManager'])) return <Redirect to="/" />;
      return (
        <ErrorBoundary>
          <AdminContext.Provider value={{
              isAdmin: isRole('admin'),
              isBookkeeper: isRole('bookkeeper'), 
              isOfficeManager: isRole('officeManager'),
              isManager: isRole('manager'),
            }}>
            <div className="admin-container">
              <AdminWorkDaysNotification />
              <AdminSidebar isRole={isRole} />
              <AdminRoutes isRole={isRole} />
            </div>
          </AdminContext.Provider>
        </ErrorBoundary>
      );
    }}
  </UserContext.Consumer>
);
export default AdminContainer;
