import React, { Component } from 'react';
import { Query } from 'react-apollo';
import moment from 'moment';
import 'moment/locale/ru';

import { AdminContext, AdminOrderForm } from '../../admin/components';
import { USER_ORDERS } from '../../gql';
import { Loader, SideColumnWithMobileBottomLine, YearsSelector } from '../../components';

import './OrderHistory.css';

const months = moment.localeData('ru').months();
const currentMonth = moment().month();
const currentYear = moment().year();

class OrderHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenEditOrder: false,
      currentOrder: {},
      selectedMonth: currentMonth,
      selectedYear: currentYear,
    };
  }

  toggleEditOrder = (currentOrder = {}) => this.setState({ isOpenEditOrder: !this.state.isOpenEditOrder, currentOrder })

  changeMonth = month => this.setState({ selectedMonth: month });

  renderOrderForm = client => (
    <AdminOrderForm
      active={this.state.isOpenEditOrder}
      onClose={this.toggleEditOrder}
      client={client}
      order={this.state.currentOrder}
    />
  )

  totalCost = userOrder => (userOrder.length ? userOrder.map(e => e.totalCost).reduce((prev, curr) => (prev + curr)) : 0);
  additionalCost = userOrder => (userOrder.length ? userOrder.map(e => e.additionalCost).reduce((prev, curr) => (prev + curr)) : 0);
  payByCompany = userOrder => (userOrder.length ? userOrder.map(e => e.payByCompany).reduce((prev, curr) => (prev + curr)) : 0);

  onChangeSelectedYear = (year) => {
    if (year === this.state.selectedYear) return;
    this.setState({ selectedYear: year })
  }

  render() {
    const sideOrderHistoryList = [
      {
        name: 'showOrderHistory',
        title: 'Выбрать месяц',
      }];
    return (
      <Query
        query={USER_ORDERS}
        variables={{ month: this.state.selectedMonth, year: '' + this.state.selectedYear }}
      >
        {({ client, loading, error, data }) => {
          if (loading) return <Loader />;
          if (error) throw new Error(error);

          const list = data.userOrders || [];
          const totalAdditionalCost = this.additionalCost(list)
          const totalCost = this.totalCost(list)
          const payByCompany = this.payByCompany(list)
          return (
            <AdminContext.Consumer>
              {({ isAdmin }) => (
                <div className={`OrderHistory ${isAdmin ? 'admin-container-with-fixed-header' : 'container-with-fixed-header'}`}>
                  {isAdmin && this.state.isOpenEditOrder && this.renderOrderForm(client)}
                  <div className="html ui attached grid custom-reverse">
                    <div className="ui column twelve wide">
                      <div className="ui floating message success">
                        <div className="header">
                          <div>За {months[this.state.selectedMonth]} {this.state.selectedYear}</div>
                          <div>Всего потрачено: {totalCost}</div>
                          <div>
                            Ваши затраты: {Intl.NumberFormat().format(totalCost - payByCompany)}
                            {totalAdditionalCost > 0 && <b className="red"> +{totalAdditionalCost}p</b>}
                          </div>
                        </div>
                      </div>

                      {list.map((e, index) => (
                        <div className="ui column row" key={index}>
                          <div className="column">
                            <div className="ui raised segment">
                              <a className="ui blue ribbon label big">{moment(e.date).format('LL')}</a>
                              <div className="ui column grid right">

                                <div className="ui row wide">
                                  <div className="column">
                                    <div className="item flex header">
                                      <div className="width50">Наименование</div>
                                      <div className="width25">Цена</div>
                                      <div className="width25">Ваш вклад</div>
                                    </div>
                                    {e.dishes.map(({ dish, count }) => (
                                      <div className="item flex" key={dish.id}>
                                        <div className="width50">
                                          {dish.name}
                                          {count > 1 &&
                                          <b> ({count} порции)</b>
                                          }
                                        </div>
                                        <div className="width25">
                                          {count > 1 &&
                                          <b>{count}x </b>
                                          }
                                          {dish.cost}р
                                        </div>
                                        <div className="width25">
                                          {Intl.NumberFormat().format(dish.cost * count / 2)}р
                                        </div>
                                      </div>
                                    ))}
                                    <div className="item flex padding-top-5">
                                      <div className="width50">
                                        <b>Всего</b>
                                      </div>
                                      <div className="width25">
                                        <b>{e.totalCost}р</b>
                                      </div>
                                      <div className="width25">
                                        <b>
                                          {Intl.NumberFormat().format(e.totalCost - e.payByCompany)}р
                                          {e.additionalCost > 0 && <b className="red"> +{e.additionalCost}p</b>}
                                        </b>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                    </div>
                    <div className="ui column four wide">
                      <div className="MenuOrderCard ui card fixed">
                        <div className="content">
                          <div className="description">
                            <YearsSelector year={this.state.selectedYear} changeYear={this.onChangeSelectedYear} className="fluid" />
                          </div>
                        </div>
                        <div className="content">
                          <div className="header">За месяц:</div>

                          <div className="description">
                            {months.map((month, index) => (
                              <div
                                key={month}
                                className={`month ${this.state.selectedMonth === index && 'selected'}`}
                                onClick={() => this.changeMonth(index)}
                              >
                                {month}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <SideColumnWithMobileBottomLine
                      menuList={sideOrderHistoryList}
                    >
                      <div className="MenuOrderCard ui card fixed">
                        <div className="content">
                          <div className="description">
                            <YearsSelector year={this.state.selectedYear} changeYear={this.onChangeSelectedYear} className="fluid" />
                          </div>
                        </div>
                        <div className="content">
                          <div className="header">За месяц:</div>

                          <div className="description">
                            {months.map((month, index) => (
                              <div
                                key={month}
                                className={`month ${this.state.selectedMonth === index && 'selected'}`}
                                onClick={() => this.changeMonth(index)}
                                data-modal="shouldBubble"
                              >
                                {month}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </SideColumnWithMobileBottomLine>
                  </div>
                </div>
              )}
            </AdminContext.Consumer>

          );
        }}
      </Query>
    );
  }
}

export default OrderHistory;
