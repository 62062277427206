import gql from 'graphql-tag';

export const ADD_FAKE_ORDERS = gql`
    mutation addFakeOrders {
        addFakeOrders
    }
`;

export const ADD_FAKE_MENU = gql`
    mutation addFakeMenu {
        addFakeMenu
    }
`;
