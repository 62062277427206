import React, {
  useCallback,
  Fragment,
  useState,
  useRef,
  memo,
} from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import { UPLOAD_FILE_STREAM } from '../../gql';
import { CropAvatarModal, Modal } from '../index';

const readFile = (file, setAvatarPreview) => {
  const reader = new FileReader();

  reader.readAsDataURL(file);
  reader.onload = ({ target: { result } }) => {
    setAvatarPreview(result);
  };
  reader.onerror = () => {
    setAvatarPreview(null);
  };
};

function ImageUploader({
  onChange,
  imageType,
}) {
  const [modalIsOpen, handleModalIsOpen] = useState(false);
  const [avatarPreview, setAvatarPreview] = useState(null);
  const [fileName, setFileName] = useState('');
  const [croppedImage, setCroppedImage] = useState(null);

  const fileInput = useRef(null);

  const onCloseModal = useCallback(
    () => {
      handleModalIsOpen(false);
      if (fileInput && fileInput.current) {
        fileInput.current.value = null;
      }
    },
    [],
  );

  const onChangeInputValue = useCallback(
    ({ target: { files } }) => {
      const [file] = files;

      handleModalIsOpen(true);
      readFile(file, setAvatarPreview);
      setFileName(file.name);
    },
    [],
  );

  const uploadImage = useCallback(
    (singleUploadStream) => {
      handleModalIsOpen(false);
      if (croppedImage) {
        singleUploadStream({ variables: { file: croppedImage, type: imageType } });
      }
    },
    [croppedImage, imageType],
  );

  const onUploadComplete = useCallback(
    (data) => {
      onChange(data.singleUploadStream);
    },
    [onChange],
  );

  return (
    <Mutation
      mutation={UPLOAD_FILE_STREAM}
      onCompleted={onUploadComplete}
    >
      {(singleUploadStream, { loading }) => (
        <Fragment>
          <label htmlFor="document" className="ui primary button">
            {loading ? <div className="loader">Загрузка ...</div> : 'Выбрать изображение'}
            <input
              id="document"
              name="document"
              style={{
                display: 'none',
              }}
              ref={fileInput}
              type="file"
              accept="image/png, image/jpeg"
              onChange={onChangeInputValue}
            />
          </label>
          <Modal
            active={modalIsOpen}
            onClose={onCloseModal}
            onSuccess={() => uploadImage(singleUploadStream)}
            body={(
              <CropAvatarModal
                avatarPreview={avatarPreview}
                setCroppedImage={setCroppedImage}
                fileName={fileName}
              />
            )}
            size="small"
          />
        </Fragment>
      )}
    </Mutation>
  );
}

ImageUploader.propTypes = {
  onChange: PropTypes.func.isRequired,
  imageType: PropTypes.string.isRequired,
};

export default memo(ImageUploader);
