import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import moment from 'moment';
import InputMask from 'react-input-mask';

import {
  ProfileForm,
  UserContext,
  ProfileRolesCard,
  ProfileStatusesCard,
  Loader,
} from '../../components/index';
import { USER } from '../../gql';
import { titleCase } from '../../helpers';
import './Profile.css';


class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avatar: '',
      updateAvatar: false,
    };
  }

  onUploadFinish = () => {
    this.setState({ avatar: '', updateAvatar: false });
  }

  changeAvatar = (url) => {
    if (url) this.setState({ avatar: url, updateAvatar: url !== this.state.avatar });
  }

  renderInfoBlock = (profileOwner) => {
    if (profileOwner.status !== 'inactive' && profileOwner.status !== 'blocked' && profileOwner.avatar) return null;
    return (
      <div className="margin-bottom">
        {profileOwner.status === 'inactive'
        && (
        <div className="ui floating message warning">
          <div className="header">
            Этот пользователь еще неактивен!
          </div>
          <p>Подождите немного или попросите менеджера активировать этот аккаунт!</p>
        </div>
        )
        }
        {profileOwner.status === 'blocked'
        && (
        <div className="ui floating message error">
          <div className="header">
            Этот пользователь Заблокирован!
          </div>
          <p>Вы не можете редактировать заблокированный профиль пока не разблокируете.</p>
          <p>
Дата блокировки:
            {moment(profileOwner.blockedAt || profileOwner.updatedAt).format('LL')}
          </p>
        </div>
        )
        }
      </div>
    );
  }

  getAvatarUrl = (url) => {
    if (url == null) return '/assets/images/image.png';
    const [rawUrl] = url.split('=');
    // https://developers.google.com/people/image-sizing
    return `${rawUrl}=w530`
  }

  render() {
    const userId = this.props.match.params.id;
    return (
      <Query
        query={USER}
        variables={{ id: userId }}
      >
        {({
          client, loading, error, data,
        }) => {
          if (loading) return <Loader />;
          if (error) throw new Error(error);
          const profileOwner = data.user;

          return (
            <UserContext.Consumer>
              {({ user, isRole }) => (
                <div className="Profile container-with-fixed-header">
                  {(profileOwner.id === user.id || isRole(['admin', 'manager', 'hrmanager', 'hr'])) && this.renderInfoBlock(profileOwner)}

                  <div className="ui column grid">
                    <div className={`column four wide content-center ${(!isRole(['admin', 'manager', 'hrmanager']) && profileOwner.id !== user.id) && 'horizontal-card'}`}>
                      <div className="ui card main-info">
                        <div className="ui card main-info-image">
                          <div className="image">
                            <img src={this.getAvatarUrl(profileOwner.avatarUrl)} alt="avatar" />
                          </div>
                        </div>
                        <div className="ui card main-info-description">
                          <div className="content">
                            <a className="header">
                              {profileOwner.firstName}
                              {' '}
                              {profileOwner.lastName}
                            </a>
                            {(profileOwner.id === user.id || isRole(['admin', 'manager', 'hrmanager', 'hr']))
                              ? (
                                <React.Fragment>
                                  <div className="meta">
                                  Присоедился в
                                    {' '}
                                    <b>{moment(profileOwner.createdAt).format('YYYY')}</b>
                                    {' '}
году.
                                  </div>
                                  <div className="meta">
                                  А если точнее, то
                                    {' '}
                                    <b>{moment(profileOwner.createdAt).format('DD MMMM')}</b>
.
                                  </div>
                                  {profileOwner.status !== 'blocked' && profileOwner.departments != null && profileOwner.departments[0] !== 0
                                && (
                                <React.Fragment>
                                  <div className="meta">
                                    Отдел
                                    {' '}
                                    <b>{profileOwner.departments[0].name}</b>
.
                                  </div>
                                </React.Fragment>
                                )
                                }
                                  <div className="description">
                                    {titleCase(profileOwner.role.rusName)}
                                  </div>
                                </React.Fragment>
                              )
                              : (
                                <React.Fragment>
                                  {profileOwner.status !== 'blocked' && profileOwner.departments != null && profileOwner.departments[0] !== 0
                                  && (
                                  <div className="meta">
                                    Отдел
                                    {' '}
                                    <b>{profileOwner.departments[0].name}</b>
.
                                  </div>
                                  )
                                }
                                </React.Fragment>
                              )
                            }
                          </div>
                          {(profileOwner.id === user.id || isRole(['admin', 'manager', 'hrmanager']))
                          && (
                          <div className="extra content">
                            <a>
                              <i className="phone icon" />
                              <InputMask
                                style={{ border: 0 }}
                                disabled
                                mask="+7 (999) 999-99-99"
                                value={profileOwner.phone}
                              />
                            </a>
                          </div>
                          )
                          }
                        </div>

                      </div>
                      {/*{(isRole(['admin', 'manager', 'hrmanager', 'hr']) && user.id !== profileOwner.id)*/}
                      {/*  && (*/}
                      {/*  <div className={`profile-sub-card ${isRole(['hr']) && 'hr'}`}>*/}
                      {/*    <ProfileStatusesCard profileOwner={profileOwner} />*/}
                      {/*    <ProfileRolesCard profileOwner={profileOwner} />*/}
                      {/*  </div>*/}
                      {/*  )*/}
                      {/*}*/}

                    </div>
                    {(profileOwner.id === user.id || isRole(['admin', 'manager', 'hrmanager'])) && <ProfileForm profileOwner={profileOwner} client={client} />}
                  </div>
                </div>
              )}
            </UserContext.Consumer>
          );
        }}
      </Query>
    );
  }
}

export default Profile;
