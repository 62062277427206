import gql from 'graphql-tag';

export const FRAGMENT_CALENDAR_INFO = gql`
    fragment CalendarInfo on Calendar {
        id
        name
        rusName
        year
        date
    }
`;
