import gql from 'graphql-tag';
import { FRAGMENT_DISH_FULL_INFO } from '../';

export const MENU = gql`
    query menu($filter: Filter) {
        menu(filter: $filter) {
            ...DishFullInfo
        }
    }
    ${FRAGMENT_DISH_FULL_INFO}
`;

export const MENU_GROUP_BY_CATEGORY = gql`
    query menuGroupByCategory {
        menuGroupByCategory{
            id
            name
            rusName
            menu {
                ...DishFullInfo
            }
            orderTime
        }
    }
    ${FRAGMENT_DISH_FULL_INFO}
`;
