import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';

import { REQUEST_STATUSES } from '../../../environment';
import { declOfNum } from '../../../helpers';
import { PAGINATION_REQUESTS } from '../../../gql';
import './RequestsList.css';
import { Loader, UserContext } from '../..';

class RequestsList extends Component {
  static propTypes = {
    variables: PropTypes.shape(),
  };

  static defaultProps = {
    variables: {},
  };

  stylesByStatus = (status) => {
    if (status === REQUEST_STATUSES.done) return 'green';
    if (status === REQUEST_STATUSES.rejected) return 'red';
    return 'orange';
  };

  showRequestModal = () => {};
  showImageModal = () => {};

  renderHeaderButtons = request => (
    <div className="ui pseudo-segment request-card_header_buttons">
      <div className="ui button days-numbers only-icon-for-small-width">
        {request.days} {declOfNum(request.days, ['день', 'дня', 'дней'])}
        <span> на выполнение</span>
      </div>
      <div className={`ui button status only-icon-for-small-width ${this.stylesByStatus(request.status)}`}>
        <React.Fragment>
          {request.status === REQUEST_STATUSES.waiting &&
          <React.Fragment>
            <i className="icon button sync" />
            <span>Заявка в работе</span>
          </React.Fragment>
          }
          {request.status === REQUEST_STATUSES.done &&
          <React.Fragment>
            <i className="icon button checkmark" />
            <span>Заявка выполнена</span>
          </React.Fragment>
          }
          {request.status === REQUEST_STATUSES.rejected &&
          <React.Fragment>
            <i className="icon button close" />
            <span>Заявка отклонена</span>
          </React.Fragment>
          }
        </React.Fragment>
      </div>
    </div>
  )

  renderAttachedImage = (request = {}) => {
    if (!request.file) return null;
    const splittedUrl = request.file.split('.');
    const fileFormat = splittedUrl[splittedUrl.length - 1];
    const isPdf = fileFormat === 'pdf';
    const updatedRequest = {
      ...request,
      file: decodeURI(request.file),
    };
    const openImageModal = (e) => {
      if (e.type === 'keypress' && e.key !== 'Enter') return;
      e.stopPropagation();
      this.showImageModal(updatedRequest);
    };
    const getPdfLinkedItem = item => (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={updatedRequest.file}
        onClick={e => e.stopPropagation()}
        className="pdf-link"
      >
        {item}
      </a>
    );

    if (isPdf) {
      return (
        <div className="ui segment right aligned request-card_sub-info_images">
          {getPdfLinkedItem(<i className="file pdf icon big cursor-pointer" />)}
          {getPdfLinkedItem(<p className="pdf-description"> Жми сюда, чтобы посмотреть файл</p>)}
        </div>
      );
    }

    return (
      <div className="ui segment right aligned request-card_sub-info_images">
        <div
          role="button"
          tabIndex="0"
          className="ui small image"
          onKeyPress={openImageModal}
          onClick={openImageModal}
        >
          <img src={updatedRequest.file} alt="file attached to request" />
        </div>
      </div>
    );
  }

  renderRequestCard = (request = {}) => (
    <UserContext.Consumer>
      {({ isRole }) => (
        <div
          className="ui segments request-card"
          key={request.id}
          onClick={() => this.showRequestModal(request)}
        >
          <div className="ui horizontal segments request-card_header">
            <div className="ui pseudo-segment request-card_header_number">
              <b>{request.id}</b>
            </div>
            <div className="ui segment request-card_header_title">
              <b>{request.name}</b>
            </div>
            {this.renderHeaderButtons(request)}
          </div>

          <div className="ui segment request-card_mobile-sub-header">
            {this.renderHeaderButtons(request)}
          </div>

          <div className="ui segment request-card_description">
            <p>{request.description}</p>
            {request.comment && !isRole(['admin', 'manager', 'hrmanager']) && (
              <p className="request-card_comment"><b>Комментарий: </b>{request.comment}</p>
            )}
          </div>

          <div className="ui segment request-card_mobile-sub-info">
            {this.renderAttachedImage(request)}
          </div>

          <div className="ui horizontal segments request-card_sub-info">
            <div className="ui request-card_sub-info_info pseudo-segment">
              <div>Пользователь:  <b>{request.owner.firstName} {request.owner.lastName}</b></div>
              <div>Дата открытия:  <b>{moment(new Date(request.createdAt)).format('LL')}</b></div>
              {request.closedAt ?
                <div>Дата закрытия:  <b>{moment(new Date(request.closedAt)).format('LL')}</b></div>
                :
                <div>Выполнить до:  <b>{moment(new Date(request.createdAt)).add(request.days, 'days').format('DD.MM.YY')}</b></div>
              }
            </div>
            {this.renderAttachedImage(request)}
          </div>
        </div>
      )}
    </UserContext.Consumer>
  )

  render() {
    const policy = this.props.variables.filter && this.props.variables.filter.search ? 'no-cache' : 'cache-first';
    return (
      <div className="RequestsList">
        <Query
          query={PAGINATION_REQUESTS}
          variables={this.props.variables}
          fetchPolicy={policy}
        >
          {({ loading, error, data, fetchMore }) => {
            if (loading) return <Loader outerStyles={{ minHeight: '250px' }} />;
            if (error) throw new Error(error);

            const { paginationRequests: { results: requests = [], cursors = {} } = {} } = data || {};

            const loadMore = () => fetchMore({
              variables: { ...this.props.variables, pageInfo: { after: cursors.after } },
              updateQuery: (previousResult = {}, { fetchMoreResult = {} }) => {
                const previousSearch = previousResult.paginationRequests || {};
                const currentSearch = fetchMoreResult.paginationRequests || {};
                const previousNodes = previousSearch.results || [];
                const currentNodes = currentSearch.results || [];
                return {
                  ...previousResult,
                  paginationRequests: {
                    ...previousSearch,
                    results: [...previousNodes, ...currentNodes],
                    cursors: currentSearch.cursors,
                  },
                };
              },
            });

            return (
              <InfiniteScroll
                pageStart={0}
                loadMore={loadMore}
                hasMore={cursors.hasNext}
                useWindow
                loader={<div className="loader" key={0}>Загрузка ...</div>}
              >
                {requests.length > 0 ?
                  requests.map(request => this.renderRequestCard(request))
                  :
                  <div className="no-list">
                    {this.props.variables.filter.search ?
                      'Ничего не найдено :('
                      :
                      'Вау! Все заявки выполнены! Хоть джигу пляши!'
                    }
                  </div>
                }
              </InfiniteScroll>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default RequestsList;
