import gql from 'graphql-tag';
import { FRAGMENT_EVENT_INFO } from '../';

export const CREATE_EVENT = gql`
    mutation createEvent($event: EventCredentials!) {
        createEvent(event: $event) {
            ...EventInfo
        }
    }
    ${FRAGMENT_EVENT_INFO}
`;

export const ADD_TO_EVENT = gql`
    mutation addToEvent($id: ID!) {
        addToEvent(id: $id) {
            ...EventInfo
        }
    }
    ${FRAGMENT_EVENT_INFO}
`;

export const REMOVE_FROM_EVENT = gql`
    mutation removeFromEvent($id: ID!, $userId: ID!) {
        removeFromEvent(id: $id, userId: $userId) {
            ...EventInfo
        }
    }
    ${FRAGMENT_EVENT_INFO}
`;
