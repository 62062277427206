import gql from 'graphql-tag';
import { FRAGMENT_REQUEST_INFO } from '../';

export const CREATE_REQUEST = gql`
    mutation createRequest($request: RequestCredentials!) {
        createRequest(request: $request) {
            ...RequestInfo
        }
    }
    ${FRAGMENT_REQUEST_INFO}
`;

export const CLOSE_REQUEST = gql`
    mutation closeRequest($id: ID!, $status: String!, $comment: String) {
        closeRequest(id: $id, status: $status, comment: $comment) {
            ...RequestInfo
        }
    }
    ${FRAGMENT_REQUEST_INFO}
`;