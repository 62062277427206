import React, {PureComponent} from 'react';
import './PeopleGame.css';

import ParticleEffectButton from 'react-particle-effect-button'

import {Query} from "react-apollo";
import {QUESTION_FOR_PEOPLE_GAME,CAN_PLAY_PEOPLE_GAME} from "../../gql/user/query";

class PeopleGame extends PureComponent {

    constructor(props){
        super(props);
        this.state = {
            screen:'start',
            startScreenButton: true,
            message:'',
            selected:null,
            correct:null,
            phase:'should-answer'
        }
    }

    startGame = ()=>{
        this.setState({startScreenButton:false},()=>setTimeout(()=>{
            this.setState({screen:'play'})
        },5000));

    }
    playAgain= (refetch)=>{
        this.setState({
            selected:null,
            correct:null,
            phase:'should-answer',
        },()=>{
            refetch();
        })

    }

    select =(selected)=>{
        if(this.state.phase !== 'should-answer') return;
        this.setState({selected,phase:'waiting'},()=>{
            let selected = this.state.selected;
            let count=0;
            let interval = setInterval(()=>{
                count++;
                if(count%2){
                    this.setState({selected:null});
                } else{
                    this.setState({selected:selected})
                }

                if(count===6){
                    clearInterval(interval);
                    this.setState({phase:'end'})
                }
            },300);


        })

    }

    render() {

        return (<div className="PeopleGame">

            {this.state.screen ==='start'?(<div className="startScreen">
                <Query
                    query={CAN_PLAY_PEOPLE_GAME}
                >
                    {( {loading, error, data,refetch }) => {
                        if(loading) return <div>Загрузка ...</div>

                        if(error){
                            console.log('Error in CAN_PLAY_PEOPLE_GAME ',error)
                            return <div>Ошибка :( </div>
                        }

                        if(data.canPlayPeopleGame){
                            return (<ParticleEffectButton
                                color='#1B1C1D'
                                hidden={!this.state.startScreenButton}
                                duration={2000}
                                direction={'bottom'}
                            >
                                <button onClick={this.startGame}> Играть!</button>
                            </ParticleEffectButton>)
                        }
                        return <div className="come-tomorrow"> К сожалению на сегодня вопросы кончились :(</div>



                    }}

                        </Query>

            </div>):''}
            {this.state.screen ==='play'?(<div className="playScreen">
                    <Query
                        query={QUESTION_FOR_PEOPLE_GAME}
                    >
                        {( {loading, error, data,refetch }) => {
                            if(loading) return <div>Загрузка ...</div>

                            if(error){
                                console.log('Error in QUESTION_FOR_PEOPLE_GAME ',error)
                                return <div>Ошибка :( </div>
                            }

                            const q = data.getQuestionForPeopleGame;
                            if(q.questionType=='ended') return (<div className="come-tomorrow"> К сожалению на сегодня вопросы кончились :(</div>)
                            return(<div>
                                <div>
                                    {['pickName','pickFromWhichDepartment-avatar'].includes(q.questionType)&&(<div className="pick-name">
                                        <img id="pick-name" src={q.questionData}></img>
                                    </div>)}
                                    {['pickPhoto','pickWhoFromDepartment-avatar'].includes(q.questionType)&&(<div className="pick-photo">

                                        {q.answers.map((item,i)=><div  id={"pick-photo-"+i} key={i}>
                                            <img  src={item}/>
                                            <div className="letter">{['A','B','C','D'][i]}</div>
                                        </div>)}
                                    </div>)}
                                    {['pickFromWhichDepartment-name','pickWhoFromDepartment-name'].includes(q.questionType)&&(<div className="empty-box"></div>)}

                                </div>
                                <div className="question sides">
                                    <div className="sides-left"></div>
                                    {q.questionType=='pickName'&&'Как зовут человека на фото?'}
                                    {q.questionType=='pickPhoto'&&`Как выглядит ${q.questionData} ?`}
                                    {['pickWhoFromDepartment-avatar','pickWhoFromDepartment-name'].includes(q.questionType)&&`Кто работает в офисе ${q.questionData} ?`}
                                    {q.questionType =='pickFromWhichDepartment-avatar'&&`Где работает этот человек?`}
                                    {q.questionType =='pickFromWhichDepartment-name'&&`Где работает ${q.questionData}?`}
                                    <div className="sides-right"></div>
                                </div>
                                <div className={`answers ${this.state.phase}`}>
                                    <div className={`first ${this.state.selected==0&&'answered'} ${q.correctAnswerIndex==0&&'correct'} `} onClick={this.select.bind(this,0)}>
                                        <div className="sides-left"></div>
                                        {['pickName','pickWhoFromDepartment-name','pickFromWhichDepartment-name','pickFromWhichDepartment-avatar'].includes(q.questionType)&& q.answers[0]}
                                        {['pickPhoto','pickWhoFromDepartment-avatar'].includes(q.questionType) && 'A'}
                                        <div className="sides-right"></div>
                                    </div>
                                    <div className="innerline"></div>
                                    <div className={`second ${this.state.selected==1&&'answered'} ${q.correctAnswerIndex==1&&'correct'} `}  onClick={this.select.bind(this,1)}>
                                        <div className="sides-left"></div>
                                        {['pickName','pickWhoFromDepartment-name','pickFromWhichDepartment-name','pickFromWhichDepartment-avatar'].includes(q.questionType)&& q.answers[1]}
                                        {['pickPhoto','pickWhoFromDepartment-avatar'].includes(q.questionType)&& 'B'}
                                        <div className="sides-right"></div>
                                    </div>
                                    <div className={`third ${this.state.selected==2&&'answered'} ${q.correctAnswerIndex==2&&'correct'}`}  onClick={this.select.bind(this,2)}>
                                        <div className="sides-left"></div>
                                        {['pickName','pickWhoFromDepartment-name','pickFromWhichDepartment-name','pickFromWhichDepartment-avatar'].includes(q.questionType)&& q.answers[2]}
                                        {['pickPhoto','pickWhoFromDepartment-avatar'].includes(q.questionType)&& 'C'}
                                        <div className="sides-right"></div>
                                    </div>
                                    <div className="innerline"></div>
                                    <div className={`fourth ${this.state.selected==3&&'answered'} ${q.correctAnswerIndex==3&&'correct'}`}  onClick={this.select.bind(this,3)}>
                                        <div className="sides-left"></div>
                                        {['pickName','pickWhoFromDepartment-name','pickFromWhichDepartment-name','pickFromWhichDepartment-avatar'].includes(q.questionType)&& q.answers[3]}
                                        {['pickPhoto','pickWhoFromDepartment-avatar'].includes(q.questionType)&& 'D'}
                                        <div className="sides-right"></div>
                                    </div>
                                </div>
                                <div>
                                    {(this.state.phase=='end'&&q.canPlayAgain)&&(<button className="play-again" onClick={this.playAgain.bind(this,refetch)}> Играть еще!</button>)}
                                    {(this.state.phase=='end'&&!q.canPlayAgain)?(<div className="come-tomorrow"> К сожалению на сегодня вопросы кончились :(</div>):''}

                                </div>
                            </div>)
                        }


                        }</Query>
            </div>):''}

        </div>)
    }
}


export default PeopleGame;