import gql from 'graphql-tag';
import { FRAGMENT_HEADER_BADGES } from '../';

export const HEADER_BADGES = gql`
    query headerBadges {
        headerBadges {
            ...HeaderBadges
        }
    }
    ${FRAGMENT_HEADER_BADGES}
`;
