import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { getErrorMessages, showErrors } from '../helpers';
import { ERRORS } from '../environment';

export default function Validator(WrappedComponent) {
  class formValidator extends React.Component {
    static propTypes = {

    }

    static defaultProps = {

    }

    constructor(props) {
      super(props)
      this.state = {
        formError: new Map(),
        form: new Map(),
      }
    }

    validate = ({ target: { name, value } }) => {
      const { formError, form } = this.state;
      form.set(name, value);
      formError.has('response') && formError.delete('response');

      if (!value) {
        formError.has(name) && formError.delete(name);
        form.delete(name);
      } else {
        switch (name) {
          case 'confirmPassword':
          case 'newPassword':
            (form.has('confirmPassword') && form.get('confirmPassword') !== form.get('newPassword'))
              ? formError.set('confirmPassword', ERRORS.TEXT.passwordCompare)
              : formError.delete('confirmPassword');
            break;

          case 'startAt':
          case 'endAt':
            form.set(name, moment(value, 'DD-MM-YYYY').isValid() ? moment(value, 'DD-MM-YYYY') : moment());
            moment(value, 'DD-MM-YYYY').isValid() ? formError.delete(name) : formError.set(name, ERRORS.TEXT[name])
            break;

          case 'days':
            form.set(name, Math.min(Math.max(value, 1), 100));
            break;

          case 'name':
          case 'description':
            value.trim().length ? formError.delete(name) : formError.set(name, ERRORS.TEXT.addSymbols)
            break;

          case 'isUnpaid':
            form.set(name, form.get('endAt').diff(form.get('startAt'), 'days') < 6 ? true : value);
            break;
          default:
            formError.has(name) && formError.delete(name);
        }
      }

      this.setState({ form, formError });
    }

    updateState = data => this.setState({ ...data });

    onError = (error) => {
      const errorMessage = getErrorMessages(error);
      const { formError } = this.state;
      formError.set('response', errorMessage[0]);
      this.setState({ formError });
    }

    render() {
      const data = {
        validate: this.validate,
        updateState: this.updateState,
        onError: this.onError,
        data: this.state.form,
        errors: this.state.formError,
      }
      const {
        ...passThroughProps
      } = this.props;
      // Wraps the input component in a container, without mutating it. Good!
      return <WrappedComponent form={data} {...passThroughProps} />;
    }

  }
  // Add a specific display name for debugging purpose
  formValidator.displayName = `FormValidator(${getDisplayName(WrappedComponent)})`;

  return formValidator
}

// Should be put outside this compoment
function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
