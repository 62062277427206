import React, { Component } from 'react';
import { debounce } from 'lodash';

import { RequestsList, CreateRequestForm, UserContext, UsersSelector, PageSidePanel, SideColumnWithMobileBottomLine } from '../../components';
import { AdminRequestsList } from '../../admin/components';
import { REQUEST_STATUSES } from '../../environment';

import './Request.css';

class Request extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showContent: '',
      searchFilter: '',
      userFilter: {},
    };
    this.debounce = debounce(value => this.setState({ searchFilter: value }), 500);
  }

  getListSidePanel = (isAdmin) => {
    const generalWord = isAdmin ? 'Все' : 'Мои';
    return [
      {
        name: 'create',
        title: 'Открыть заявку',
        userPosition: 1,
        adminPosition: 5,
      },
      {
        name: 'all',
        title: `${generalWord} заявки`,
        userPosition: 2,
        adminPosition: 2,
      },
      {
        name: 'open',
        title: 'В работе',
        userPosition: 3,
        adminPosition: 1,
      },
      {
        name: 'done',
        title: 'Выполненные',
        userPosition: 4,
        adminPosition: 3,
      },
      {
        name: 'rejected',
        title: 'Отклонненые',
        userPosition: 5,
        adminPosition: 4,
      },
    ];
  };

  changeContent = showContent => this.setState({ showContent });
  changeUser = userFilter => this.setState({ userFilter });

  renderContentByUser = (isAdmin) => {
    const { showContent, searchFilter, userFilter } = this.state;
    const filter = {
      search: searchFilter,
      ownerId: userFilter.id,
    };
    switch (showContent) {
      case 'create':
        return <CreateRequestForm changeContent={this.changeContent} />;
      case 'all': break;
      case 'open':
        filter.status = REQUEST_STATUSES.waiting;
        break;
      case 'done':
        filter.status = REQUEST_STATUSES.done;
        break;
      case 'rejected':
        filter.status = REQUEST_STATUSES.rejected;
        break;
      default:
        filter.status = REQUEST_STATUSES.waiting;
        return isAdmin ?
          <AdminRequestsList variables={{ filter }} />
          :
          <CreateRequestForm changeContent={this.changeContent} />;
    }
    return isAdmin ?
      <AdminRequestsList variables={{ filter }} />
      :
      <RequestsList variables={{ filter }} />;
  }

  search = ({ target }) => {
    const { value } = target;
    this.debounce(value);
  }

  renderHintMessageBlock = (isAdmin) => {
    let hintText = `Если вы допустили ошибки в заявке пожалуйста
      попросите своего руководителя отклонить заявку и пересоздайте ее.`;
    if (isAdmin) {
      hintText = `Если сотрудник допустил ошибки в заявке пожалуйста
        отклоните заявку и попросите его пересоздать ее.`;
    }
    return (
      <div className="ui card info message hide-on-mobile">
        <div className="content">
          <h4>Памятка</h4>
          <div className="description">
            {hintText}
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="Request container-with-fixed-header">
        <UserContext.Consumer>
          {({ isRole }) => (
            <div className="html ui attached grid custom-reverse">
              <div className="ui column twelve wide">
                {((!this.state.showContent && isRole(['admin', 'manager', 'hrmanager', 'officeManager'])) || (this.state.showContent && this.state.showContent !== 'create')) &&
                  <div className="flex-block search-block">
                    {isRole(['admin', 'manager', 'hrmanager', 'officeManager']) &&
                    <UsersSelector user={this.state.userFilter} changeUser={this.changeUser} className="user-selector" />
                    }
                    <div className={`ui icon input search-input ${isRole(['admin', 'manager', 'hrmanager', 'officeManager']) && 'for-admin'}`}>
                      <input
                        type="text"
                        placeholder="Поиск по названию и тексту заявки..."
                        onChange={this.search}
                      />
                      <i className="search link icon" />
                    </div>
                  </div>
                }
                <div className={`
                    ${((!this.state.showContent && isRole(['admin', 'manager', 'hrmanager', 'officeManager'])) || (this.state.showContent && this.state.showContent !== 'create')) && 'padding-for-search-block'}
                    ${isRole(['admin', 'manager', 'hrmanager', 'officeManager']) && 'for-admin'}
                    `}>
                  {this.renderContentByUser(isRole(['admin', 'manager', 'hrmanager', 'officeManager']))}
                </div>
              </div>
              <div className="ui column four wide">
                <div className="ui cards fixed mobile-side-menu">
                  <PageSidePanel
                    changeContent={this.changeContent}
                    showContent={this.state.showContent}
                    isAdmin={isRole(['admin', 'manager', 'hrmanager', 'officeManager'])}
                    menuList={this.getListSidePanel(isRole(['admin', 'manager', 'hrmanager', 'officeManager']))}
                  />
                  {this.renderHintMessageBlock(isRole(['admin', 'manager', 'hrmanager', 'officeManager']))}
                </div>
              </div>
              <SideColumnWithMobileBottomLine
                menuList={this.getListSidePanel(isRole(['admin', 'manager', 'hrmanager', 'officeManager']))}
                changeContent={this.changeContent}
                activeItem={this.state.showContent}
                isAdmin={isRole(['admin', 'manager', 'hrmanager', 'officeManager'])}
              />
            </div>
          )}
        </UserContext.Consumer>

      </div>
    );
  }
}

export default Request;
