import React from 'react';
import moment from 'moment';

const { REACT_APP_SSO_FE_URL } = process.env;

export function getErrorMessages({ graphQLErrors, networkError }) {
  const errors = [];
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      errors.push(message);
      return console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }

  if (networkError) {
    const message = 'Плохое соединение. Проверьте подключение или перезагрузите страницу.'
    errors.push(message);
    console.error(`[Network error]: ${networkError}`);
  }
  return errors;
}

export function showErrors(mapList) {
  const errors = [];
  for (const [key, value] of mapList) {
    errors.push(<li key={key}>{value}</li>);
  }
  return errors;
}

export function getDataFromStorage(key) {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : null;
}

export function setDataToStorage(data) {
  for (const key in data) {
    localStorage.setItem(key, data[key]);
  }
}
export function compare(property, order) {
  let sortOrder = 1;
  if (order !== 'ASC') {
    sortOrder = -1;
  }
  property = property.split('.');
  const len = property.length;

  return function (a, b) {
    let i = 0;
    while (i < len) {
      a = a[property[i]];
      b = b[property[i]];
      i += 1;
    }
    const result = (a < b) ? -1 : (a > b) ? 1 : 0;
    return result * sortOrder;
  };
}

export function numberCompare(property, order) {
  let sortOrder = 1;
  if (order !== 'ASC') {
    sortOrder = -1;
  }
  return function (a, b) {
    const result = (Number(a[property]) < Number(b[property])) ? -1 : (Number(a[property]) > Number(b[property])) ? 1 : 0;
    return result * sortOrder;
  };
}
export function dateCompare(property, order) {
  let sortOrder = 1;
  if (order !== 'ASC') {
    sortOrder = -1;
  }
  return function (a, b) {
    const result = (new Date(a[property]) < new Date(b[property])) ? -1 : (new Date(a[property]) > new Date(b[property])) ? 1 : 0;
    return result * sortOrder;
  };
}
const compareObj = {
  simpleCompare: compare,
  numberCompare,
  dateCompare,
}
export function sortList({
  list, sortBy = '', sortDirection = 'ASC', type = 'simple',
}) {
  const newList = [...list];

  return newList.sort(compareObj[`${type}Compare`](sortBy, sortDirection));
}

export function titleCase(str) {
  if (!str) return '';
  return str.toLowerCase().split(' ').map(word => word.replace(word[0], word[0].toUpperCase())).join(' ');
}

export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export function secondsToTime(secs) {
  const hours = Math.floor(secs / (60 * 60));

  const divisorForMinutes = secs % (60 * 60);
  const minutes = Math.floor(divisorForMinutes / 60);

  const divisorForSeconds = divisorForMinutes % 60;
  const seconds = Math.ceil(divisorForSeconds);

  return {
    h: `00${hours}`.slice(-2),
    m: `00${minutes}`.slice(-2),
    s: `00${seconds}`.slice(-2),
  };
}
export function isTouchDevice() {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  const mq = function (query) {
    return window.matchMedia(query).matches;
  };

  if (('ontouchstart' in window) || 'onmsgesturechange' in window || navigator.maxTouchPoints) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
}
export function isIPhone() {
  return /iPhone/.test(navigator.userAgent) && !window.MSStream;
}
export function getRangeOfDates(start, end, key = 'days', arr = [start.startOf(key)]) {
  if (start.isAfter(end)) return [];

  const next = moment(start).add(1, key).startOf(key);

  if (next.isAfter(end, key)) return arr;

  return getRangeOfDates(next, end, key, arr.concat(next));
}

export function declOfNum(number, titles) {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
}

export function caseOfMonth(month) {
  let m = month
  if (m[m.length - 1] === 'ь' || m[m.length - 1] === 'й') {
    m = m.slice(0, -1);
  }
  return titleCase(`${m}е`);
}

export function countBoxes(userOrders) {
  let countOfPairs = 0
  const sortableList = sortList({ list: userOrders, sortBy: 'menuCategoryId' })
  const array = sortableList.reduce((r, o) => {
    if (o.menuCategoryId === '2') {
      countOfPairs += 1
      r.push(o.id);
    } else if (o.menuCategoryId === '4') {
      if (countOfPairs) {
        countOfPairs -= 1
      } else {
        r.push(o.id);
      }
    } else {
      r.push(o.id);
    }
    return r
  }, []);
  return array
}

export function convertNumberToTypeWithSpaces(n) {
  return (`${n}`)
    .split('')
    .reverse()
    .join('')
    .replace(/(\d{3})/g, '$1 ')
    .split('')
    .reverse()
    .join('')
    .replace(/^ /, '');
}

export function getCroppedImg(image, imageCrop, fileName) {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = imageCrop.width;
  canvas.height = imageCrop.height;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    imageCrop.x * scaleX,
    imageCrop.y * scaleY,
    imageCrop.width * scaleX,
    imageCrop.height * scaleY,
    0,
    0,
    imageCrop.width,
    imageCrop.height,
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        return;
      }
      blob.name = fileName;
      resolve(blob);
    }, 'image/jpeg');
  });
}

export function getSquireCropPixels(image) {
  const isHorizontalAspect = image.width > image.height;
  const croppedSize = isHorizontalAspect ? image.height : image.width;
  const x = isHorizontalAspect ? (image.width - croppedSize) / 2 : 0;
  const y = isHorizontalAspect ? 0 : (image.height - croppedSize) / 2;

  return {
    aspect: 1,
    unit: 'px',
    width: croppedSize,
    height: croppedSize,
    x,
    y,
  };
}

export function getSquareCropPercents(image) {
  const width = image.width > image.height ? (image.height / image.width) * 100 : 100;
  const height = image.height > image.width ? (image.width / image.height) * 100 : 100;
  const x = width === 100 ? 0 : (100 - width) / 2;
  const y = height === 100 ? 0 : (100 - height) / 2;

  return {
    aspect: 1,
    unit: '%',
    width,
    height,
    x,
    y,
  };
}

export function redirectToSsoAuthWithCbLink() {
  const { href } = window.location;
  window.location.replace(`${REACT_APP_SSO_FE_URL}?redirectURI=${href}`);
}

export function redirectToSso() {
  window.location.replace(`${REACT_APP_SSO_FE_URL}`);
}
