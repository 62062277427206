import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { Query } from 'react-apollo';
import moment from 'moment';

import './AdminScheduleVacations.css';

import { SCHEDULE_VACATIONS } from '../../../gql';
import { EditSickModal, Loader } from '../../../components';
import { AdminContext } from '../../components';

class AdminScheduleVacations extends Component {
  state = {
    startAt: moment().startOf('week'),
    endAt: moment().add(7, 'days').endOf('week'),
    today: moment().startOf('day'),
    isOpenSickModal: false,
    selectedSick: {},
  }
  getDates = (startDate, endDate) => {
    const steps = moment(endDate).diff(moment(startDate), 'days') ;
    return Array.from({length: steps+1}, (v,i) => startDate.clone().add(i, 'days'));
  }

  isWeekend = date => (moment(date).weekday() === 5 || moment(date).weekday() === 6);

  hideSickModal = () => this.setState({ isOpenSickModal: false, selectedSick: { newComment: '' } });
  showSickModal = (date = new Date(), list = []) => {
    let selectedSick
    list.some((e) => {
      const startDate = moment(e.startAt);
      const endDate = moment(e.endAt);
      return moment(date).isBetween(startDate, endDate, 'day', '[]') && (selectedSick = e);
    })
    this.setState({ selectedSick, isOpenSickModal: true });
  };

  checkDateOnList = (date = new Date(), list = []) => {
    if (!list.length) return false;
    const enable = list.some((e) => {
      const startDate = moment(e.startAt);
      const endDate = moment(e.endAt);
      return moment(date).isBetween(startDate, endDate, 'day', '[]');
    })
    return enable;
  };

  groupByOwner = (list) => {
    return list.reduce((r, v) => {
      r[v.ownerId] = r[v.ownerId] || {};
      r[v.ownerId].owner = r[v.ownerId].owner || v.owner;
      r[v.ownerId].sick = r[v.ownerId].sick || [];
      r[v.ownerId].unpaidVacation = r[v.ownerId].unpaidVacation || [];
      r[v.ownerId].paidVacation = r[v.ownerId].paidVacation || [];
      if (v.__typename === 'Sick') {
        r[v.ownerId].sick.push(v);
      }
      if (v.__typename === 'Vacation' && v.leave === 'unpaid') {
        r[v.ownerId].unpaidVacation.push(v);
      }
      if (v.__typename === 'Vacation' && v.leave === 'paid') {
        r[v.ownerId].paidVacation.push(v);
      }
      return r;
    }, {});
  }

  addWeek = () => {
    const { startAt, endAt } = this.state;
    const start = moment(startAt).add(7, 'days');
    const end = moment(endAt).add(7, 'days');
    this.setState({ startAt: start, endAt: end });
  }

  subtractWeek = () => {
    const { startAt, endAt } = this.state;
    const start = moment(startAt).subtract(7, 'days');
    const end = moment(endAt).subtract(7, 'days');
    this.setState({ startAt: start, endAt: end });
  }

  render() {
    const { startAt, endAt } = this.state;
    const loaderStyles = {
      width: 'calc(100% + 3rem)',
      margin: '0 -1.5rem',
    }
    return (
      <AdminContext.Consumer>
        {({ isOfficeManager }) => (
          <div className="AdminScheduleVacations">
            <EditSickModal
              selectedSick={this.state.selectedSick}
              query={SCHEDULE_VACATIONS}
              variables={{ startAt, endAt }}
              hideSickModal={this.hideSickModal}
              isOpenSickModal={this.state.isOpenSickModal}
            />
            <div className="content-center">
              <button className="ui labeled icon blue button" onClick={this.subtractWeek}>
                <i className="left chevron icon" />
                Туда неделя
              </button>
              <div className={`ui left input `}>
                <DatePicker
                  selected={moment(startAt)}
                  selectsStart
                  startDate={moment(startAt)}
                  endDate={moment(endAt)}
                  onChange={value => this.setState({ startAt: value }, () => document.activeElement.blur())}
                />
              </div>
              <label> - </label>
              <div className={`ui left input `}>
                <DatePicker
                  selected={moment(endAt)}
                  selectsEnd
                  startDate={moment(startAt)}
                  endDate={moment(endAt)}
                  onChange={value => this.setState({ endAt: value }, () => document.activeElement.blur())}
                />
              </div>
              <button className="ui right labeled icon blue button" onClick={this.addWeek}>
                Сюда неделя
                <i className="right chevron icon" />
              </button>
            </div>
            <Query
              query={SCHEDULE_VACATIONS}
              variables={{ startAt, endAt }}
              fetchPolicy="cache-and-network"
            >
              {({ loading, error, data }) => {
                if (loading) return <Loader outerStyles={loaderStyles}/>;
                if (error) throw new Error(error);

                const list = data.scheduleVacations || [];
                const groupedListAsObject = this.groupByOwner([...list.sick, ...list.vacations])
                return (
                  <div className="flex-container">
                    <div className="fixed-block">
                      <h4 className="ui header">
                        <div className="content"></div>
                      </h4>
                      {Object.keys(groupedListAsObject).map((ownerId) => (
                        <h4 className="ui header" key={ownerId}>
                          <div className="content">
                            {groupedListAsObject[ownerId].owner.firstName}
                            <div className="sub header">{groupedListAsObject[ownerId].owner.lastName}
                            </div>
                          </div>
                        </h4>
                      ))}
                    </div>
                    <div className="scrollable-block">
                      {this.getDates(startAt, endAt).map((date) => (
                        <div className="fixed-block" key={date.toDate()}>
                          <h4 className={`ui header text-center ${this.isWeekend(date) && 'weekend'} ${moment(date).isSame(this.state.today, 'd') && 'today'}`}>
                            <div className="content">{moment(date).format('ddd, MMM D')}</div>
                          </h4>
                          {Object.keys(groupedListAsObject).map((ownerId) => (
                            <h4
                              className={`ui header ${this.isWeekend(date) ? 'weekend' : 'workday'} ${moment(date).isSame(this.state.today, 'd') && 'today'}`}
                              key={ownerId}
                            >
                              <div className="content colored-container">
                                {this.checkDateOnList(date, groupedListAsObject[ownerId].sick) &&
                                  <div className={`sick-colored-block ${!isOfficeManager && 'sick-colored-block--clickable'}`} onClick={() => !isOfficeManager && this.showSickModal(date, groupedListAsObject[ownerId].sick)}></div>
                                }
                                {this.checkDateOnList(date, groupedListAsObject[ownerId].paidVacation) &&
                                  <div className="vacation-colored-block vacation-colored-block--paid"></div>
                                }
                                {this.checkDateOnList(date, groupedListAsObject[ownerId].unpaidVacation) &&
                                <div className="vacation-colored-block vacation-colored-block--unpaid"></div>
                                }
                              </div>
                            </h4>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                );
              }}
            </Query>
          </div>
        )}
      </AdminContext.Consumer>
    );
  }
}

export default AdminScheduleVacations;
