const ERRORS = {
  TEXT: {
    passwordCompare: 'Пароли должны быть одинаковыми',
    onlyLetters: 'Используйте только русские буквы',
    phoneIncorrect: 'Используйте только цифры',
    removeSpace: 'Пожалуйста не используйте пробелы.',
    startAt: 'Проверьте правильность начальной даты.',
    endAt: 'Проверьте правильность конечной даты.',
    addSymbols: 'Добавьте читаемых символов.',
  },
};
export default ERRORS;
