import { PAGINATION_REQUESTS } from '../../gql';
import { REQUEST_STATUSES } from '../../environment';

export const updateRequstsCache = (client, request) => {
  const queries = [
    { query: PAGINATION_REQUESTS, variables: { filter: { search: '' } } },
    { query: PAGINATION_REQUESTS, variables: { filter: { search: '', status: REQUEST_STATUSES.waiting } } },
    { query: PAGINATION_REQUESTS, variables: { filter: { search: '', status: REQUEST_STATUSES.done } } },
    { query: PAGINATION_REQUESTS, variables: { filter: { search: '', status: REQUEST_STATUSES.rejected } } },
  ];
  queries.forEach((query) => {
    try {
      const cache = client.cache.readQuery(query);

      // request was created
      if (request.status === REQUEST_STATUSES.waiting) {
        // add to 'all' and 'waiting'
        if (query.variables.filter.status === REQUEST_STATUSES.waiting || !query.variables.filter.status) {
          // add if not exists in cache
          if (!cache.paginationRequests.results.find(({ id }) => id === request.id)) {
            cache.paginationRequests.results.unshift(request);
          }
        }
        // request was rejected/approved
      } else {
        // remove from 'waiting' list
        if (query.variables.filter.status === REQUEST_STATUSES.waiting) {
          cache.paginationRequests.results = cache.paginationRequests.results.filter(r => r.id !== request.id);
          // add to appropriate list (rejected/approved)
        } else if (query.variables.filter.status === request.status) {
          // add if not exists in cache
          if (!cache.paginationRequests.results.find(({ id }) => id === request.id)) {
            cache.paginationRequests.results.unshift(request);
          }
        }
      }
      client.cache.writeQuery({ ...query, data: cache });
    } catch (error) {}
  });
};
