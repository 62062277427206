import React from 'react';

import './Emergency.scss';

const EMERGENCY_LIST = {
  title: `Действия работников при нахождении на рабочем месте при сигнале «Воздушная тревога»
  Необходимо:`,
  items: [
    `Выполнить требования инструкции, предусматривающей немедленное прекращение работ с остановкой оборудования;`,
    `Убыть в защитное сооружение. В качестве быстрого убежища предлагается использовать подвалы.
    Они могут защитить от самых простых артобстрелов и авиаударов. Самое главное, не выбирать подвалы высоток и хрупких домов.
    Сигнал «Отбой воздушной тревоги» подается для оповещения населения о том, что угроза непосредственного нападения противника миновала.
    Он доводится по радио и телевизионным сетям, через каждые 3 мин дикторы повторяют в течение 1-2 мин: «ВНИМАНИЕ! ВНИМАНИЕ! Граждане! Отбой воздушной тревоги! Отбой воздушной тревоги!».
    Сигнал дублируется по местным радиотрансляционным сетям и с помощью передвижных громкоговорящих установок.
    После объявления этого сигнала работники действует в соответствии со сложившейся обстановкой;`,
    `Персонал возвращаются к месту работы или к месту сбора формирований и
    включаются в работу по ликвидации последствий нападения;`,
  ]
};

const MATERIALS = [
  {
    href: 'https://storage.yandexcloud.net/dunice-public-media/emergency/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%92%D0%B2%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE-%D0%93%D0%9E.pdf',
    title: 'Программа вводного ГО'
  },
  {
    href: 'https://storage.yandexcloud.net/dunice-public-media/emergency/%D0%9F%D0%B0%D0%BC%D1%8F%D1%82%D0%BA%D0%B0-%D0%A7%D0%A1.pdf',
    title: 'Памятка при ЧС',
  },
  {
    href: 'https://storage.yandexcloud.net/dunice-public-media/emergency/%D0%92-%D1%81%D0%BB%D1%83%D1%87%D0%B0%D0%B5-%D0%B2%D0%BD%D0%B5%D0%B7%D0%B0%D0%BF%D0%BD%D0%BE%D0%B8%CC%86-%D0%B0%D1%82%D1%82%D0%B0%D0%BA%D0%B8-%D0%B1%D0%B5%D1%81%D0%BF%D0%B8%D0%BB%D0%BE%D1%82%D0%BD%D0%BE%D0%B3%D0%BE-%D0%BB%D0%B5%D1%82%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE-%D0%B0%D0%BF%D0%BF%D0%B0%D1%80%D0%B0%D1%82%D0%B0.jpeg',
    title: 'В случае внезапной аттаки беспилотного летательного аппарата',
  },
  {
    href: 'https://storage.yandexcloud.net/dunice-public-media/emergency/%D0%92-%D1%81%D0%BB%D1%83%D1%87%D0%B0%D0%B5-%D0%BE%D0%B1%D0%BD%D0%B0%D1%80%D1%83%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F-%D0%B1%D0%B5%D1%81%D0%BF%D0%B8%D0%BB%D0%BE%D1%82%D0%BD%D0%BE%D0%B3%D0%BE-%D0%BB%D0%B5%D1%82%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE-%D0%B0%D0%BF%D0%BF%D0%B0%D1%80%D0%B0%D1%82%D0%B0.pdf',
    title: 'В случае обнаружения беспилотного летательного аппарата',
  },
  {
    href: 'https://storage.yandexcloud.net/dunice-public-media/emergency/%D0%9F%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BF%D1%80%D0%B8-%D0%B0%D1%82%D0%B0%D0%BA%D0%B5-%D0%B1%D0%B5%D1%81%D0%BF%D0%B8%D0%BB%D0%BE%D1%82%D0%BD%D0%BE%D0%B3%D0%BE-%D0%BB%D0%B5%D1%82%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE-%D0%B0%D0%BF%D0%BF%D0%B0%D1%80%D0%B0%D1%82%D0%B0.jpeg',
    title: 'Поведение при атаке беспилотного летательного аппарата',
  },
  {
    href: 'https://storage.yandexcloud.net/dunice-public-media/emergency/%D0%9F%D1%80%D0%B8-%D0%BE%D0%B1%D1%81%D1%82%D1%80%D0%B5%D0%BB%D0%B5.pdf',
    title: 'При обстреле',
  },
  {
    href: 'https://storage.yandexcloud.net/dunice-public-media/emergency/%D0%A1%D0%B8%D0%B3%D0%BD%D0%B0%D0%BB%D1%8B-%D0%93%D0%9E-%D0%B4%D0%BE%D0%BC.pdf',
    title: 'Сигналы ГО дом',
  },
  {
    href: 'https://storage.yandexcloud.net/dunice-public-media/emergency/%D0%A1%D0%B8%D0%B3%D0%BD%D0%B0%D0%BB%D1%8B-%D0%93%D0%9E-%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%B0.pdf',
    title: 'Сигналы ГО работа',
  },
  {
    href: 'https://storage.yandexcloud.net/dunice-public-media/emergency/%D0%A2%D1%80%D0%B5%D0%B2%D0%BE%D0%B6%D0%BD%D1%8B%D0%B8%CC%86-%D1%87%D0%B5%D0%BC%D0%BE%D0%B4%D0%B0%D0%BD%D1%87%D0%B8%D0%BA.pdf',
    title: 'Тревожный чемоданчик',
  },
  {
    href: 'https://storage.yandexcloud.net/dunice-public-media/emergency/%D0%AD%D0%B2%D0%B0%D0%BA%D1%83%D0%B0%D1%86%D0%B8%D1%8F-%D0%BD%D0%B0%D1%81%D0%B5%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F.pdf',
    title: 'Эвакуация населения',
  },
]


const Emergency = () => {
  return (
    <section className="emergency container-with-fixed-header">
      <div className="emergency__wrapper">
        <h1 className="emergency__title">
          Общие правила поведения при ЧС
        </h1>
        <div className="emergency__steps">
          <div className="emergency__steps-item">
            Услышав сигналы предупреждения о непосредственной угрозе нападения противника, действуйте быстро.
            <br />Не поддавайтесь паническим настроениям.
            Сигнал «Воздушная тревога» подается для предупреждения всего населения о возникшей непосредственной угрозе ракетной и авиационной опасности по поражению противником данного муниципального района (городского округа) с воздуха.
          </div>
          <div className="emergency__steps-item">
            {EMERGENCY_LIST.title}

            <ul className="emergency__list">
              {EMERGENCY_LIST.items.map((text, index) => (
                <li className="emergency__list-item" key={index}>
                {text}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <h2 className="emergency__title">
          Полезные материалы
        </h2>
        <div className="emergency__steps-item">
          <ul className="emergency__list">
            {MATERIALS.map(({ href, title }) => (
              <li className="emergency__list-item" key={href}>
                <a href={href} target="__blank">{title}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
};

export default Emergency;
