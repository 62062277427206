import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';

import { PAGINATION_EVENTS } from '../../../gql';
import './EventsList.css';
import { Loader } from '../..';

class EventsList extends Component {
  static propTypes = {
    variables: PropTypes.shape(),
    history: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    variables: {},
  };

  goToEventPage = (id) => {
    this.props.history.push(`/event/${id}`);
  };

  renderEventType = event => {
    const classList = `ui pseudo-segment event-card_header_buttons event-type-${event.eventType.name}`;
    return (
      <div className={classList}>
        <div className="ui button participants only-icon-for-small-width">
          {event.eventType.rusName}
        </div>
      </div>
    );
  }

  renderHeaderButtons = event => (
    <div className="ui pseudo-segment event-card_header_buttons">
      <div className="ui button participants only-icon-for-small-width">
        {event.participants.length}
        <span> участников</span>
      </div>
    </div>
  );

  renderEventCard = (event = {}) => (
    <div
      className="ui segments event-card"
      key={event.id}
      onClick={() => this.goToEventPage(event.id)}
    >
      <div className="ui horizontal segments event-card_header">
        <div className="ui pseudo-segment event-card_header_number">
          <b>{event.id}</b>
        </div>
        <div className="ui segment event-card_header_title">
          <b>{event.name}</b>
        </div>
        {this.renderEventType(event)}
        {this.renderHeaderButtons(event)}
      </div>

      <div className="ui segment event-card_mobile-sub-header">
        {this.renderEventType(event)}
        {this.renderHeaderButtons(event)}
      </div>

      <div className="ui segment event-card_description">
        <div>
          Дата окончания регистрации:  <b>{moment(new Date(event.endRegistrationsAt)).format('LL')}</b>
        </div>
        <div>
          Дата проведения:  <b>{moment(new Date(event.eventStartAt)).format('LL')}</b>
        </div>
        <div>
          Организатор:  <b>{event.owner.firstName} {event.owner.lastName}</b>
        </div>
      </div>

    </div>
  )

  render() {
    const policy = this.props.variables.filter && this.props.variables.filter.search ? 'no-cache' : 'cache-first';
    return (
      <div className="EventsList">
        <Query
          query={PAGINATION_EVENTS}
          variables={this.props.variables}
          fetchPolicy={policy}
        >
          {({
            loading, error, data, fetchMore,
          }) => {
            if (loading) return <Loader outerStyles={{ minHeight: '250px' }} />;
            if (error) throw new Error(error);

            const { paginationEvents: { results: events = [], cursors = {} } = {} } = data || {};
            const loadMore = () => fetchMore({
              variables: { ...this.props.variables, pageInfo: { after: cursors.after } },
              updateQuery: (previousResult = {}, { fetchMoreResult = {} }) => {
                const previousSearch = previousResult.paginationEvents || {};
                const currentSearch = fetchMoreResult.paginationEvents || {};
                const previousNodes = previousSearch.results || [];
                const currentNodes = currentSearch.results || [];
                return {
                  ...previousResult,
                  paginationEvents: {
                    ...previousSearch,
                    results: [...previousNodes, ...currentNodes],
                    cursors: currentSearch.cursors,
                  },
                };
              },
            });

            return (
              <InfiniteScroll
                pageStart={0}
                loadMore={loadMore}
                hasMore={cursors.hasNext}
                useWindow
                loader={<div className="loader" key={0}>Загрузка ...</div>}
              >
                {events.length > 0
                  ? events.map(event => this.renderEventCard(event))
                  : (
                    <div className="no-list">
                      {this.props.variables.filter.search
                        ? 'Ничего не найдено :('
                        : 'Никаких событий.'
                    }
                    </div>
                  )
                }
              </InfiniteScroll>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default EventsList;
