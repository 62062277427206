
import _ from 'lodash';
import { Link, withRouter } from 'react-router-dom';

import './index.scss';

function HandbookPrinciples(props) {
    return (
        <div className="handbook-principles handbook__section">
        <h1 className="handbook__title">
          Наши стандарты и принципы
        </h1>
        <div className="handbook-principles__container">
          {_.chain(props.data)
          .chunk(3)
          .map( (c, ind) => {
            return <div key={ind} className="handbook-principles__flex-column">
              {c.map( ({icon, Content}) => 
                <div key={icon} className="handbook-principles__item">
                  <img className="handbook-principles__item-icon" alt="icon" src={require(`./icons/${icon}.png`).default}/>
                  <div className="handbook-principles__item-content">
                    <Content className="handbook-principles__item-content"/>
                  </div>
                </div>
              )}
            </div>
          }).value()}
          
        </div>
        
      </div>        
    )
}

export default withRouter(HandbookPrinciples);