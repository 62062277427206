import gql from 'graphql-tag';
import { FRAGMENT_EVENT_COMMENT_WITH_USER } from '../';

export const EVENT_COMMENTS = gql`
    query eventComments($eventId: ID!) {
        eventComments(eventId: $eventId) {
            ...EventCommentWithUser
        }
    }
    ${FRAGMENT_EVENT_COMMENT_WITH_USER}
`;
