import { PAGINATION_VACATIONS } from '../../gql';
import { VACATION_STATUSES } from '../../environment';

export const updateVacationsCache = (client, vacation) => {
  const queries = [
    { query: PAGINATION_VACATIONS, variables: { filter: {} } },
    { query: PAGINATION_VACATIONS, variables: { filter: { status: VACATION_STATUSES.waiting } } },
    { query: PAGINATION_VACATIONS, variables: { filter: { status: VACATION_STATUSES.done } } },
    { query: PAGINATION_VACATIONS, variables: { filter: { status: VACATION_STATUSES.rejected } } },
  ];
  queries.forEach((query) => {
    try {
      const cache = client.cache.readQuery(query);
      // vacation was created
      if (vacation.status === VACATION_STATUSES.waiting) {
        // add to 'all' and 'waiting'
        if (query.variables.filter.status === VACATION_STATUSES.waiting || !query.variables.filter.status) {
          // add if not exists in cache
          if (!cache.paginationVacations.results.find(({ id }) => id === vacation.id)) {
            cache.paginationVacations.results.unshift(vacation);
          }
        }
        // vacation was rejected/approved
      } else {
        // remove from 'waiting' list
        if (query.variables.filter.status === VACATION_STATUSES.waiting) {
          cache.paginationVacations.results = cache.paginationVacations.results.filter(r => r.id !== vacation.id);
          // add to appropriate list (rejected/approved)
        } else if (query.variables.filter.status === vacation.status) {
          // add if not exists in cache
          if (!cache.paginationVacations.results.find(({ id }) => id === vacation.id)) {
            cache.paginationVacations.results.unshift(vacation);
          }
        }
      }
      client.cache.writeQuery({ ...query, data: cache });
    } catch (error) {}
  });
};
