import gql from 'graphql-tag';

export const FRAGMENT_REQUEST_CORRELATION = gql`
    fragment RequestCorrelation on RequestCorrelation {
        done
        rejected
        all
    }
`;

export const FRAGMENT_ORDER_CORRELATION = gql`
    fragment CostCorrelation on CostCorrelation {
        totalCost
        additionalCost
        payByCompany
    }
`;
export const FRAGMENT_REQUEST_STATISTIC = gql`
    fragment RequestStatistic on RequestStatistic {
        total {
            ...RequestCorrelation
        }
        year(year: $year) {
            ...RequestCorrelation
        }
        month(month: $month, year: $year) {
            ...RequestCorrelation
        }
    }
    ${FRAGMENT_REQUEST_CORRELATION}
`;

export const FRAGMENT_SICK_STATISTIC = gql`
    fragment SickStatistic on SickStatistic {
        total
        year(year: $year)
        month(month: $month, year: $year)
    }
`;

export const FRAGMENT_VACATION_STATISTIC = gql`
    fragment VacationStatistic on VacationStatistic {
        total
        year(year: $year)
        month(month: $month, year: $year)
    }
`;

export const FRAGMENT_ORDER_STATISTIC = gql`
    fragment OrderStatistic on OrderStatistic {
        currentWeek {
            ...CostCorrelation
        }
        year(year: $year) {
            ...CostCorrelation
        }
        month(month: $month, year: $year) {
            ...CostCorrelation
        }
    }
    ${FRAGMENT_ORDER_CORRELATION}
`;

export const FRAGMENT_CURRENT_ORDERS = gql`
    fragment CurrentOrders on OrderStatistic {
        month(month: $month, year: $year) {
            ...CostCorrelation
        }
    }
    ${FRAGMENT_ORDER_CORRELATION}
`;

export const FRAGMENT_CURRENT_VACATIONS = gql`
    fragment CurrentVacations on VacationStatistic {
        month(month: $month, year: $year)
    }
`;

export const FRAGMENT_CURRENT_SICKS = gql`
    fragment CurrentSicks on SickStatistic {
        month(year: $year, month: $month)
    }
`;

export const FRAGMENT_CURRENT_HOLLYDAYS = gql`
    fragment CurrentHolidays on CalendarStatistic {
        monthHolidays(year: $year, month: $month)
    }
`;

export const FRAGMENT_CURRENT_WORKDAYS = gql`
    fragment CurrentWorkdays on CalendarStatistic {
        monthWorkdays(year: $year, month: $month)
    }
`;

export const FRAGMENT_USER_FULL_STATISTIC = gql`
    fragment UserFullStatistic on Statistic {
        requests(year: $year, month: $month) {
            ...RequestStatistic
        }
        sicks(year: $year, month: $month) {
            ...SickStatistic
        }
        vacations(year: $year, month: $month) {
            ...VacationStatistic
        }
        orders(year: $year, month: $month) {
            ...OrderStatistic
        }
    }
    ${FRAGMENT_REQUEST_STATISTIC}
    ${FRAGMENT_VACATION_STATISTIC}
    ${FRAGMENT_SICK_STATISTIC}
    ${FRAGMENT_ORDER_STATISTIC}
`;

export const FRAGMENT_USER_SALARY_STATISTIC = gql`
    fragment UserSalaryStatistic on SalaryStatistic {
        sicks(year: $year, month: $month) {
            ...CurrentSicks
        }
        vacations(year: $year, month: $month) {
            ...CurrentVacations
        }
        orders(year: $year, month: $month) {
            ...CurrentOrders
        }
        daysOfMonth(year: $year, month: $month) {
            ...CurrentHolidays
            ...CurrentWorkdays
        }
    }
    ${FRAGMENT_CURRENT_VACATIONS}
    ${FRAGMENT_CURRENT_SICKS}
    ${FRAGMENT_CURRENT_ORDERS}
    ${FRAGMENT_CURRENT_HOLLYDAYS}
    ${FRAGMENT_CURRENT_WORKDAYS}
`;

export const FRAGMENT_ADMIN_SALARY_STATISTIC = gql`
    fragment AdminSalaryStatistic on SalaryStatistic {
        sicks(year: $year, month: $month) {
            ...CurrentSicks
        }
        vacations(year: $year, month: $month) {
            ...CurrentVacations
        }
        daysOfMonth(year: $year, month: $month) {
            ...CurrentHolidays
            ...CurrentWorkdays
        }
    }
    ${FRAGMENT_CURRENT_VACATIONS}
    ${FRAGMENT_CURRENT_SICKS}
    ${FRAGMENT_CURRENT_HOLLYDAYS}
    ${FRAGMENT_CURRENT_WORKDAYS}
`;
