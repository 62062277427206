import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import { titleCase } from '../../helpers';
import { ALL_USERS } from '../../gql';

import './UsersSelector.css';

class UsersSelector extends Component {
  static propTypes = {
    changeUser: PropTypes.func,
    user: PropTypes.shape(),
    filter: PropTypes.shape(),
    className: PropTypes.string,
  }
  static defaultProps = {
    user: {},
    filter: {},
    changeUser: () => {},
    className: '',
  }

  constructor(props) {
    super(props);
    this.refInput = React.createRef();
    this.containerInput = React.createRef();
    this.state = {
      isOpenDropdown: false,
      searchUser: this.getFullName(props.user),
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }
  handleClick = ({ target }) => {
    if (this.state.isOpenDropdown) {
      if (this.containerInput.current.contains(target)) return;
      this.setState({ isOpenDropdown: false });
    }
  }

  getFullName = user => (user.firstName ? `${titleCase(user.firstName)} ${titleCase(user.lastName)}` : '');

  onChangeUser = (user) => {
    this.setState({ isOpenDropdown: !this.state.isOpenDropdown, searchUser: this.getFullName(user) });
    this.props.changeUser(user);
  }

  clearSelector = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.refInput.current.focus();
    this.setState({ searchUser: '', isOpenDropdown: true });
    this.props.changeUser({});
  }

  filterList = list => list.filter(e => (
    e.firstName.toLowerCase().includes(this.state.searchUser.toLowerCase())
    || e.lastName.toLowerCase().includes(this.state.searchUser.toLowerCase())
    || this.getFullName(e).toLowerCase().includes(this.state.searchUser.toLowerCase())
  ));

  render() {
    return (
      <div className={`UsersSelector ${this.props.className}`}>
        <Query
          query={ALL_USERS}
          variables={{ filter: this.props.filter }}
        >
          {({ data }) => {
            const users = (data && data.allUsers) || [];

            return (
              <div
                className={`ui selection search dropdown ${this.state.isOpenDropdown && 'active visible'}`}
                onClick={() => this.setState({ isOpenDropdown: !this.state.isOpenDropdown })}
                ref={this.containerInput}
              >
                <input
                  type="text"
                  name="user"
                  className="search"
                  value={this.state.searchUser}
                  autoComplete="off"
                  placeholder="Сотрудник..."
                  ref={this.refInput}
                  onChange={({ target: { value } }) => this.setState({ searchUser: value, isOpenDropdown: true })}
                />
                {this.state.searchUser &&
                  <i
                    className="times circle outline icon search-cross-icon"
                    onClick={e => this.clearSelector(e)}
                  />
                }
                <i className="dropdown icon" />
                <div className={`menu transition ${this.state.isOpenDropdown && 'visible'}`}>
                  {this.filterList(users).map(user => (
                    <div
                      key={user.id}
                      className={`item ${user.id === (this.props.user.id) && 'active selected'}`}
                      data-value={this.getFullName(user)}
                      onClick={() => this.onChangeUser(user)}
                    >
                      {this.getFullName(user)}
                    </div>
                  ))}
                </div>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default UsersSelector;
