import gql from 'graphql-tag';
import { FRAGMENT_USER_MAIN_INFO } from '../';

export const SIGNUP = gql`
    mutation signup($user: UserCredentials!) {
        signup(user: $user) {
            ...UserMainInfo
            token
        }
    }
    ${FRAGMENT_USER_MAIN_INFO}
`;
export const CREATE_TEMP_PASSWORD = gql`
    mutation createTempPassword($phone: String!, $reCaptcha: String!) {
        createTempPassword(phone: $phone,  reCaptcha: $reCaptcha)
    }
`;
export const SEND_SMS = gql`
    mutation createPhone($phone: String!) {
        createPhone(phone: $phone)
    }
`;