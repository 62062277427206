import React, { Component } from 'react';
import { Query } from 'react-apollo';

import { getErrorMessages, titleCase } from '../../../helpers';
import { ROLES } from '../../../gql';
import { AdminEditRole } from '../';
import './AdminUserRoles.css';
import { Loader, UsersSelector } from '../../../components';

class AdminUserRoles extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formError: new Map(),
      isOpenEditRole: false,
      isOpenEditIp: false,
      selectedRole: { id: '1' },
    };
  }

  onError = (error) => {
    const errorMessage = getErrorMessages(error);
    const { formError } = this.state;
    formError.set('response', errorMessage[0]);
    this.setState({ formError });
  }
  onOpenModal = (setting) => {
    this.state[`isOpenEdit${titleCase(setting.name)}`] = true;
    this.state[setting.name] = setting.value;
    this.setState(this.state);
  }

  closeModals = () => {
    this.setState({ isOpenEditRole: false });
  }

  renderAdminEditRole = () => (
    <AdminEditRole
      active={this.state.isOpenEditRole}
      onClose={this.closeModals}
      role={this.state.selectedRole}
    />
  )
  changeUser = ({ id }) => this.props.history.push(`/profile/${id}`);
  changeSelectedRole = (selectedRole) => {
    if (selectedRole.id === this.state.selectedRole.id) {
      this.setState({ isOpenEditRole: true, selectedRole });
    } else {
      this.setState({ selectedRole });
    }
  }

  render() {
    return (
      <Query
        query={ROLES}
      >
        {({ loading, error, data }) => {
          if (loading) return <Loader />;
          if (error) throw new Error(error);

          const list = data.roles || [];
          return (
            <React.Fragment>
              <div className="AdminUserRoles">
                {this.state.isOpenEditRole && this.renderAdminEditRole()}
                <div className="ui raised segment flex" >
                  <div>
                    <b>Доступные роли:</b> выбрав роль вам будет доступен список сотрудников с этой ролью,
                    выберите сотрудника чтобы перейти на его профиль и редактировать роль.
                  </div>
                  {list.map(e => (
                    <div className="editAdminUserRoles" key={e.id}>
                      <div className="ui right labeled left icon input" onClick={() => this.changeSelectedRole(e)}>
                        <i className={`icon user`} />
                        <input type="text" value={e.rusName} className={`${this.state.selectedRole.id !== e.id && 'disabled'}`} />
                        <div className={`ui basic label inner-label ${this.state.selectedRole.id !== e.id && 'disabled'}`}>
                          {e.name}
                        </div>
                        <a className={`ui label ${this.state.selectedRole.id === e.id ? 'tag' : 'right-attached-button'}`}>
                          {this.state.selectedRole.id === e.id ? 'Редактировать' : 'Выбрать'}
                        </a>
                      </div>
                      {this.state.selectedRole.id === e.id &&
                        <div className="ui icon input user-selector-wrapper">
                          <UsersSelector user={this.state.userFilter} changeUser={this.changeUser} filter={{ roleId: e.id }} />
                        </div>
                      }
                    </div>
                  ))}
                </div>
              </div>
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}

export default AdminUserRoles;
