import gql from 'graphql-tag';

export const FRAGMENT_EVENT_INFO = gql`
    fragment EventInfo on Event {
        id
        name
        description
        minParticipants
        endRegistrationsAt
        eventStartAt
        createdAt
        ownerId
        owner {
            id
            firstName
            lastName
        }
        eventTypeId
        eventType {
            id
            name
            rusName
        }
        participants {
            id
            firstName
            lastName
            avatarUrl
        }
    }
`;
