import gql from 'graphql-tag';
import { FRAGMENT_VACATION_INFO } from '../';

export const VACATIONS = gql`
    query vacations($filter: Filter) {
        vacations(filter: $filter) @connection(key: "vacations", filter: ["filter"]) {
            ...VacationInfo
        }
    }
    ${FRAGMENT_VACATION_INFO}
`;

export const VACATION_BY_ID = gql`
    query vacationById($id: ID!) {
        vacationById(id: $id) {
            ...VacationInfo
        }
    }
    ${FRAGMENT_VACATION_INFO}
`;

export const PAGINATION_VACATIONS = gql`
    query paginationVacations($filter: Filter, $pageInfo: PageInfo) {
        paginationVacations(filter: $filter, pageInfo: $pageInfo) @connection(key: "paginationVacations", filter: ["filter", "pageInfo"]) {
            results {
                ...VacationInfo
            }
            cursors {
                hasNext
                hasPrevious
                before
                after
            }
        }
    }
    ${FRAGMENT_VACATION_INFO}
`;
