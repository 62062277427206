import { EVENT_COMMENTS } from '../../gql';

export const updateEventCommentsCache = (client, eventComment) => {
  const queries = [
    { query: EVENT_COMMENTS, variables: { eventId: eventComment.eventId } },
  ];
  queries.forEach((query) => {
    try {
      const cache = client.cache.readQuery(query);
      // add if not exists in cache
      if (!cache.eventComments.find(r => r.id === eventComment.id)) {
        cache.eventComments.push(eventComment);
      }
      client.cache.writeQuery({ ...query, data: cache });
    } catch (error) {}
  });
};
