import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query, Mutation, graphql } from 'react-apollo';
import { Redirect, withRouter } from 'react-router-dom';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import InfiniteScroll from 'react-infinite-scroller';

import { declOfNum, sortList } from '../../../helpers';
import { COMMON_SUBSCRIPTION, EDIT_SICK, PAGINATION_SICK, SICK_BY_ID } from '../../../gql';
import { Loader, Modal, UserContext } from '../..';

import './SIckList.css';

class SickList extends Component {
  static propTypes = {
    history: PropTypes.shape().isRequired,
    location: PropTypes.shape().isRequired,
    variables: PropTypes.shape(),
  }
  static defaultProps = {
    variables: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      isOpenSickModal: false,
      selectedSick: {},
      initialSickData: {},
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (!nextProps.data) return null;
    const { loading } = nextProps.data;
    const selectedSick = (nextProps.data.sickById || {});
    if (!loading && !state.selectedSick.id) {
      if (moment(new Date(selectedSick.startAt)).isAfter(moment()) && selectedSick.endAt == null) {
        selectedSick.endAt = sick.startAt;
      } else if (selectedSick.endAt == null) {
        selectedSick.endAt = new Date().toISOString()
      }
      return { isOpenSickModal: true, selectedSick };
    }
    return null;
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  stylesByDate = (sick) => {
    const today = moment().startOf('day').hours(12);
    if (moment(new Date(sick.endAt)).diff(moment(), 'days') < 0) return 'blue';
    if (moment(new Date(sick.startAt)).diff(today, 'days') > 0) return 'yellow';
    return 'green';
  }

  showSickModal = (sick) => {
    this.props.history.push({
      ...this.props.location,
      search: `?id=${sick.id}`,
    });
    let endAt = sick.endAt;
    if (moment(new Date(sick.startAt)).isAfter(moment()) && sick.endAt == null) {
      endAt = sick.startAt;
    } else if (sick.endAt == null) {
      endAt = new Date().toISOString()
    }
    this.setState({
      isOpenSickModal: true,
      selectedSick: {
        ...sick,
        endAt,
      },
      initialSickData: { ...sick },
    });
  };
  hideSickModal = () => {
    this.props.history.push({
      ...this.props.location,
      search: '',
    });
    this.setState({ isOpenSickModal: false, selectedSick: { newComment: '' } });
  };

  datesSortable = (list) => {
    const today = moment().startOf('day').hours(12);
    const blue = sortList({
      list: list.filter(sick => sick.endAt && moment(new Date(sick.endAt)).diff(moment(), 'days') < 0),
      sortBy: 'startAt',
      type: 'date',
      sortDirection: 'DESC',
    });
    const yellow = sortList({
      list: list.filter(sick => moment(new Date(sick.startAt)).diff(today, 'days') > 0),
      sortBy: 'startAt',
      type: 'date',
      sortDirection: 'DESC',
    });
    const green = sortList({
      list: list.filter(sick => !(moment(new Date(sick.startAt)).diff(today, 'days') > 0 || sick.endAt != null && moment(new Date(sick.endAt)).diff(moment(), 'days') < 0)),
      sortBy: 'startAt',
      type: 'date',
      sortDirection: 'DESC',
    });

    return [...yellow, ...green, ...blue];
  }

  renderSelectedSick = () => {
    const { selectedSick, initialSickData } = this.state;
    const isNextHalfOfMonth = moment().date() >= 15;
    let minDate = isNextHalfOfMonth ? moment({ date: 15 }) : moment().startOf('month');
    const startAtMoment = moment(new Date(selectedSick.startAt));
    if (minDate.isBefore(startAtMoment)) {
      minDate = startAtMoment;
    }
    const maxDate = isNextHalfOfMonth ? moment().endOf('month') : moment({ date: 14 });
    return (
      <div className="full-width">
        <h3 className="ui image header">
          <div className="content white-space-pre-line">
            {selectedSick.owner && selectedSick.owner.firstName} {selectedSick.owner && selectedSick.owner.lastName}
            <div className="sub header">{selectedSick.comment}</div>
            <div className="sub header">{selectedSick.editComment}</div>
          </div>
        </h3>
        <div className="edit-sick-modal_date-pickers">
          <div className="start-time">
            <label htmlFor="startAt">Начало</label>
            <div className={`ui left input `}>
              <DatePicker
                disabled
                selected={moment(new Date(selectedSick.startAt))}
                selectsStart
                startDate={moment(new Date(selectedSick.startAt))}
                endDate={moment(new Date(selectedSick.endAt))}
                onChange={value => this.setState({ selectedSick: { ...this.state.selectedSick, startAt: value } })}
                onKeyDown={e => e.preventDefault()}
              />
            </div>
          </div>
          <div className="end-time">
            <label htmlFor="endAt">Конец</label>
            <div className={`ui left input `}>
              <DatePicker
                selected={moment(new Date(selectedSick.endAt))}
                selectsEnd
                disabled={initialSickData.endAt != null}
                minDate={minDate}
                maxDate={maxDate}
                startDate={moment(new Date(selectedSick.startAt))}
                endDate={moment(new Date(selectedSick.endAt))}
                onChange={value => this.setState({ selectedSick: { ...this.state.selectedSick, endAt: value } })}
                onKeyDown={e => e.preventDefault()}
              />
            </div>
          </div>
        </div>

        {
          initialSickData.endAt == null && (
            <h4 className={`field white-space-pre-line`}>
              <label htmlFor="comment">Комментарий для редактирования (не обязателен)</label>
              <div className="ui left icon">
                <textarea
                  rows={3}
                  type="text"
                  name="comment"
                  value={this.state.selectedSick.newComment}
                  onChange={({target: { value }}) => this.setState({ selectedSick: { ...this.state.selectedSick, newComment: value } })}
                />
              </div>
            </h4>
          )
        }
      </div>
    )
  }

  modalFooter = () => {
    const { initialSickData, selectedSick } = this.state;
    return (
      <Mutation mutation={EDIT_SICK} onCompleted={this.hideSickModal} >
        {(editSick) => (
          <div className="actions">
            <div
              className={`ui positive right labeled icon button ${initialSickData.endAt != null || selectedSick.endAt == null ? 'disabled' : ''}`}
              onClick={() => {
                if (initialSickData.endAt == null && selectedSick.endAt != null) {
                  editSick({
                    variables: {
                      id: this.state.selectedSick.id,
                      endAt: this.state.selectedSick.endAt,
                      startAt: this.state.selectedSick.startAt,
                      newComment: this.state.selectedSick.newComment,
                    },
                    refetchQueries: [
                      { query: PAGINATION_SICK, variables: this.props.variables },
                    ],
                  });
                }
              }}
            >
              Закрыть больничный
              <i className="checkmark icon" />
            </div>
          </div>
        )}
      </Mutation>
    );
  }

  renderEditSickModal = () => (
    <Modal
      active={this.state.isOpenSickModal}
      onClose={this.hideSickModal}
      header="Редактировать больничный"
      body={this.renderSelectedSick()}
      footer={this.modalFooter()}
    />
  )

  renderHeaderDurations = (sick) => {
    const days = sick.endAt != null && moment(sick.endAt).startOf('day').diff(moment(sick.startAt).startOf('day'), 'days') + 1
    return (
      <React.Fragment>
        <div className="ui segment sick-card_sub-header_durations">
          <span>Начало: <b>{moment(new Date(sick.startAt)).format('DD.MM.YY')}</b></span>
          { sick.endAt != null && <span className="durations_end-time">Конец: <b>{moment(new Date(sick.endAt)).format('DD.MM.YY')}</b></span> }
        </div>
        <div className="ui segment sick-card_mobile-sub-header_durations">
          С:&nbsp;<b>{moment(new Date(sick.startAt)).format('DD.MM.YY')}</b>
          { sick.endAt != null && <span className="durations_end-time">До:&nbsp;<b>{moment(new Date(sick.endAt)).format('DD.MM.YY')}</b></span> }
        </div>
        {
          sick.endAt != null && (
            <div className={`ui segment sick-card_sub-header_days ${this.stylesByDate(sick)}`}>
              <b>
                {days} {declOfNum(days, ['день', 'дня', 'дней'])}
              </b>
            </div>
          )
        }
      </React.Fragment>
    );
  }
  renderSickCard = (sick = {}) => (
    <div
      className="ui segments sick-card"
      key={sick.id}
      onClick={() => this.showSickModal(sick)}
    >
      <div className="ui horizontal segments sick-card_header">
        <div className="ui pseudo-segment sick-card_header_number">
          <b>{sick.id}</b>
        </div>
        <div className="ui segment sick-card_header_owner">
          <b>
            {sick.owner && sick.owner.firstName} {sick.owner && sick.owner.lastName}
          </b>
        </div>
      </div>

      {sick.comment &&
        <div className="ui segment sick-card_comments white-space-pre-line">
          {sick.comment}
          <div className="sub header">{sick.editComment}</div>
        </div>
      }

      <div className="ui horizontal segments sick-card_sub-header">
        {this.renderHeaderDurations(sick)}
      </div>
    </div>
  )

  render() {
    return (
      <div className="SickList">
        {this.renderEditSickModal()}
        <Query
          query={PAGINATION_SICK}
          variables={this.props.variables}
        >
          {({ subscribeToMore, loading, error, data, fetchMore }) => {
            if (loading) return <Loader />;
            if (error) throw new Error(error);

            const { paginationSick: { results: sickList = [], cursors = {} } = {} } = data || {};
            const loadMore = () => fetchMore({
              variables: { ...this.props.variables, pageInfo: { after: cursors.after } },
              updateQuery: (previousResult = {}, { fetchMoreResult = {} }) => {
                const previousSearch = previousResult.paginationSick || {};
                const currentSearch = fetchMoreResult.paginationSick || {};
                const previousNodes = previousSearch.results || [];
                const currentNodes = currentSearch.results || [];
                return {
                  ...previousResult,
                  paginationSick: {
                    ...previousSearch,
                    results: [...previousNodes, ...currentNodes],
                    cursors: currentSearch.cursors,
                  },
                };
              },
            });

            if (!this.unsubscribe) {
              this.unsubscribe = subscribeToMore({
                document: COMMON_SUBSCRIPTION,

                updateQuery: (prev, {subscriptionData}) => {
                  if (!subscriptionData.data) return prev;
                  const {commonSubscription: {sick} = {}} = subscriptionData.data;
                  /**
                   * we can different subscriptions data
                   * so "sick" can returning as "null"
                   */
                  if (!(sick && sick.id)) return prev;
                  if (!this.props.variables.filter.status) return prev;
                  const previousSearch = prev.paginationSick || {};
                  const previousNodes = previousSearch.results || [];

                  return {
                    ...prev,
                    paginationSick: {
                      ...previousSearch,
                      results: [sick, ...previousNodes.filter(r => r.id !== sick.id)],
                    },
                  };
                }
              })
            }

            return (
              <InfiniteScroll
                pageStart={0}
                loadMore={loadMore}
                hasMore={cursors.hasNext}
                useWindow
                loader={<div className="loader" key={'0'}>Загрузка ...</div>}
              >
                {this.datesSortable(sickList).map(sick => this.renderSickCard(sick))}
              </InfiniteScroll>
            )
          }}
        </Query>
      </div>
    )
  }
}

export default withRouter(graphql(SICK_BY_ID, {
  skip: ({ history }) => !history.location.search,
  options: ({ history }) =>
    ({
      variables: { id: history.location.search.split('id=')[1] },
    }),
})(SickList));
