import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { WebSocketLink } from 'apollo-link-ws';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { createUploadLink } from 'apollo-upload-client';

import 'react-datepicker/dist/react-datepicker.css';

import { unregister as  unregisterServiceWorker } from './registerServiceWorker';
import { MainContainer } from './components';
import { Forbidden } from './pages';

import './semantic/dist/semantic.css';
import './index.css';

const httpLink = `${process.env.REACT_APP_API_URL}/graphql`;

const mainLink = createUploadLink({
  uri: httpLink,
  headers: {
    'keep-alive': 'true',
  },
  credentials: 'include',
});

let wsLink;

const wsOptions = {
  reconnect: true,
  timeout: 2000,

  connectionCallback: (err) => {
    if (err) {
      wsLink.subscriptionClient.close(false, false);
      wsLink.subscriptionClient.connect();
    }
  },
};
wsLink = new WebSocketLink({ uri: `${process.env.REACT_APP_SUBSCRIPTION_URL}/subscription`, options: wsOptions });

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  mainLink,
);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

const App = () => (
  <ApolloProvider client={client}>
    <Router>
      <Switch>
        <Route exact path="/forbidden" component={Forbidden} />
        {/*<Route exact path="/login" component={Login} />*/}
        {/*<Route exact path="/signup" component={Signup} />*/}
        {/*<Route exact path="/forgot_password" component={ForgotPassword} />*/}
        <Route component={MainContainer} />
      </Switch>
    </Router>
  </ApolloProvider>
);

render(<App />, document.getElementById('root'));
unregisterServiceWorker();
// registerServiceWorker();
