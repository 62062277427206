import React from 'react';
import './ErrorHandler.css';
import { getErrorMessages } from '../../helpers';

function ErrorHandler(props) {
  if (!props.error) return null;
  const errorMessage = getErrorMessages(props.error);
  if (!errorMessage.length) return null;
  return (
    <div className="ui error message">
      {errorMessage}
    </div>
  )
}

export default ErrorHandler;
