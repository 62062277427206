import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './SideColumnWithMobileBottomLine.css';
import { Modal } from '../index';
import { sortList, isIPhone } from '../../helpers';

const _isIPhone = isIPhone();

/**
 *  This component wrapping "children" to Modal component
 *  if passed children - need pass only one button because it will has "ref" for open modal
 */
class SideColumnWithMobileBottomLine extends Component {
  static propTypes = {
    children: PropTypes.node,
    mainClass: PropTypes.string,
    menuList: PropTypes.arrayOf(PropTypes.shape()),
    changeContent: PropTypes.func,
    activeItem: PropTypes.string,
    isAdmin: PropTypes.bool,
  }
  static defaultProps = {
    mainClass: '',
    menuList: [],
    changeContent: null,
    children: null,
    isAdmin: false,
    activeItem: '',
  }

  constructor(props) {
    super(props);
    this.buttonRef = React.createRef();
    this.state = {
      showModalPanel: false,
    };
  }

  handleClickMobileButton = (target) => {
    if (this.buttonRef.current.contains(target)) return;
    setTimeout(() => this.setState({ showModalPanel: !this.state.showModalPanel }), !this.state.showModalPanel ? 0 : 150)
  }

  isActiveClass = (item) => {
    if (this.props.activeItem) {
      return this.props.activeItem === item.name && 'active';
    } else {
      return item[this.props.isAdmin ? 'adminPosition' : 'userPosition'] === 1 && 'active';
    }
  }

  render() {
    const contentStyles = { padding: '0 !important' };
    const list = sortList({ list: this.props.menuList, sortBy: this.props.isAdmin ? 'adminPosition' : 'userPosition' });
    return (
      <div className={`SideColumnWithMobileBottomLine ${this.props.mainClass}`}>
        <Modal
          hideCloseButton
          active={this.state.showModalPanel}
          onClose={this.handleClickMobileButton}
          body={this.props.children}
          footer={<div />}
          modalStyles={{ width: 'auto' }}
          contentStyles={contentStyles}
        />
        <div className={`bottom-line-sub-menu ${_isIPhone && 'iPhone-styles'}`}>
          {list.map(item=> {
            if (!this.props.isAdmin && item.userPosition < 1) return null;
            return (
              <div
                key={item.name}
                ref={this.props.children && this.buttonRef}
                className={`bottom-line-sub-menu_button ${this.isActiveClass(item)}`}
                onClick={() => (this.props.changeContent ? this.props.changeContent(item.name) : this.handleClickMobileButton())}
              >
                {item.title}
              </div>
            );
          })}
          <div className="bottom-line-sub-menu_empty-element" />
        </div>
      </div>
    );
  }
}

export default SideColumnWithMobileBottomLine;
