import gql from 'graphql-tag';
import { FRAGMENT_REQUEST_INFO } from '../';

export const SUBSCRIPTION_REQUESTS_CLOSED = gql`
    subscription requestClosed($ownerId: ID!) {
        requestClosed(ownerId: $ownerId) {
            ...RequestInfo
        }
    }
    ${FRAGMENT_REQUEST_INFO}
`;
export const SUBSCRIPTION_REQUESTS_CREATED = gql`
    subscription requestCreated($userId: ID!) {
        requestCreated(userId: $userId) {
            ...RequestInfo
        }
    }
    ${FRAGMENT_REQUEST_INFO}
`;
