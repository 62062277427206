import React, {
  memo,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import {
  getCroppedImg,
  getSquareCropPercents,
  getSquireCropPixels,
} from '../../../helpers';

import './CropAvatarModal.css';

function CropAvatarModal({
  avatarPreview,
  setCroppedImage,
  fileName,
}) {
  const [crop, setCrop] = useState({});
  const [currentCroppedImage, setCurrentCroppedImage] = useState(null);

  const saveCroppedImage = useCallback(
    async (pixelsCrop, percentCrop, image = null) => {
      const imageToSave = image || currentCroppedImage;

      if (imageToSave && pixelsCrop.width && pixelsCrop.height) {
        const croppedImage = await getCroppedImg(
          imageToSave,
          pixelsCrop,
          fileName,
        );

        setCroppedImage(croppedImage);
      }
    },
    [currentCroppedImage, fileName, setCroppedImage],
  );

  const onImageLoaded = useCallback(
    (image) => {
      const initialCropPixels = getSquireCropPixels(image);
      const initialCropPercents = getSquareCropPercents(image);

      setCurrentCroppedImage(image);
      setCrop(initialCropPercents);
      saveCroppedImage(initialCropPixels, initialCropPercents, image);
      return false;
    },
    [saveCroppedImage],
  );

  if (!avatarPreview) return null;

  return (
    <div className="crop-avatar-modal">
      <p>Фотография будет обрезана по выбранной области.</p>
      <ReactCrop
        src={avatarPreview}
        crop={crop}
        onChange={(newCrop, newPercentCrop) => setCrop(newPercentCrop)}
        onImageLoaded={onImageLoaded}
        onComplete={saveCroppedImage}
        imageStyle={{
          maxWidth: '100%',
          height: 'auto',
          maxHeight: 600,
        }}
        ruleOfThirds
      />
    </div>
  );
}

CropAvatarModal.propTypes = {
  avatarPreview: PropTypes.string,
  setCroppedImage: PropTypes.func.isRequired,
  fileName: PropTypes.string,
};

CropAvatarModal.defaultProps = {
  avatarPreview: null,
  fileName: '',
};

export default memo(CropAvatarModal);
