import React, { Component } from 'react';
import moment from 'moment';
import { debounce } from 'lodash';

import { EventsList, CreateEventForm, UserContext, PageSidePanel, SideColumnWithMobileBottomLine } from '../../components';

import './Events.css';

class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showContent: '',
      searchFilter: '',
    };
    this.debounce = debounce(value => this.setState({ searchFilter: value }), 500);
  }

  getListSidePanel = () => {
    return [
      {
        name: 'create',
        title: 'Новое событие',
        userPosition: -1,
        adminPosition: 3,
      },
      {
        name: 'past',
        title: 'Прошедшие события',
        userPosition: 2,
        adminPosition: 2,
      },
      {
        name: 'future',
        title: ' Грядущие события',
        userPosition: 1,
        adminPosition: 1,
      },
    ];
  };

  changeContent = showContent => this.setState({ showContent });

  renderContentByUser = () => {
    const { showContent, searchFilter } = this.state;
    const filter = { search: searchFilter };
    switch (showContent) {
      case 'create':
        return <CreateEventForm changeContent={this.changeContent} />;
      case 'past':
        filter.endDate = moment().startOf('day');
        break;
      case 'future':
        filter.startDate = moment().startOf('day');
        break;
      default:
        filter.startDate = moment().startOf('day');
        break;
    }
    return <EventsList variables={{ filter }} history={this.props.history} />;
  }

  search = ({ target }) => {
    const { value } = target;
    this.debounce(value);
  }

  render() {
    return (
      <div className="Events container-with-fixed-header">

        <UserContext.Consumer>
          {({ isRole }) => (
            <div className="html ui attached grid custom-reverse">
              <div className="ui column twelve wide">
                {(isRole(['admin', 'manager', 'hrmanager', 'eventManager']) ? this.state.showContent !== 'create' : true) &&
                <div className="flex-block search-block">
                  <div className={`ui icon input search-input ${isRole(['admin', 'manager', 'hrmanager', 'eventManager']) && 'for-admin'}`}>
                    <input
                      type="text"
                      placeholder="Поиск по названию события..."
                      onChange={this.search}
                    />
                    <i className="search link icon" />
                  </div>
                </div>
                }
                <div className={`
                    ${(isRole(['admin', 'manager', 'hrmanager', 'eventManager']) ? this.state.showContent !== 'create' : true) && 'padding-for-search-block'}
                `}>
                  {this.renderContentByUser()}
                </div>
              </div>
              <div className="ui column four wide">
                <PageSidePanel
                  changeContent={this.changeContent}
                  showContent={this.state.showContent}
                  isAdmin={isRole(['admin', 'manager', 'hrmanager', 'eventManager'])}
                  menuList={this.getListSidePanel()}
                  fixed
                />
              </div>
              <SideColumnWithMobileBottomLine
                changeContent={this.changeContent}
                activeItem={this.state.showContent}
                isAdmin={isRole(['admin', 'manager', 'hrmanager', 'eventManager'])}
                menuList={this.getListSidePanel()}
              />
            </div>
          )}
        </UserContext.Consumer>

      </div>
    );
  }
}

export default Events;
