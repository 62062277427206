import gql from 'graphql-tag';
import { FRAGMENT_EVENT_COMMENT_WITH_USER } from '../';

export const ADD_EVENT_COMMENT = gql`
    mutation addEventComment($eventId: ID!, $text: String!) {
        addEventComment(eventId: $eventId, text: $text) {
            ...EventCommentWithUser
        }
    }
    ${FRAGMENT_EVENT_COMMENT_WITH_USER}
`;
