import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation, Query } from 'react-apollo';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { declOfNum, getRangeOfDates, showErrors } from '../../../helpers';
import { CREATE_SICK, PAGINATION_SICK, RESERVED_VACATIONS_AND_SICK } from '../../../gql';
import formValidator from '../../../hoc/formValidator';

import './CreateSickForm.css';
import { Loader } from '../..';

class CreateSickForm extends Component {
  static propTypes = {
    changeContent: PropTypes.func.isRequired,
    form: PropTypes.shape(),
    variables: PropTypes.shape(),
  };

  static defaultProps = {
    form: {
      validate: () => {},
      onError: () => {},
      updateState: () => {},
      data: {},
      errors: {},
    },
    variables: {},
  };

  constructor(props) {
    super(props);
    props.form.data.set('startAt', moment());
    props.form.data.set('endAt', moment());
    props.form.updateState({ form: props.form.data });
  }

  onSubmit = (e, createSick) => {
    e.preventDefault();
    const { form } = this.props;
    const { data, errors } = form;
    if (errors.size) return;

    createSick({
      variables: {
        sick: {
          startAt: data.get('startAt'),
          comment: data.get('comment'),
        },
      },
      refetchQueries: [{ query: PAGINATION_SICK, variables: { filter: {} } }],
    });
  }

  handleChange = (name, value) => {
    const { form } = this.props;
    form.validate({ target: { name, value } });
  }

  getArrayOfDaysFromRange = (list) => {
    const daysList = [];
    list.forEach((e) => {
      daysList.push(...getRangeOfDates(moment(e.startAt), moment(e.endAt == null ? e.startAt : e.endAt)));
    });
    return daysList;
  }

  render() {
    const { changeContent, form, variables } = this.props;
    return (
      <Query
        query={RESERVED_VACATIONS_AND_SICK}
        variables={variables}
        fetchPolicy="cache-and-network"
      >
        {({ loading, error, data }) => {
          if (loading) return <Loader />;
          if (error) throw new Error(error);

          const { vacations = [], sick = [] } = data || {};
          const vacationDays = this.getArrayOfDaysFromRange(vacations);
          const sickDays = this.getArrayOfDaysFromRange(sick);
          const highlightWithRanges = [
            { 'react-datepicker__day--highlighted-custom-sick': sickDays },
            { 'react-datepicker__day--highlighted-custom-vacation': vacationDays },
          ];
          const hasActiveSick = sick.some((item) => item.endAt == null);
          const isNextHalfOfMonth = moment().date() >= 15;
          const minDate = isNextHalfOfMonth ? moment({ date: 15 }) : moment().startOf('month');
          const maxDate = isNextHalfOfMonth ? moment().endOf('month') : moment({ date: 14 });
          return (
            <div className="CreateSickForm">
              <h2 className="margin-15">Открыть больничный</h2>
              <Mutation
                mutation={CREATE_SICK}
                onCompleted={() => {
                  form.updateState({ form: new Map() });
                  changeContent('all');
                }}
                onError={(mutationError) => {
                  form.onError(mutationError);
                }}
              >
                {(createSick, { loading }) => (
                  <form
                    className={`ui large form segment flex-1-to-4 ${(form.errors.size) && 'error'}`}
                    onSubmit={e => this.onSubmit(e, createSick)}
                  >
                    <div className={`field ${form.errors.has('startAt') && 'error'}`}>
                      <label htmlFor="startAt">Начало</label>
                      <div className="ui left input ">
                        <DatePicker
                          name="startAt"
                          onChangeRaw={form.validate}
                          selected={form.data.get('startAt')}
                          minDate={minDate}
                          maxDate={maxDate}
                          highlightDates={highlightWithRanges}
                          excludeDates={[...vacationDays, ...sickDays]}
                          startDate={form.data.get('startAt')}
                          onChange={value => this.handleChange('startAt', value)}
                          onKeyDown={e => e.preventDefault()}
                        />
                      </div>
                    </div>
                    <div className={`field ${form.errors.has('comment') && 'error'}`}>
                      <label htmlFor="comment" className="with-textarea">Комментарий</label>
                      <div className="ui left input">
                        <textarea
                          rows={3}
                          type="text"
                          placeholder="комментарий (не обязательный)"
                          name="comment"
                          value={form.data.get('comment')}
                          onChange={form.validate}
                        />
                      </div>
                    </div>

                    <button
                      type="submit"
                      disabled={hasActiveSick}
                      className={`ui large green submit left button ${loading && 'loading'}  ${(loading || form.errors.size) && 'disabled'}`}
                    >
                    Открыть больничный
                    </button>
                    <div className="ui error message">
                      <ul className="list">
                        {showErrors(form.errors)}
                      </ul>
                    </div>

                  </form>
                )}
              </Mutation>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default formValidator(CreateSickForm);
