import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation, Query } from 'react-apollo';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import { showErrors, getRangeOfDates, declOfNum } from '../../../helpers';
import { Loader } from '../..';
import { CREATE_VACATION, RESERVED_VACATIONS_AND_SICK } from '../../../gql';
import formValidator from '../../../hoc/formValidator';
import Checkbox from '../../Checkbox/Checkbox';

import './CreateVacationForm.css';

class CreateVacationForm extends Component {
  static propTypes = {
    changeContent: PropTypes.func.isRequired,
    variables: PropTypes.shape(),
    form: PropTypes.shape(),
  }

  static defaultProps = {
    variables: {},
    form: {
      validate: () => {},
      onError: () => {},
      updateState: () => {},
      data: {},
      errors: {},
    },
  }

  constructor(props) {
    super(props);
    props.form.data.set('startAt', moment());
    props.form.data.set('endAt', moment());
    props.form.data.set('isUnpaid', true);
    props.form.updateState({ form: props.form.data });
  }

  handleChange = (name, value) => {
    this.props.form.validate({ target: { name, value } });
  }

  toggleUnpaid = () => {
    this.props.form.validate({
      target: {
        name: 'isUnpaid',
        value: !this.props.form.data.get('isUnpaid'),
      },
    });
  };

  checkValidTimeRange = () => {
    return this.checkMinDuration() || this.checkMaxDuration() || this.checkMinAvailable();
  }

  checkMinDuration = () => {
    const { data } = this.props.form;
    return moment(data.get('startAt').startOf('day')).diff(moment(data.get('endAt')), 'days', true) > 0;
  }
  checkMaxDuration = () => {
    const { data } = this.props.form;
    return moment(data.get('endAt')).diff(moment(data.get('startAt')), 'days', true) > 33;
  }
  checkMinAvailable = () => {
    const { data } = this.props.form;
    return moment(data.get('startAt')).diff(moment().subtract(1, 'days'), 'days', true) < 0;
  }

  getDuration = () => {
    const { data, errors } = this.props.form;
    const isDatesInvalid = errors.has('startAt') || errors.has('endAt');
    if (isDatesInvalid || moment(data.get('startAt')).diff(moment(data.get('endAt')), 'days', true) > 0) return 'Хм';

    const duration = moment(data.get('endAt')).diff(moment(data.get('startAt')).subtract(1, 'minutes'), 'days') + 1;
    return `${duration} ${declOfNum(duration, ['день', 'дня', 'дней'])}`;
  }

  onSubmit = (e, createVacation) => {
    e.preventDefault();
    const { data, errors } = this.props.form;
    if (errors.size) return;

    createVacation({
      variables: {
        vacation: {
          startAt: data.get('startAt'),
          endAt: data.get('endAt'),
          comment: data.get('comment'),
          isUnpaid: data.get('isUnpaid'),
        },
      },
    });
  }

  getArrayOfDaysFromRange = (list) => {
    const daysList = [];
    list.forEach(e => {
      const endAt = moment(e.endAt || new Date());
      daysList.push(...getRangeOfDates(moment(e.startAt), endAt));
    })
    this.props.variables.daysList = daysList;
    return daysList;
  }

  isUnpaidDisabled = () => {
    const { data } = this.props.form;
    const start = data.get('startAt');
    const end = data.get('endAt');
    return end.diff(start, 'days') < 6;
  }

  hasInvalidDates = () => {
    const { form, variables } = this.props;
    const startAt = form.data.get('startAt');
    const endAt = form.data.get('endAt');
    return variables.daysList.some((item) => item.isSame(startAt, 'day') || item.isSame(endAt, 'day'));
  }

  render() {
    const { changeContent, form } = this.props;
    return (
      <Query
        query={RESERVED_VACATIONS_AND_SICK}
        variables={this.props.variables}
        fetchPolicy="cache-and-network"
      >
        {({ loading, error, data }) => {
          if (loading) return <Loader />;
          if (error) throw new Error(error);

          const { vacations = [], sick = [] } = data || {};

          const vacationDays = this.getArrayOfDaysFromRange(vacations)
          const sickDays = this.getArrayOfDaysFromRange(sick)
          const highlightWithRanges = [
            { 'react-datepicker__day--highlighted-custom-sick': sickDays },
            { 'react-datepicker__day--highlighted-custom-vacation': vacationDays },
          ]

          return <div className="CreateVacationForm">
            <div className="ui floating message warning">
              <div className="header">
                Учёт отпусков
              </div>
              <p>Открывать заявку на отпуск необходимо не позднее чем за две недели</p>
              <p>Отпуска длительностью менее 7 дней берутся за свой счет</p>

            </div>
            <h2 className="margin-15">Открыть заявку на отпуск</h2>
            <Mutation
              mutation={CREATE_VACATION}
              onCompleted={() => {
                form.updateState({ form: new Map() });
                changeContent('all');
              }}
              onError={(err) => {
                form.onError(err);
                console.error('onError CREATE_VACATION', err);
              }}
            >
              {(createVacation, { loading }) => (
                <form
                  className={`ui large form segment flex-1-to-4 ${(form.errors.size || this.checkValidTimeRange()) && 'error'}`}
                  onSubmit={e => this.onSubmit(e, createVacation)}
                >
                  <div className="ui info icon message desktop-duration">
                    <i className="info letter icon "></i>
                    <div className="content">
                      Длительность: {this.getDuration()}
                    </div>
                  </div>
                  <div className={`field ${form.errors.has('startAt') && 'error'}`}>
                    <label htmlFor="startAt">Начало</label>
                    <div className={`ui left input `}>
                      <DatePicker
                        name="startAt"
                        onChangeRaw={this.props.form.validate}
                        selected={form.data.get('startAt')}
                        selectsStart
                        minDate={moment()}
                        highlightDates={highlightWithRanges}
                        excludeDates={[...vacationDays, ...sickDays]}
                        startDate={form.data.get('startAt')}
                        endDate={form.data.get('endAt')}
                        onChange={(value) => this.handleChange('startAt', value)}
                        onKeyDown={e => e.preventDefault()}
                      />
                    </div>
                  </div>
                  <div className={`field ${form.errors.has('endAt') && 'error'}`}>
                    <label htmlFor="endAt">Конец</label>
                    <div className={`ui left input `}>
                      <DatePicker
                        name="endAt"
                        onChangeRaw={this.props.form.validate}
                        selected={form.data.get('endAt')}
                        selectsEnd
                        minDate={form.data.get('startAt')}
                        highlightDates={highlightWithRanges}
                        excludeDates={[...vacationDays, ...sickDays]}
                        startDate={form.data.get('startAt')}
                        endDate={form.data.get('endAt')}
                        onChange={(value) => this.handleChange('endAt', value)}
                        onKeyDown={e => e.preventDefault()}
                      >
                        {/*TODO: NEED ADD CHECK TO SELECTED MONTH?*/}
                        {/*<div className="calendar-clue-container">*/}
                        {/*{vacationDays.length > 0 && (*/}
                          {/*<div className="react-datepicker__day--highlighted-custom-vacation calendar-clue">*/}
                            {/*Ваши отпуска.*/}
                          {/*</div>*/}
                        {/*)}*/}
                        {/*{sickDays.length > 0 && (*/}
                          {/*<div className="react-datepicker__day--highlighted-custom-sick calendar-clue">*/}
                            {/*Ваши больничные.*/}
                          {/*</div>*/}
                        {/*)}*/}
                        {/*</div>*/}
                      </DatePicker>
                    </div>
                  </div>
                  <div className={`field ${form.errors.has('comment') && 'error'}`}>
                    <label htmlFor="unpaid-checkbox" className="with-textarea">Неоплачиваемый</label>
                    <div className="ui left">
                      <Checkbox
                        checked={form.data.get('isUnpaid')}
                        disabled={this.isUnpaidDisabled()}
                        onChange={this.toggleUnpaid}
                      />
                    </div>
                  </div>
                  <div className="ui info icon message device-duration">
                    <i className="info letter icon "></i>
                    <div className="content">
                      Длительность: {this.getDuration()}
                    </div>
                  </div>
                  <div className={`field ${form.errors.has('comment') && 'error'}`}>
                    <label htmlFor="comment" className="with-textarea">Комментарий</label>
                    <div className={`ui left input`}>
                  <textarea
                    rows={3}
                    type="text"
                    placeholder="комментарий (не обязательный)"
                    name="comment"
                    value={form.data.get('comment')}
                    onChange={form.validate}
                  />
                    </div>
                  </div>

                  <button
                    type="submit"
                    disabled={this.hasInvalidDates()}
                    className={`ui large green submit left button ${loading && 'loading'} ${(loading || form.errors.size || this.checkValidTimeRange()) && 'disabled'}`}
                  >
                    Устроить себе отпуск
                  </button>
                  <div className="ui error message">
                    <ul className="list">
                      {showErrors(form.errors)}
                      {this.checkMinDuration() && form.errors.size === 0 &&
                      <li key="checkMinDuration">Кажется даты перепутаны.</li>
                      }
                      {this.checkMaxDuration() && form.errors.size === 0 &&
                      <li key="checkMaxDuration">Ого, скромнее надо быть.</li>
                      }
                      {this.checkMinAvailable() && form.errors.size === 0 &&
                      <li key="checkMinAvailable">Слишком далеко в прошлое.</li>
                      }
                    </ul>
                  </div>

                </form>
              )}
            </Mutation>
          </div>
        }}
      </Query>
    );
  }
}

export default formValidator(CreateVacationForm);
