import gql from 'graphql-tag';

export const FRAGMENT_NEWS_INFO = gql`
    fragment NewsInfo on News {
        id
        text
        hide
        title
        createdAt
    }
`;
