import gql from 'graphql-tag';
import { FRAGMENT_EVENT_INFO } from '../';

export const SUBSCRIPTION_EVENTS_EDITED = gql`
    subscription eventEdited {
        eventEdited {
            ...EventInfo
        }
    }
    ${FRAGMENT_EVENT_INFO}
`;
