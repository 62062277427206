import gql from 'graphql-tag';
import { FRAGMENT_SICK_INFO } from '../';

export const CREATE_SICK = gql`
    mutation createSick($sick: SickCredentials!) {
        createSick(sick: $sick) {
            ...SickInfo
        }
    }
    ${FRAGMENT_SICK_INFO}
`;

export const EDIT_SICK = gql`
    mutation editSick($id: ID!, $startAt: String!, $endAt: String!, $newComment: String) {
        editSick(id: $id, startAt: $startAt, endAt: $endAt, newComment: $newComment) {
            ...SickInfo
        }
    }
    ${FRAGMENT_SICK_INFO}
`;