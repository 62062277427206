import React from 'react';
import stylePropType from 'react-style-proptype';
import './Loader.css';

function Loader(props) {
  return (
    <div className="Loader ui active inverted dimmer" style={props.outerStyles}>
      <div className="ui large text loader">Загрузка</div>
    </div>
  );
}
Loader.propTypes = {
  outerStyles: stylePropType,
}
Loader.defaultProps = {
  outerStyles: {},
}

export default Loader;
