import React, { Component } from 'react';
import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype';

import { Mutation } from 'react-apollo';
import { SEND_SMS } from '../../gql';

import './SmsCode.css';

class SmsCode extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    code: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    phone: PropTypes.string,
    inputStyles: stylePropType,
    buttonStyles: stylePropType,
  }
  static defaultProps = {
    disabled: true,
    code: '',
    phone: '',
    inputStyles: {},
    buttonStyles: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      timer: null,
      seconds: 300,
    };
  }

  componentWillUnmount() {
    clearInterval(this.state.timer);
  }

  countDown = () => {
    const seconds = this.state.seconds - 1;
    if (seconds === 0) {
      clearInterval(this.state.timer);
      this.setState({ seconds: 300, timer: null });
    } else {
      this.setState({ seconds });
    }
  }

  startTimer = () => {
    const timer = setInterval(this.countDown, 1000);
    this.setState({ timer });
  }

  render() {
    const {
      disabled,
      inputStyles,
      code,
      onChange,
      onError,
      buttonStyles,
      phone,
    } = this.props;

    const {
      timer,
      seconds,
    } = this.state;

    return (
      <div className={`two fields ${disabled && 'disabled'}`}>
        <div className="field">
          <div className="ui left input" style={inputStyles}>
            <input
              type="number"
              name="code"
              placeholder="Код из смс"
              value={code}
              onChange={onChange}
            />
          </div>
        </div>
        <div className={`field ${timer && 'disabled'}`}>
          <Mutation mutation={SEND_SMS} onError={onError} onCompleted={this.startTimer} >
            {(createPhone) => {
              if (timer) {
                return (
                  <div className="ui fluid button labeled" >
                    <div style={buttonStyles} className="ui fluid large blue submit button">
                                    Повторить
                    </div>
                    <a className="ui basic label left pointing width50">
                      {seconds}
                    </a>
                  </div>
                );
              }
              return (
                <button
                  style={buttonStyles}
                  className="ui fluid large blue submit button"
                  onClick={() => !disabled && createPhone({ variables: { phone: String(phone) } })}
                >
                  Прислать код
                </button>
              );
            }}
          </Mutation>
        </div>
      </div>
    );
  }
}


export default SmsCode;
