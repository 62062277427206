import gql from 'graphql-tag';
import { FRAGMENT_DISH_FULL_INFO } from '../';

export const FRAGMENT_ORDER_INFO_WITH_USER_AND_DISHES = gql`
    fragment OrderInfoWithUserAndDishes on Order {
        id
        totalCost
        additionalCost
        payByCompany
        date
        withYou
        ownerId
        owner {
            id
            firstName
            lastName
            priority
        }
        dishes {
            dish {
                ...DishFullInfo
            }
            count
        }
    }
    ${FRAGMENT_DISH_FULL_INFO}
`;
