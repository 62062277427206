import gql from 'graphql-tag';
import { FRAGMENT_REQUEST_INFO } from '../';

export const REQUESTS = gql`
    query requests($filter: Filter) {
        requests(filter: $filter) @connection(key: "requests", filter: ["filter"]) {
            ...RequestInfo
        }
    }
    ${FRAGMENT_REQUEST_INFO}
`;

export const REQUEST_BY_ID = gql`
    query requestById($id: ID!) {
        requestById(id: $id) {
            ...RequestInfo
        }
    }
    ${FRAGMENT_REQUEST_INFO}
`;

export const PAGINATION_REQUESTS = gql`
    query paginationRequests($filter: Filter, $pageInfo: PageInfo) {
        paginationRequests(filter: $filter, pageInfo: $pageInfo) @connection(key: "paginationRequests", filter: ["filter", "pageInfo"]) {
            results {
                ...RequestInfo
            }
            cursors {
                hasNext
                hasPrevious
                before
                after
            }
        }
    }
    ${FRAGMENT_REQUEST_INFO}
`;
