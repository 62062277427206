import React, { Component } from 'react';
import moment from 'moment';

import {
  UserContext,
  PageSidePanel,
  SickList,
  CreateSickForm,
  UsersSelector,
  CurrentStatistic,
  SideColumnWithMobileBottomLine,
} from '../../components';
import { CURRENT_SICKS } from '../../gql';

import './Sick.css';

class Sick extends Component {
  state = {
    showContent: '',
    userFilter: {},
  };

  getListSidePanel = (isAdmin) => {
    const generalWord = isAdmin ? 'Все' : 'Мои';
    return [
      {
        name: 'create',
        title: 'Новый больничный',
        userPosition: 1,
        adminPosition: 2,
      },
      {
        name: 'all',
        title: `${generalWord} больничные`,
        userPosition: 2,
        adminPosition: 1,
      },
    ];
  };

  changeContent = showContent => this.setState({ showContent });
  changeUser = userFilter => this.setState({ userFilter });

  renderContentByUser = (user, isAdmin) => {
    const { showContent, userFilter } = this.state;
    const filter = {
      ownerId: userFilter.id,
    };
    switch (showContent) {
      case 'create':
        filter.endDate = moment().startOf('day');
        filter.ownerId = user.id;
        return <CreateSickForm changeContent={this.changeContent} variables={{ filter }} />;
      case 'all':
        return <SickList variables={{ filter }} />;
      default:
        if (!isAdmin) {
          filter.endDate = moment().startOf('day');
          filter.ownerId = user.id;
        }
        return isAdmin ?
          <SickList variables={{ filter }} />
          :
          <CreateSickForm changeContent={this.changeContent} variables={{ filter }} />;
    }
  };

  render() {
    return (
      <div className="Sick container-with-fixed-header">
        <UserContext.Consumer>
          {({ user, isRole }) => (
            <div className="html ui attached grid custom-reverse">
              <div className="ui column twelve wide">
                {(isRole(['admin', 'manager', 'hrmanager']) ? this.state.showContent !== 'create' : this.state.showContent === 'all') &&
                  <div className="flex-block search-block">
                    {isRole(['admin', 'manager', 'hrmanager']) &&
                      <UsersSelector user={this.state.userFilter} changeUser={this.changeUser} />
                    }
                    <div>
                      <div><i className="color-example yellow" /> Запланированные больничные.</div>
                      <div><i className="color-example green" /> Текущие больничные.</div>
                      <div><i className="color-example blue" /> Прошедшие больничные.</div>
                    </div>
                  </div>
                }
                <div className={`
                  ${(isRole(['admin', 'manager', 'hrmanager']) ? this.state.showContent !== 'create' : this.state.showContent === 'all') && 'padding-for-search-block'}
                  ${isRole(['admin', 'manager', 'hrmanager']) && 'for-admin'}
                `}
                >
                  {this.renderContentByUser(user, isRole(['admin', 'manager', 'hrmanager']))}
                </div>
              </div>
              <div className="ui column four wide">
                <div className="ui cards fixed mobile-side-menu">
                  <PageSidePanel
                    changeContent={this.changeContent}
                    showContent={this.state.showContent}
                    isAdmin={isRole(['admin', 'manager', 'hrmanager'])}
                    menuList={this.getListSidePanel(isRole(['admin', 'manager', 'hrmanager']))}
                  />
                  <CurrentStatistic type="currentSicks" query={CURRENT_SICKS} />
                </div>
              </div>
              <SideColumnWithMobileBottomLine
                menuList={this.getListSidePanel(isRole(['admin', 'manager', 'hrmanager']))}
                changeContent={this.changeContent}
                activeItem={this.state.showContent}
                isAdmin={isRole(['admin', 'manager', 'hrmanager'])}
              />
            </div>
          )}
        </UserContext.Consumer>
      </div>
    );
  }
}

export default Sick;
