import gql from 'graphql-tag';
import { FRAGMENT_ORDER_INFO_WITH_USER_AND_DISHES } from '../';

export const SUBSCRIPTION_ORDER_EDITED = gql`
    subscription orderEdited {
        orderEdited {
            ...OrderInfoWithUserAndDishes
        }
    }
    ${FRAGMENT_ORDER_INFO_WITH_USER_AND_DISHES}
`;
