import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';

import { VirtualizedSortableTable, Modal, Loader } from '../../../components';
import { AdminMenuForm } from '../../components';

import './AdminMenu.css';

import { MENU, CLEAN_MENU, EDIT_DISH, ADD_FAKE_MENU } from '../../../gql';

class AdminMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenAddDish: false,
      isOpenCleanMenu: false,
      isOpenAddFakeMenu: false,
      dish: {},
      formError: new Map(),
      dishFilter: '',
    };
  }

  onRowClick = ({ event, rowData }) => {
    const isCheckbox = event.target.type === 'checkbox' ||
      (event.target.className && event.target.className.includes('checkbox')) ||
      (event.target.firstChild && event.target.firstChild.className && event.target.firstChild.className.includes('checkbox'));

    if (isCheckbox) return;
    this.setState({ isOpenAddDish: !this.state.isOpenAddDish, dish: rowData });
  }

  filterList = list => list.filter(e => e.name.toLowerCase().includes(this.state.dishFilter.toLowerCase()) || e.description.toLowerCase().includes(this.state.dishFilter.toLowerCase()));

  tableHeader = () => ([
    {
      label: 'Добавить/Изьять',
      dataKey: 'id',
      width: 200,
      disableSort: true,
      cellRenderer: this.renderCheckBox,
    },
    {
      label: 'Наименование',
      dataKey: 'name',
      width: 300,
    },
    {
      label: 'Категория',
      dataKey: 'category',
      width: 200,
    },
    {
      label: 'Описание',
      dataKey: 'description',
      width: 300,
      flexGrow: 1,
    },
    {
      label: 'Цена',
      dataKey: 'cost',
      width: 100,
    },
    {
      label: 'В меню',
      dataKey: 'available',
      width: 100,
      cellRenderer: this.renderAvailableItem,
    },
  ]);

  toggleCleanMenu = () => this.setState({ isOpenCleanMenu: !this.state.isOpenCleanMenu });
  toggleAddDish = () => this.setState({ isOpenAddDish: !this.state.isOpenAddDish, dish: {} });
  toggleAddFakeMenu = () => this.setState({ isOpenAddFakeMenu: !this.state.isOpenAddFakeMenu });

  renderAddFakeMenu = () => (
    <Mutation mutation={ADD_FAKE_MENU} onError={error => console.error('ADD_FAKE_MENU --> ', error)} onCompleted={this.toggleAddFakeMenu} >
      {(addFakeMenu) => {
        return (
          <Modal
            active={this.state.isOpenAddFakeMenu}
            onClose={this.toggleAddFakeMenu}
            onSuccess={() => addFakeMenu({
              refetchQueries: [{ query: MENU }],
            })}
            header="Добавить блюда в меню (для тестирования)"
            body="Функционал доступен только в режиме тестирования сайта. Будут добавлены случайные 10 блюд."
            size="small"
          />
        )
      }}
    </Mutation>
  )

  renderMenuForm = client => (
    <AdminMenuForm
      active={this.state.isOpenAddDish}
      onClose={this.toggleAddDish}
      client={client}
      dish={this.state.dish}
    />
  )

  renderCheckBox = e => (
    <Mutation mutation={EDIT_DISH} >
      {editDish => (
        <div
          className="ui checkbox"
          onClick={() => {
            editDish({
              variables: {
                dish: {
                  id: e.rowData.id,
                  name: e.rowData.name,
                  description: e.rowData.description,
                  available: !e.rowData.available,
                  cost: +e.rowData.cost,
                },
              },
            });
          }}
        >
          <input type="checkbox" name="order" checked={e.rowData.available} />
        </div>
        )}
    </Mutation>
  )

  renderAvailableItem = e => (
    <i className={`${e.rowData.available ? 'green check' : 'times red'} icon`} />
  )

  renderCleanMenu = () => (
    <Mutation mutation={CLEAN_MENU} onError={this.onError} onCompleted={this.toggleCleanMenu} >
      {cleanMenu => (
        <Modal
          active={this.state.isOpenCleanMenu}
          onClose={this.toggleCleanMenu}
          onSuccess={cleanMenu}
          header="Удаление всех блюд из меню"
          body="Ты действительно хочешь оставить весь IBS Dunice без обеда?"
          size="small"
        />
          )}
    </Mutation>
  );

  render() {
    return (
      <Query
        query={MENU}
        variables={{ filter: {} }}
      >
        {({ client, loading, error, data }) => {
          if (loading) return <Loader />;
          if (error) throw new Error(error);

          const list = data.menu || [];
          return (

            <div className="AdminMenu" style={{ height: '100%' }}>
              <div className="html ui padded grid">
                <div className="column twelve wide">
                  <div className="ui icon input fluid">
                    <input type="text" placeholder="Поиск по блюдам..." onChange={({ target: { value } }) => this.setState({ dishFilter: value })} />
                    <i className="search link icon" />
                  </div>
                  <div className="ui card card-with-table tableWithSearch">
                    <VirtualizedSortableTable
                      list={this.filterList(list)}
                      tableHeader={this.tableHeader(list)}
                      onRowClick={this.onRowClick}
                    />
                  </div>
                </div>
                <div className="column four wide">
                  <div className="floated left">
                    <div className="overlay fixed ">
                      <div className="ui labeled icon vertical menu">
                        <a className="item" onClick={this.toggleAddDish}><i className="plus green icon" /> Добавить блюдо</a>
                        <a className="item" onClick={this.toggleCleanMenu}><i className="times red icon" /> Убрать все блюда из меню</a>
                        {/*<a className="item" onClick={this.toggleAddFakeMenu}><i className="wrench yellow icon" /> +10 блюд</a>*/}
                      </div>
                    </div>
                    {this.state.isOpenAddDish && this.renderMenuForm(client)}
                    {this.state.isOpenAddFakeMenu && this.renderAddFakeMenu()}
                    {this.renderCleanMenu()}
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default AdminMenu;
