import React, { PureComponent } from 'react';
import { Query } from 'react-apollo';

import { getErrorMessages, titleCase } from '../../../helpers';
import { SETTINGS } from '../../../gql';
import { AdminEditTime, AdminEditTraineeLunchLimit } from '..';
import { Loader } from '../../../components';
import './Settings.css';

const constName = {
  time: {
    title: 'Ограничение времени для заказа обеда:',
    tag: 'clock outline',
  },
  ip: {
    title: 'IP адрес с которого можно заказать обед или зарегистрироваться:',
    tag: 'server',
  },
  withYouBoxCost: {
    title: 'Стоимость контейнера для заказа обеда с собой:',
    tag: 'box',
  },
  traineeLunchLimit: {
    title: 'Ограничение суммы обеда для стажера:',
    tag: 'coffee',
  },
};

class Settings extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      formError: new Map(),
      isOpenEditTime: false,
      isOpenEditTraineeLunchLimit: false,
    };
  }

  onError = (error) => {
    const errorMessage = getErrorMessages(error);
    const { formError } = this.state;
    formError.set('response', errorMessage[0]);
    this.setState({ formError });
  };

  onOpenModal = (setting) => {
    const { name, value } = setting;
    const camelizedTitle = name.replace(name[0], name[0].toUpperCase());

    this.setState({
      [`isOpenEdit${camelizedTitle}`]: true,
      [name]: value,
    });
  };

  closeModals = () => {
    this.setState({
      isOpenEditTime: false,
      isOpenEditTraineeLunchLimit: false,
    });
  };

  renderAdminEditTime = () => {
    const { isOpenEditTime, time } = this.state;

    return (
      <AdminEditTime
        active={isOpenEditTime}
        onClose={this.closeModals}
        time={time}
      />
    );
  };

  render() {
    const {
      isOpenEditTime,
      isOpenEditTraineeLunchLimit,
      traineeLunchLimit,
    } = this.state;

    return (
      <Query
        query={SETTINGS}
      >
        {({ loading, error, data }) => {
          if (loading) return <Loader />;
          if (error) throw new Error(error);

          let list = data.settings || [];
          list = list.filter((item) => item.name !== 'time');
          return (
            <div className="Settings" style={{ height: 'calc(100% - 76px)' }}>
              {/*{isOpenEditTime && this.renderAdminEditTime()}*/}
              {/*{isOpenEditTraineeLunchLimit && (*/}
              {/*  <AdminEditTraineeLunchLimit*/}
              {/*    onClose={this.closeModals}*/}
              {/*    limit={traineeLunchLimit}*/}
              {/*  />*/}
              {/*)}*/}
              {list.map(e => (
                <div className="ui raised segment flex" key={e.id}>
                  {e.title || (constName[e.name] && constName[e.name].title)}
                  <div role="button" aria-hidden="true" className="editSettings" onClick={() => this.onOpenModal(e)}>
                    <div className="ui right labeled left icon input">
                      <i className={`icon ${e.tag || (constName[e.name] && constName[e.name].tag)}`} />
                      <input type="text" value={e.value} />
                      <span className="ui tag label">
                          Редактировать
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          );
        }}
      </Query>
    );
  }
}

export default Settings;
