import gql from 'graphql-tag';

export const FRAGMENT_SICK_INFO = gql`
    fragment SickInfo on Sick {
        id
        ownerId
        startAt
        endAt
        comment
        editComment
        owner {
            id
            firstName
            lastName
        }
    }
`;
