import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { Redirect } from 'react-router-dom';
import moment from 'moment';

import './EventView.css';
import { ADD_TO_EVENT, REMOVE_FROM_EVENT } from '../../../gql';
import { UserContext, Chat, Modal, HomeUserItem } from '../../';
import { titleCase } from '../../../helpers';

const description = `Суть игры в следующем: команды до 6 человек играют 8 раундов (с двумя перерывами).
Вопросы бывают уровня "Что? Где? Когда?", на знание новостей, музыки, событий и просто забавные.
Название раундов: Юмористический, Ребусный, Логический, Музыкальный, Визуальный, Логический, Решающий. Во время игры вы можете есть/пить т.д.

Участвовать могут только сотрудники и стажеры компании.

Составы команд будут случайными.`;

class EventView extends PureComponent {
  static propTypes = {
    event: PropTypes.shape().isRequired,
    onEdit: PropTypes.func.isRequired,
    checkbox: PropTypes.shape(),
    renderInfoBlock: PropTypes.func,
  }
  static defaultProps = {
    renderInfoBlock: () => {},
    checkbox: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      removableUser: {},
      isOpenRemoveModal: false,
    };
  }

  description = React.createRef();

  checkKey = key => ((Object.keys(this.props.checkbox).length > 0 && this.props.checkbox[key]) || this.props.event[key]);
  isParticipant = user => !!this.props.event.participants.find(participant => participant.id === user.id);

  renderMainInfoBlock = () => {
    const { event } = this.props;

    return (
      <div className="ui segments">
        <div className="ui segment">
          <h3 className="margin-15">Информация</h3>
        </div>
        <div className="ui segment">
          <div className="field">
            <div className="title">Организатор</div>
            <div className="text-padding">
              {event.owner.firstName} {event.owner.lastName}
            </div>
          </div>

          <div className="field">
            <div className="title">Окончание регистрации</div>
            <div className="text-padding">
              {moment(event.endRegistrationsAt).format('LLL')}
            </div>
          </div>

          <div className="field">
            <div className="title">Дата мероприятия</div>
            <div className="text-padding">
              {moment(event.eventStartAt).format('LLL')}
            </div>
          </div>

          <div className="field">
            <div className="title inline-flex">Минимальное количество человек</div>
            <div className="text-padding inline-flex">
              <a className={`ui orange circular large label ${event.minParticipants <= event.participants.length && 'green'}`} >
                {event.minParticipants}
              </a>
            </div>
          </div>
        </div>
        {this.renderParticipantsBlock()}
      </div>
    );
  }

  renderParticipantsBlock = () => {
    const { event } = this.props;
    return (
      <UserContext.Consumer>
        {({ user, isRole }) => (
          <React.Fragment>
            {!event.subData && !this.isParticipant(user) && moment(event.endRegistrationsAt).diff(moment(), 'minutes') > 0 &&
            <div className="ui segment">
              <Mutation
                mutation={ADD_TO_EVENT}
                onError={(error) => {
                  console.error('onError ADD_TO_EVENT', error);
                }}
              >
                {(addToEvent, { loading }) => (
                  <button
                    type="submit"
                    className={`ui large green submit left button fluid ${loading && 'loading disabled'}`}
                    onClick={() => this.handleAddToEvent(addToEvent)}
                  >
                    Точно пойду!
                  </button>
                )}
              </Mutation>
            </div>
            }
            <div className="ui segment">
              <h3 className="margin-15">Участники: {event.participants.length}</h3>
            </div>
            {event.participants.length > 0 &&
            <div className="ui segment top attached scrolling-block">
              <div className="ui doubling three cards">
                {event.participants.map(participant => (
                  <HomeUserItem
                    key={participant.id}
                    user={participant}
                    style={{ position: 'relative' }}
                    actionIcon={isRole(['admin', 'manager', 'hrmanager', 'eventManager']) &&
                      <a className="ui red circular label absolute-top-right" onClick={() => this.setState({ removableUser: participant, isOpenRemoveModal: true })}>
                        <i className="times icon" />
                      </a>
                    }
                  />
                ))}
              </div>
            </div>
            }
          </React.Fragment>
        )}
      </UserContext.Consumer>
    )
  }
  renderDangerousHTML = html => <div dangerouslySetInnerHTML={{ __html: html }} />

  renderRemoveUserModal = () => (
    <Mutation
      mutation={REMOVE_FROM_EVENT}
      onError={e => console.error('error', e)}
      onCompleted={() => this.setState({ removableUser: {}, isOpenRemoveModal: false })}
    >
      {removeFromEvent => (
        <Modal
          active={this.state.isOpenRemoveModal}
          onClose={() => this.setState({ removableUser: {}, isOpenRemoveModal: false })}
          onSuccess={() => this.handleRemoveFromEvent(removeFromEvent)}
          header="Удаление пользователя из события"
          body={`Ты действительно хочешь удалить "${this.state.removableUser.firstName} ${this.state.removableUser.lastName}" из события?`}
          size="small"
        />
      )}
    </Mutation>
  );

  renderSubInfoBlock = () => {
    const { event, onEdit } = this.props;

    return (
      <UserContext.Consumer>
        {({user, isRole}) => (
          <div className="ui segments">
            <div className="ui segment clearing">
              <h3 className="margin-15">
                Описание
                {!event.subData && isRole(['admin', 'manager', 'hrmanager', 'eventManager']) &&
                <span onClick={onEdit} className="right custom-floated">
                Редактировать
              </span>
                }
              </h3>
            </div>
            <div className="ui segment">
              <h2 className="ui header">{event.name || 'Будь креативным'}</h2>
              <div ref={this.description}>
                {event.description ?
                  this.renderDangerousHTML(event.description)
                  :
                  (event.subData && event.subData.editor) ?
                    this.renderDangerousHTML(this.props.event.subData.editor)
                    :
                    <React.Fragment>
                      <div className="ui two column grid">
                        {(this.checkKey('imageLink') || this.checkKey('videoLink')) &&
                        <div className="ui column">
                          {this.checkKey('imageLink') &&
                          <img alt="eventShow"
                               src={(event.subData || {}).imageLink || 'http://media.giphy.com/media/xT9KVvkK7Es3pWjpZK/giphy.gif'}
                               width="100%"/>
                          }
                          {this.checkKey('videoLink') &&
                          <iframe
                            title="video"
                            width="100%"
                            height="auto"
                            frameBorder="0"
                            allow="autoplay; encrypted-media"
                            allowFullScreen
                            src={(event.subData || {}).videoLink || 'http://www.youtube.com/embed/si5RyLmJupg?autoplay=1&controls=0&autohide=1&showinfo=0&mute=1&rel=0'}
                          />
                          }
                        </div>
                        }
                        <div className="ui column flex-block">
                          {this.checkKey('where') &&
                          <div className="field flex-block">
                            <div className="flex sub-info-title">Где?</div>
                            <div className="flex sub-info-text">
                              {(event.subData || {}).where || 'А действительно где?'}
                            </div>
                          </div>
                          }
                          {this.checkKey('why') &&
                          <div className="field flex-block">
                            <div className="flex sub-info-title">Зачем?</div>
                            <div className="flex sub-info-text">
                              {(event.subData || {}).why || 'Веские причины'}
                            </div>
                          </div>
                          }
                          {this.checkKey('description') &&
                          <div className="field flex-block description">
                            <div className="flex white-space-pre-line">
                              {(event.subData || {}).description || description}
                            </div>
                          </div>
                          }
                        </div>
                      </div>
                      <div className="ui column grid">
                        {this.checkKey('guests') &&
                        <div className="field">
                          <h4 className="">С нами будут:</h4>
                          <div className="flex-block-wrap">
                            {(event.subData || {}).guests.map((guest, index) => (
                              <HomeUserItem
                                noLink
                                key={`quest-${index}`}
                                user={{
                                  avatar: guest.link || '/assets/images/avatars/defaultAvatar.png',
                                  firstName: (guest.name || '').split(' ')[0] || '',
                                  lastName: (guest.name || '').split(' ')[1] || '',
                                  id: 999,
                                }}
                                style={{width: '75px', margin: '10px'}}
                                imgStyle={{width: '75px', height: '75px'}}
                                device
                              />
                            ))}
                          </div>
                        </div>
                        }
                      </div>
                    </React.Fragment>
                }
              </div>
            </div>

          </div>
        )}
      </UserContext.Consumer>
    );
  }
  handleAddToEvent = (addToEvent) => {
    addToEvent({
      variables: {
        id: this.props.event.id,
      },
    });
  }
  handleRemoveFromEvent = (removeFromToEvent) => {
    removeFromToEvent({
      variables: {
        id: this.props.event.id,
        userId: this.state.removableUser.id,
      },
    });
  }

  render() {
    const { renderInfoBlock, event } = this.props;
    return (
      <div className="EventView">
        <div className="ui column">
          {!event.subData &&
            <h1 className="ui header-title">{titleCase(event.eventType.rusName)}</h1>
          }
          {renderInfoBlock()}
          <div className="ui column grid">
            <div className="ui column five wide">
              {this.renderMainInfoBlock()}
            </div>
            <div className="ui column eleven wide">
              {this.renderSubInfoBlock()}
              {event.id && <Chat eventId={event.id} />}
            </div>
          </div>
          {this.renderRemoveUserModal()}
        </div>
      </div>
    );
  }
}

export default EventView;
