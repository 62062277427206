import gql from 'graphql-tag';
import { FRAGMENT_BEST_USER_INFO } from '../';

export const BEST_USER = gql`
    query bestUser {
      bestUser {
            ...BestUserInfo
        }
    }
    ${FRAGMENT_BEST_USER_INFO}
`;