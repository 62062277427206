import React, { Component } from 'react';
import { Query } from 'react-apollo';
import moment from 'moment';
import XLSX from 'xlsx';


import './AdminTableVacations.css';

import { ADMIN_SALARY_STATISTIC, ADMIN_BULK_SALARY_STATISTIC } from '../../../gql';
import { Loader, MonthsSelector, UsersSelector, YearsSelector, Checkbox } from '../../../components';
import { caseOfMonth } from '../../../helpers';

const months = moment.localeData('ru').months();
const currentMonth = moment().month();
const currentYear = moment().year();


class AdminTableVacations extends Component {
  state = {
    selectedMonth: currentMonth,
    selectedYear: currentYear,
    selectedUser: null,
    selectedIncludeHolidays: false,
  }

  onChangeSelectedYear = (year) => {
    if (year === this.state.selectedYear) return;
    this.setState({ selectedYear: year });
  }

  onChangeSelectedMonth = (month) => {
    if (month === this.state.selectedMonth) return;
    this.setState({ selectedMonth: month });
  }
  
  onChangeIncludeHolidays = () => {
    this.setState({ selectedIncludeHolidays: !this.state.selectedIncludeHolidays });
  }

  changeUser = selectedUser => {
    this.setState({ 
      selectedUser, 
      selectedIncludeHolidays: false,
    });
  };

  getDates = (startDate, endDate) => {
    const steps = moment(endDate).diff(moment(startDate), 'days') ;
    return Array.from({ length: steps + 1 }, (v, i) => startDate.clone().add(i, 'days'));
  }

  isWeekend = date => (moment(date).weekday() === 5 || moment(date).weekday() === 6);

  createArrayFromData = (data) => {
    const { selectedIncludeHolidays } = this.state;
    const renderData = [
      {
        title: `Был в отпуске (${selectedIncludeHolidays ? 'с выходными' : 'без выходных'})`,
        value: data.vacations.month,
      },{
        title: `Отсутствовал по болезни (${selectedIncludeHolidays ? 'с выходными' : 'без выходных'})`,
        value: data.sicks.month,
      },{
        title: `Отработал в ${caseOfMonth(months[this.state.selectedMonth])}`,
        value: data.daysOfMonth.monthWorkdays - data.sicks.month - data.vacations.month,
      },{
        title: `Рабочих дней в ${caseOfMonth(months[this.state.selectedMonth])}`,
        value: data.daysOfMonth.monthWorkdays,
      },{
        title: `Выходных и праздников в ${caseOfMonth(months[this.state.selectedMonth])}`,
        value: data.daysOfMonth.monthHolidays,
      },
    ]
    return renderData;
  }

  generateXLS = (arrayStatistic) => {
    const firstStatistic = arrayStatistic[0].statistic;
    if (!firstStatistic) {
      return alert('Нет строк данных!');
    }
    const monthes = ['янв', 'фев', 'март', 'апр', 'май', 'июнь', 'июль', 'авг', 'сен', 'окт', 'нояб', 'дек']
    const { selectedMonth, selectedYear, selectedIncludeHolidays } = this.state;
    const filename = `Отчет__${monthes[selectedMonth]}_${selectedYear}_${selectedIncludeHolidays ? 'c' : 'без'}-вых.xlsx`;
    const columns = ['Имя Фамилия'].concat(this.createArrayFromData(firstStatistic).map(v => v.title));
    const rows = arrayStatistic.map((salaryStatistic) => {
      const first = `${salaryStatistic.user.firstName} ${salaryStatistic.user.lastName}`;
      const rest = this.createArrayFromData(salaryStatistic.statistic).map(v => v.value);
      return [first, ...rest];
    });
    // console.log({rows, columns});
    const data = [columns, ...rows];
    // const data = [[1,2,3],[true, false, null, "sheetjs"],["foo","bar",new Date("2014-02-19T14:30Z"), "0.3"], ["baz", null, "qux"]]
    const ws_name = 'Отчет';
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(data);
    /* add worksheet to workbook */
    XLSX.utils.book_append_sheet(wb, ws, ws_name);
    XLSX.writeFile(wb, filename);
  }



  renderContentWithoutControls = (arrayStatistic) => {
    return arrayStatistic.length > 0 && (
      <div className="flex-container">
        <div className="fixed-block">
          <h4 className="ui header">
            <div className="content" />
          </h4>
          {arrayStatistic.map((salaryStatistic) => {
            return (
              <h4 className="ui header" key={salaryStatistic.user.id}>
                <div className="content">
                  {salaryStatistic.user.firstName}
                  <div className="sub header">
                    {salaryStatistic.user.lastName}
                  </div>
                </div>
              </h4>
            )
          })}
        </div>
        <div className="scrollable-block">

          {this.createArrayFromData(arrayStatistic[0].statistic || {}).map((item, i) => (
            <div className="fixed-block" key={item.title}>
              <h4 className={`ui header text-center`}>
                <div className="content">{item.title}</div>
              </h4>
              {arrayStatistic.map((salaryStatistic) => {
                const list = this.createArrayFromData(salaryStatistic.statistic || {});
                return (
                  <h4 className={`ui header text-center`} key={salaryStatistic.user.id}>
                    <div className="content">{list[i].value}</div>
                  </h4>
                )
              })}
            </div>
          ))}

        </div>
      </div>
    )
  }

  renderContent = (data) => {
    const { selectedMonth, selectedYear, selectedUser, selectedIncludeHolidays } = this.state;
    let arrayStatistic = [];
    if (data.salaryStatistic) arrayStatistic.push({ statistic: data.salaryStatistic || {}, user: selectedUser || {} });
    if (data.bulkSalaryStatistic) arrayStatistic = data.bulkSalaryStatistic;

    const Content = () => this.renderContentWithoutControls(arrayStatistic);

    return (
      <div>
        <div className="flex-block">
          <div>
            <label><b>За год</b></label>
            <YearsSelector year={selectedYear} changeYear={this.onChangeSelectedYear} className="small-dropdown" />
          </div>
          <div className="with-left-margin">
            <label><b>За месяц</b></label>
            <MonthsSelector month={selectedMonth} changeMonth={this.onChangeSelectedMonth} className="small-dropdown" />
          </div>
        </div>
        <UsersSelector user={selectedUser || {}} changeUser={this.changeUser} className="user-selector"/>
        
        
        {!selectedUser && <>
          <div>
            <label className="flex-block" style={{fontWeight: 700}}>Включать выходные в расчеты:&nbsp;&nbsp;
              <Checkbox checked={selectedIncludeHolidays} onChange={this.onChangeIncludeHolidays}/>
            </label>
          </div>
          <button onClick={() => this.generateXLS(arrayStatistic)}>Generate XLS</button>
          {/* <button onClick={() => this.generatePDF(Content)}>Generate PDF</button> */}
        </>}

        
        <Content/>
          
      </div>
    )
  }
  render() {
    const { selectedMonth, selectedYear, selectedUser, selectedIncludeHolidays } = this.state;
    return (
      <div className="AdminTableVacations">
        { selectedUser?.id ? (
          <Query
            query={ADMIN_SALARY_STATISTIC}
            variables={{ id: selectedUser?.id, year: selectedYear, month: selectedMonth }}
            fetchPolicy="no-cache"
          >
            {({ loading, error, data }) => {
              if (loading) return <Loader />;
              if (error) throw new Error(error);

              return this.renderContent(data)
            }}
          </Query>
        ) : (
          <Query
            query={ADMIN_BULK_SALARY_STATISTIC}
            variables={{ id: selectedUser?.id, year: selectedYear, month: selectedMonth, isIncludeHolidays: selectedIncludeHolidays }}
            fetchPolicy="no-cache"
          >
            {({ loading, error, data, updateQuery }) => {
              if (loading) return <Loader />;
              if (error) throw new Error(error);

              // this need for correct work request
              try {
                updateQuery(previousResult => ({
                  ...previousResult,
                }))
              } catch (e) {}

              return this.renderContent(data)
            }}
          </Query>
        )}
      </div>
    );
  }
}

export default AdminTableVacations;
