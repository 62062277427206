const USER_STATUSES = [
  {
    name: 'active',
    rusName: 'активный',
  },
  {
    name: 'inactive',
    rusName: 'неактивный',
  },
  {
    name: 'blocked',
    rusName: 'заблокированный',
  },
];
export default USER_STATUSES;
