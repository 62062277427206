import gql from 'graphql-tag';

export const FRAGMENT_REQUEST_INFO = gql`
    fragment RequestInfo on Request {
        id
        name
        description
        days
        file
        ownerId
        createdAt
        closedAt
        comment
        status
        owner {
            id
            firstName
            lastName
        }
    }
`;
