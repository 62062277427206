import React from 'react';

import './NotFound.css';

function NotFound() {
  return (
    <div className="NotFound">
      <div className="ui image">
        <img alt="Page not found" src="/assets/images/404.svg" />
        <div className="text">
          <p>Кажется такой страницы не существует.</p>
          <div className="ui large green button" onClick={() => {
            window.location.replace(window.location.origin)
          }}>Вернуться на главную</div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
