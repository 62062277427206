import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation, Query } from 'react-apollo';

import { Modal } from '../../../components';
import { EDIT_ROLE } from '../../../gql';
import './AdminEditRole.css';

class AdminEditRole extends Component {

  constructor(props) {
    super(props);
    this.state = {
      rusName: props.role.rusName,
    };
  }

  onSubmit = (editRole) => {
    editRole({
      variables: {
        id: this.props.role.id,
        rusName: this.state.rusName,
      },
    });
  }


  renderRoleInput = () => (
    <div className="field ">
      <div className="ui left icon input ">
        <i className="user icon" />
        <input
          type="text"
          name="rusName"
          value={this.state.rusName}
          onChange={({ target: { value } }) => this.setState({ rusName: value })}
        />
      </div>
    </div>
  )

  render() {
    return (
      <Mutation mutation={EDIT_ROLE} onCompleted={this.props.onClose} >
        {(editRole, { loading, data , error }) => {
          return (
            <Modal
              active={this.props.active}
              onClose={this.props.onClose}
              onSuccess={() => this.onSubmit(editRole)}
              header="Редактирование русского имени роли"
              body={this.renderRoleInput()}
              size="small"
            />
          );
        }}
      </Mutation>
    );
  }
}
AdminEditRole.defaultProps = {
  role: {},
  active: false,
  onClose: () => {},
};
AdminEditRole.propTypes = {
  role: PropTypes.shape(),
  active: PropTypes.bool,
  onClose: PropTypes.func,
  client: PropTypes.shape().isRequired,
};


export default AdminEditRole;
