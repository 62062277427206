import gql from 'graphql-tag';
import { FRAGMENT_DISH_FULL_INFO } from '../';

export const EDIT_DISH = gql`
    mutation editDish($dish: dishCredentials!) {
        editDish(dish: $dish) {
            ...DishFullInfo
        }
    }
    ${FRAGMENT_DISH_FULL_INFO}
`;
