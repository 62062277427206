import gql from 'graphql-tag';
import {
  FRAGMENT_EVENT_COMMENT_WITH_USER,
  FRAGMENT_EVENT_INFO,
  FRAGMENT_VACATION_INFO,
  FRAGMENT_REQUEST_INFO,
  FRAGMENT_SETTINGS_INFO,
  FRAGMENT_DISH_FULL_INFO,
} from '../index';

export const COMMON_SUBSCRIPTION = gql`
    subscription commonSubscription {
        commonSubscription {
            eventComment {
              ...EventCommentWithUser
            }
            event {
              ...EventInfo
            }
            vacation {
              ...VacationInfo
            }
            request {
              ...RequestInfo
            }
            orderTime {
              ...SettingsInfo
            }
            logout {
              id
            }
            actionBy {
              id
            }
            menu {
              dish {
                ...DishFullInfo
              }
            }
        }
    }
    ${FRAGMENT_EVENT_COMMENT_WITH_USER}
    ${FRAGMENT_EVENT_INFO}
    ${FRAGMENT_VACATION_INFO}
    ${FRAGMENT_REQUEST_INFO}
    ${FRAGMENT_SETTINGS_INFO}
    ${FRAGMENT_DISH_FULL_INFO}
`;
