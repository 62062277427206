import gql from 'graphql-tag';
import { FRAGMENT_ORDER_INFO_WITH_USER_AND_DISHES } from '../';

export const USER_ORDERS = gql`
    query userOrders($month: Int, $year: String) {
        userOrders(month: $month, year: $year) {
            ...OrderInfoWithUserAndDishes
        }
    }
    ${FRAGMENT_ORDER_INFO_WITH_USER_AND_DISHES}
`;

export const ORDERS = gql`
    query orders($month: Int, $year: String) {
        orders(month: $month, year: $year) {
            id
            firstName
            lastName
            role {
                id
                name
                rusName
            }
            orders(month: $month, year: $year) {
                id
                date
                totalCost
                additionalCost
                payByCompany
            }
        }
    }
`;

export const ORDERS_BEETWEEN = gql`
    query ordersBeetween($from: String, $to: String) {
        ordersBeetween(from: $from, to: $to) {
            id
            firstName
            lastName
            role {
                id
                name
                rusName
            }
            ordersBeetween(from: $from, to: $to) {
                id
                date
                totalCost
                additionalCost
                payByCompany
            }
        }
    }
`;

export const ORDERS_BY_DAYS = gql`
    query ordersByDays($month: Int!, $year: String!) {
        ordersByDays(month: $month, year: $year) {
            date
            totalCost
            payByCompany
        }
    }
`;

export const TODAY_ORDERS = gql`
    query todayOrders($day: Int) {
        todayOrders(day: $day) {
            ...OrderInfoWithUserAndDishes
        }
    }
    ${FRAGMENT_ORDER_INFO_WITH_USER_AND_DISHES}
`;
