import gql from 'graphql-tag';
import { FRAGMENT_SICK_INFO } from '../';

export const SICK = gql`
    query sick($filter: Filter) {
        sick(filter: $filter) @connection(key: "sick", filter: ["filter"]) {
            ...SickInfo
        }
    }
    ${FRAGMENT_SICK_INFO}
`;

export const SICK_BY_ID = gql`
    query sickById($id: ID!) {
        sickById(id: $id) {
            ...SickInfo
        }
    }
    ${FRAGMENT_SICK_INFO}
`;

export const PAGINATION_SICK = gql`
    query paginationSick($filter: Filter, $pageInfo: PageInfo) {
        paginationSick(filter: $filter, pageInfo: $pageInfo) @connection(key: "paginationSick", filter: ["filter", "pageInfo"]) {
            results {
                ...SickInfo
            }
            cursors {
                hasNext
                hasPrevious
                before
                after
            }
        }
    }
    ${FRAGMENT_SICK_INFO}
`;
