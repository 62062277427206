import gql from 'graphql-tag';

export const ADD_USER_STAR = gql`
    mutation addStarUser($userId: ID!, $blockSlug: String!) {
        addStarUser(userId: $userId,blockSlug: $blockSlug)
    }
`;

export const REMOVE_USER_STAR = gql`
    mutation removeStarUser($id: ID!) {
        removeStarUser(id: $id) 
    } 
`;