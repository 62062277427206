import gql from 'graphql-tag';
import { FRAGMENT_DISH_FULL_INFO } from '../';

export const CLEAN_MENU = gql`
    mutation cleanMenu {
        cleanMenu {
            ...DishFullInfo
        }
    }
    ${FRAGMENT_DISH_FULL_INFO}
`;
