import gql from 'graphql-tag';

export const FRAGMENT_DISH_MAIN_INFO = gql`
    fragment DishMainInfo on Dish {
        id
        name
        cost
        description
    }
`;
export const FRAGMENT_DISH_FULL_INFO = gql`
    fragment DishFullInfo on Dish {
        ...DishMainInfo
        available
        category
        menuCategoryId
        rating
    }
    ${FRAGMENT_DISH_MAIN_INFO}
`;
