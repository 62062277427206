import React from 'react';
import { Mutation } from 'react-apollo';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';

const LOGOUT = gql`
    mutation logout {
        logout
    }
`;

export default () => (
  <Mutation mutation={LOGOUT} >
    {(logout) => {
      return (
        <Link
          onClick={logout}
          to=""
          className="item logout"
        >
          <img className="ui menu-item" src="/assets/icons/logout.svg" alt="logout" />
          <div className="menu-title">Выход</div>
        </Link>
      );
    }}
  </Mutation>
);
