import React from 'react';
import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype';
import { isTouchDevice } from '../helpers';

const eventName = isTouchDevice() ? 'touchend' : 'mousedown';

/**
 * If you need close modal by click on some element - add to it atrr:
 * data-modal="shouldClose"
 */
class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener(eventName, this.handleClick, false);
    document.addEventListener('keydown', this.escFunction, false);
  }
  componentWillUnmount() {
    document.removeEventListener(eventName, this.handleClick, false);
    document.removeEventListener('keydown', this.escFunction, false);
  }

  escFunction = (event) => {
    if (this.props.active && event.keyCode === 27) {
      this.onClose();
    }
  }

  handleClick = (e) => {
    const { target } = e;
    if (this.props.active) {
      const dataModal = target && (
        (target.getAttribute && target.getAttribute('data-modal')) ||
        (target.parentNode && target.parentNode.getAttribute && target.parentNode.getAttribute('data-modal')) ||
        (target.parentNode.parentNode && target.parentNode.parentNode.getAttribute && target.parentNode.parentNode.getAttribute('data-modal'))
      )
      if (!dataModal && this.modalRef.current.contains(target)) return;
      if (dataModal !== 'shouldBubble') {
        e.preventDefault();
        e.stopPropagation();
      }
      this.onClose(target);
    }
  }

  onClose = (target) => {
    this.props.onClose(target);
  }
  onSuccess = () => {
    this.props.onSuccess();
  }
  defaultFooter = () => (
    <div className="actions">
      <div className="ui black deny button" onClick={this.onClose} >
        Закрыть
      </div>
      <div className={`ui positive right labeled icon button ${this.props.disableButton && 'disabled'}`} onClick={this.onSuccess}>
        Применить
        <i className="checkmark icon" />
      </div>
    </div>
  )

  render() {
    const {
      active,
      size,
      hideCloseButton,
      header,
      body,
      footer,
      modalStyles,
      contentStyles,
      bodyClass,
    } = this.props;
    if (!active) return null;

    return (
      <div className={`ui dimmer modals page transition ${active && 'active'}`}>
        <div ref={this.modalRef} className={`ui modal transition active ${size}`} style={modalStyles}>
          {!hideCloseButton && <i className="close icon" onClick={this.onClose} />}
          {header && <div className="header">{header}</div>}
          <div className={bodyClass} style={contentStyles}>{body}</div>
          {footer || this.defaultFooter()}
        </div>
      </div>
    );
  }
}
Modal.defaultProps = {
  active: false,
  disableButton: false,
  hideCloseButton: false,
  onClose: () => {},
  onSuccess: () => {},
  body: null,
  header: '',
  size: 'standard',
  footer: '',
  modalStyles: {},
  contentStyles: {},
  bodyClass: 'image content',
}
Modal.propTypes = {
  active: PropTypes.bool,
  disableButton: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  header: PropTypes.string,
  size: PropTypes.string,
  body: PropTypes.node,
  footer: PropTypes.node,
  modalStyles: stylePropType,
  contentStyles: stylePropType,
  bodyClass: PropTypes.string,
}

export default Modal;
