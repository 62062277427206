import gql from 'graphql-tag';

export const MENU_CATEGORIES = gql`
    query menuCategories {
        menuCategories {
            name
            rusName
            id
        }
    }
`;
