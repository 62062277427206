import gql from 'graphql-tag';
import { FRAGMENT_USER_MAIN_INFO } from '../';

export const LOGIN = gql`
    query login($login: String!, $password: String!, $reCaptcha: String!) {
        login(login: $login, password: $password, reCaptcha: $reCaptcha) {
            ...UserMainInfo
            token
        }
    }
    ${FRAGMENT_USER_MAIN_INFO}
`;
