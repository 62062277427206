import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation, Query } from 'react-apollo';
import { toLower } from 'lodash';
import moment from 'moment';

import { countBoxes, getErrorMessages, showErrors } from '../../../helpers';
import { Modal, VirtualizedSortableTable, UsersSelector, Loader, Checkbox } from '../../../components';
import { EDIT_ORDER, SAVE_ORDER, TODAY_ORDERS, MENU_GROUP_BY_CATEGORY } from '../../../gql';
import './AdminOrderForm.css';

const WITH_YOU_BOX_COST = 0;

class AdminOrderForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workDays: moment.weekdays().slice(1, 6).map(toLower),
      formError: new Map(),
      totalCost: props.order.totalCost || 0,
      dishes: props.order.dishes || [],
      owner: props.order.owner || {},
      withYou: props.order.withYou || false,
      additionalCost: props.order.additionalCost || 0,
      payByCompany: props.order.payByCompany || 0,
    };
  }

  onSubmit = (orderFunc) => {
    if (this.state.formError.size) return;
    const additionalCost = this.state.withYou ? countBoxes(this.state.dishes.map(({ dish }) => dish) || []).length * WITH_YOU_BOX_COST : 0
    const order = {
      dishes: this.state.dishes.map(({ dish }) => dish.id),
      countedDishes: this.state.dishes.map(({ dish, count }) => ({ id: dish.id, count })),
      totalCost: +this.state.totalCost,
      payByCompany: +this.state.payByCompany,
      additionalCost: additionalCost,
      withYou: this.state.withYou,
    };
    if (this.props.order.owner) {
      order.id = this.props.order.id;
    } else {
      order.ownerId = this.state.owner.id;
    }
    orderFunc({
      variables: {
        order,
      },
      refetchQueries: [{ query: TODAY_ORDERS, variables: { day: moment().date() } }],
    });
  }

  onError = (error) => {
    const errorMessage = getErrorMessages(error);
    const { formError } = this.state;
    formError.set('response', errorMessage[0]);
    this.setState({ formError });
  }

  setOrder = ({ cellData, rowData }) => {
    const defaultCount = 1
    const dishes = this.state.dishes.filter(({ dish }) => dish.id !== rowData.id);
    if (dishes.length === this.state.dishes.length) {
      dishes.push({ dish: rowData, count: defaultCount });
    }
    if(!dishes.length) return;
    const totalCost = dishes.map(({ dish, count }) => dish.cost * count).reduce((prev, curr) => (prev + curr));
    const additionalCost = this.state.withYou ? countBoxes(dishes.map(({ dish }) => dish) || []).length * WITH_YOU_BOX_COST : 0;
    // Concat do a deep copy of the object for triggering rerend
    this.setState({ dishes, totalCost, additionalCost, formError: new Map() });
  }

  renderCheckBox = e => (
    <div
      className="ui checkbox"
      onClick={() => this.setOrder(e)}
    >
      <input type="checkbox" name="order" checked={this.state.dishes && this.state.dishes.find(({ dish }) => dish.id === e.cellData)} />
    </div>
  )

  tableHeaderForUser = () => ([
    {
      label: 'Заказ',
      dataKey: 'id',
      width: 100,
      disableSort: true,
      cellRenderer: this.renderCheckBox,
    },
    {
      label: 'Наименование',
      dataKey: 'name',
      width: 300,
      disableSort: true,
    },
    {
      label: 'Категория',
      dataKey: 'categoryRusName',
      width: 300,
      disableSort: true,
    },
    {
      label: 'Цена',
      dataKey: 'cost',
      width: 100,
      disableSort: true,
    },
  ]);
  changeUser = owner => this.setState({ owner });

  renderMenuForm = () => (
    <Query
      query={MENU_GROUP_BY_CATEGORY}
      variables={{ filter: { available: true } }}
    >
      {({ client, loading, error, data }) => {
        if (loading) return <Loader />;
        if (error) throw new Error(error);

        const list = data.menuGroupByCategory?.reduce((menu, category) => {
          const categoryMenu = category.menu.map(item => ({
            ...item,
            categoryRusName: category.rusName,
          }));
          return [...menu, ...categoryMenu];
        }, []) || [];

        return (
          <div className="AdminOrderForm">
            {!this.props.order.owner &&
              <div className="select-owner">
                Для кого:
                <div className="ui icon input user-selector-wrapper">
                  <UsersSelector
                    user={this.state.owner} changeUser={this.changeUser}
                    filter={{ notIn: this.props.ownersList }}
                  />
                </div>
              </div>
            }
            <form className={`ui large form  ${this.state.formError.size && 'error'}`} >
              <div className="html ui top attached card grid">
                <div className="ui card card-with-table" >
                  <VirtualizedSortableTable
                    key={18932492398}
                    list={list}
                    tableHeader={this.tableHeaderForUser(list)}
                  />
                </div>
              </div>
              <div className="ui error message">
                <ul className="list">
                  {showErrors(this.state.formError)}
                </ul>
              </div>
            </form>
            <div
              onClick={() => this.setState({ withYou: !this.state.withYou })}
              className='description flex-block'
            >
              <Checkbox checked={this.state.withYou} />
              <label>Завернуть с собой</label>
            </div>
          </div>

        );
      }}
    </Query>
  )

  mutationCompleted = () => {
    this.setState(
      {
        formError: new Map(),
        totalCost: 0,
        dishes: [],
        owner: {},
        withYou: false,
        additionalCost: 0,
        payByCompany: 0,
      },
      () => this.props.onClose());
  }
  render() {
    const modal = orderFunc => (<Modal
      active={this.props.active}
      onClose={this.props.onClose}
      onSuccess={() => this.onSubmit(orderFunc)}
      header={`${this.props.order.owner ? `Редактирование заказа: ${this.props.order.owner.firstName} ${this.props.order.owner.lastName}` : 'Добавление заказа'}`}
      body={this.renderMenuForm()}
      size="small"
    />)
    if (this.props.order.owner) return (
      <Mutation mutation={EDIT_ORDER} onError={this.onError} onCompleted={this.mutationCompleted} >
        {(editOrder) => modal(editOrder)}
      </Mutation>
    );

    return (
      <Mutation mutation={SAVE_ORDER} onError={this.onError} onCompleted={this.mutationCompleted} >
        {(saveOrder) => modal(saveOrder)}
      </Mutation>
    );
  }
}
AdminOrderForm.defaultProps = {
  ownersList: [],
  order: {},
  active: false,
  onClose: () => {},
};
AdminOrderForm.propTypes = {
  ownersList: PropTypes.arrayOf(PropTypes.string),
  order: PropTypes.shape(),
  active: PropTypes.bool,
  onClose: PropTypes.func,
};


export default AdminOrderForm;
