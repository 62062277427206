import gql from 'graphql-tag';
import { FRAGMENT_CALENDAR_INFO } from '..';

export const CALENDAR_HOLIDAYS = gql`
    query holidays($year: String) {
        holidays(year: $year) {
            ...CalendarInfo
        }
    }
    ${FRAGMENT_CALENDAR_INFO}
`;

export const WORK_DAYS = gql`
    query workDays {
        workDays
    }
`;
