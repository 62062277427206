import React, { Component } from 'react';
import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import { Mutation } from 'react-apollo';
import { EDIT_SICK } from '../../../gql';
import { Modal } from '../../';
import './EditSickModal.css';

class EditSickModal extends Component {
  static propTypes = {
    isOpenSickModal: PropTypes.bool,
    hideSickModal: PropTypes.func,
    selectedSick: PropTypes.shape().isRequired,
    query: PropTypes.shape().isRequired,
    variables: PropTypes.shape().isRequired,
    // buttonStyles: stylePropType,
  }
  static defaultProps = {
    isOpenSickModal: false,
    hideSickModal: () => {},
    // buttonStyles: {},
  }

  static getDerivedStateFromProps(nextProps) {
    const { selectedSick = {} } = nextProps;
    return { selectedSick };
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedSick: props.selectedSick,
    };
  }

  modalFooter = () => (
    <Mutation mutation={EDIT_SICK} onCompleted={this.props.hideSickModal} >
      {(editSick) => (
        <div className="actions">
          <div
            className={`ui positive right labeled icon button `}
            onClick={() => {
              editSick({
                variables: {
                  id: this.state.selectedSick.id,
                  endAt: this.state.selectedSick.endAt,
                  startAt: this.state.selectedSick.startAt,
                  newComment: this.state.selectedSick.newComment,
                },
                refetchQueries: [
                  { query: this.props.query, variables: this.props.variables },
                ],
              });
            }}
          >
            Изменить
            <i className="checkmark icon" />
          </div>
        </div>
      )}
    </Mutation>
  )

  modalBody = () => {
    const { selectedSick } = this.state;
    return (
      <div className="full-width">
        <h3 className="ui image header">
          <div className="content white-space-pre-line">
            {selectedSick.owner && selectedSick.owner.firstName} {selectedSick.owner && selectedSick.owner.lastName}
            <div className="sub header">{selectedSick.comment}</div>
            <div className="sub header">{selectedSick.editComment}</div>
          </div>
        </h3>
        <div>
          <div className="edit-sick-modal_date-pickers">
            <div className="start-time">
          <label htmlFor="startAt">Начало</label>
          <div className={`ui left input `}>
            <DatePicker
              selected={moment(selectedSick.startAt)}
              selectsStart
              startDate={moment(selectedSick.startAt)}
              endDate={moment(selectedSick.endAt)}
              onChange={value => this.setState({ selectedSick: { ...this.state.selectedSick, startAt: value } })}
            />
          </div>
            </div>
            <div className="end-time">
          <label htmlFor="endAt">Конец</label>
          <div className={`ui left input `}>
            <DatePicker
              selected={moment(selectedSick.endAt)}
              selectsEnd
              startDate={moment(selectedSick.startAt)}
              endDate={moment(selectedSick.endAt)}
              onChange={value => this.setState({ selectedSick: { ...this.state.selectedSick, endAt: value } })}
            />
          </div>
            </div>
          </div>
        </div>
        <h4 className={`field white-space-pre-line`}>
          <label htmlFor="comment">Комментарий для редактирования (не обязателен)</label>
          <div className="ui left icon">
          <textarea
            rows={3}
            type="text"
            name="comment"
            value={this.state.selectedSick.newComment}
            onChange={({target: { value }}) => this.setState({ selectedSick: { ...this.state.selectedSick, newComment: value } })}
          />
          </div>
        </h4>
      </div>
    )
  }

  render() {
    const {
      isOpenSickModal,
      hideSickModal,
    } = this.props;

    if (!isOpenSickModal) return null;
    return (
      <div className="EditSickModal">
        <Modal
          active={isOpenSickModal}
          onClose={hideSickModal}
          header="Редактировать больничный"
          body={this.modalBody()}
          footer={this.modalFooter()}
        />
      </div>
    );
  }
}


export default EditSickModal;
