import React from 'react';
import BrokenPage from '../../pages/BrokenPage/BrokenPage';
import NoAccess from '../../pages/NoAccess/NoAccess';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      noAccess: false,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, noAccess: error.message.includes('Ах ты хитрый хакер') };
  }

  componentDidCatch(error, info) {
    console.error('ErrorBoundary: ',error, info);
  }

  render() {
    if (this.state.hasError) {
      if (this.state.noAccess) {
        return <NoAccess />;
      }
      return <BrokenPage />;
    }
    return this.props.children;
  }
}
