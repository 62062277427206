import React from 'react';
import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype';
import './Checkbox.css';

class Checkbox extends React.Component {
  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.isAllChecked !== null && nextProps.isAllChecked !== state.isAllChecked) {
      return {
        isAllChecked: nextProps.isAllChecked,
        checked: nextProps.isAllChecked,
        disabled: nextProps.disabled,
      };
    }
    if (nextProps.isMediaSelected !== null && nextProps.isMediaSelected !== state.checked) {
      return {
        checked: nextProps.isMediaSelected,
        disabled: nextProps.disabled,
      };
    }
    if (nextProps.checked !== null) {
      return {
        checked: nextProps.checked,
        disabled: nextProps.disabled,
      };
    }
    return null;
  }
  state = {
    checked: false,
    isAllChecked: false,
    disabled: false,
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.disabled !== this.state.disabled) return true;
    if (nextState.checked === this.state.checked) {
      return false;
    }
    return true;
  }
  handleChange = () => {
    this.setState({ checked: !this.state.checked });
    this.props.onChange();
  }

  render() {
    return (
      <div className="Checkbox" style={this.props.style}>
        <input
          type="checkbox"
          disabled={this.state.disabled}
          onChange={this.handleChange}
          checked={this.state.checked}
        />
        <label />
      </div>
    );
  }
}

Checkbox.propTypes = {
  onChange: PropTypes.func,
  style: stylePropType,
  isAllChecked: PropTypes.bool,
  isMediaSelected: PropTypes.bool,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  onChange: () => {},
  style: {},
  isAllChecked: null,
  isMediaSelected: null,
  checked: null,
  disabled: false,
};

export default Checkbox;
