import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Mutation } from 'react-apollo';
import { withRouter } from 'react-router-dom';

import { REQUEST_STATUSES } from '../../../environment';
import { Modal, RequestsList } from '../../../components';
import { PAGINATION_REQUESTS, CLOSE_REQUEST, REQUEST_BY_ID } from '../../../gql';

import './AdminRequestsList.css';

class AdminRequestsList extends RequestsList {
  static propTypes = {
    history: PropTypes.shape().isRequired,
    location: PropTypes.shape().isRequired,
    variables: PropTypes.shape(),
  }
  static defaultProps = {
    variables: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      isOpenRequestModal: false,
      isOpenImageModal: false,
      selectedRequest: {},
      isRequestApproving: false,
      isRequestRejecting: false,
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (!nextProps.data) return null;
    const { loading } = nextProps.data;
    const selectedRequest = (nextProps.data.requestById || {});
    if (!loading && !state.selectedRequest.id) {
      return { isOpenRequestModal: true, selectedRequest };
    }
    return null;
  }

  showRequestModal = (request) => {
    this.props.history.push({
      ...this.props.location,
      search: `?id=${request.id}`,
    });
    this.setState({ isOpenRequestModal: true, selectedRequest: request });
  };
  hideRequestModal = () => {
    this.props.history.push({
      ...this.props.location,
      search: '',
    });
    this.setState({
      isOpenRequestModal: false, selectedRequest: { comment: '' }, isRequestApproving: false, isRequestRejecting: false,
    });
  };

  showImageModal = (request) => {
    this.setState({ isOpenImageModal: true, selectedRequest: request });
  };
  hideImagetModal = () => {
    this.setState({ isOpenImageModal: false, selectedRequest: { comment: '' } });
  };

  renderSelectedRequest = () => (
    <div className="full-width">
      <h3 className="ui image header">
        <div className="content">
          {this.state.selectedRequest.name}
          <div className="sub header">{this.state.selectedRequest.description}</div>
        </div>
      </h3>
      <h4 className="field">
        <label htmlFor="comment">Комментарий (обязателен для отклонения)</label>
        <div className="ui left icon">
          <textarea
            rows={3}
            type="text"
            name="comment"
            value={this.state.selectedRequest.comment}
            onChange={({ target: { value } }) => this.setState({ selectedRequest: { ...this.state.selectedRequest, comment: value } })}
          />
        </div>
      </h4>
    </div>
  )


  modalFooter = () => (
    <Mutation mutation={CLOSE_REQUEST} onCompleted={this.hideRequestModal} >
      {closeRequest => (
        <div className="actions">
          <div
            className={`ui black deny button ${this.state.isRequestRejecting && 'loading'} ${(this.state.isRequestApproving || this.state.isRequestRejecting || !this.state.selectedRequest.comment || this.state.selectedRequest.status !== REQUEST_STATUSES.waiting) && 'disabled'}`}
            onClick={() => {
              this.setState({ isRequestRejecting: true });
              closeRequest({
                variables: {
                  id: this.state.selectedRequest.id,
                  comment: this.state.selectedRequest.comment,
                  status: REQUEST_STATUSES.rejected,
                },
                update: (store, { data: { closeRequest: res } }) => {
                  // we updated request cache by subscription
                  // this.queriesCacheUpdate(res, store);
                },
              });
            }}
          >
            Отклонить
          </div>
          <div
            className={`ui positive right labeled icon button ${this.state.isRequestApproving && 'loading'} ${(this.state.isRequestApproving || this.state.isRequestRejecting || this.state.selectedRequest.status !== REQUEST_STATUSES.waiting) && 'disabled'}`}
            onClick={() => {
              this.setState({ isRequestApproving: true });
              closeRequest({
                variables: {
                  id: this.state.selectedRequest.id,
                  comment: this.state.selectedRequest.comment,
                  status: REQUEST_STATUSES.done,
                },
                update: (store, { data: { closeRequest: res } }) => {
                  // we updated request cache by subscription
                  // this.queriesCacheUpdate(res, store);
                },
              });
            }}
          >
            Выполнить
            <i className="checkmark icon" />
          </div>
        </div>
      )}
    </Mutation>
  )

  renderSelectedImage = () => (<img className="all-content" src={this.state.selectedRequest.file} alt="request-file" />);
  renderCloseRequestModal = () => (
    <Modal
      active={this.state.isOpenRequestModal}
      onClose={this.hideRequestModal}
      header="Закрыть заявку"
      body={this.renderSelectedRequest()}
      footer={this.modalFooter()}
    />
  )
  renderShowImageModal = () => (
    <Modal
      active={this.state.isOpenImageModal}
      onClose={this.hideImagetModal}
      body={this.renderSelectedImage()}
      footer={<div />}
      size="small"
    />
  )

  render() {
    return (
      <div className="AdminRequestsList">
        {this.renderCloseRequestModal()}
        {this.renderShowImageModal()}
        {super.render()}
      </div>
    );
  }
}

export default withRouter(graphql(REQUEST_BY_ID, {
  skip: ({ history }) => !history.location.search,
  options: ({ history }) =>
    ({
      variables: { id: history.location.search.split('id=')[1] },
    }),
})(AdminRequestsList));
