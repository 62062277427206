import gql from 'graphql-tag';
import { FRAGMENT_EVENT_INFO } from '../';

export const EVENTS = gql`
    query events($filter: Filter) {
        events(filter: $filter) @connection(key: "events", filter: ["filter"]) {
            ...EventInfo
        }
    }
    ${FRAGMENT_EVENT_INFO}
`;

export const EVENT = gql`
    query event($id: ID!) {
        event(id: $id) {
            ...EventInfo
        }
    }
    ${FRAGMENT_EVENT_INFO}
`;

export const PAGINATION_EVENTS = gql`
    query paginationEvents($filter: Filter, $pageInfo: PageInfo) {
        paginationEvents(filter: $filter, pageInfo: $pageInfo) @connection(key: "paginationEvents", filter: ["filter", "pageInfo"]) {
            results {
                ...EventInfo
            }
            cursors {
                hasNext
                hasPrevious
                before
                after
            }
        }
    }
    ${FRAGMENT_EVENT_INFO}
`;
