import gql from 'graphql-tag';
import { FRAGMENT_ORDER_INFO_WITH_USER_AND_DISHES } from '../';

export const SAVE_ORDER = gql`
    mutation saveOrder($order: orderCredentials!) {
        saveOrder(order: $order) {
            ...OrderInfoWithUserAndDishes
        }
    }
    ${FRAGMENT_ORDER_INFO_WITH_USER_AND_DISHES}
`;
export const REMOVE_ORDER = gql`
    mutation removeOrder($order: ID!) {
        removeOrder(order: $order) 
    }
`;
export const EDIT_ORDER = gql`
    mutation editOrder($order: orderCredentials!) {
        editOrder(order: $order) {
            ...OrderInfoWithUserAndDishes
        }
    }
    ${FRAGMENT_ORDER_INFO_WITH_USER_AND_DISHES}
`;
