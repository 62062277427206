import gql from 'graphql-tag';
import { FRAGMENT_SICK_INFO, FRAGMENT_VACATION_INFO } from '../';

export const RESERVED_VACATIONS_AND_SICK = gql`
    query sick($filter: Filter) {
        sick(filter: $filter) @connection(key: "sick", filter: ["filter"]) {
            ...SickInfo
        }
        vacations(filter: $filter) @connection(key: "vacations", filter: ["filter"]) {
            ...VacationInfo
        }
    }
    ${FRAGMENT_VACATION_INFO}
    ${FRAGMENT_SICK_INFO}
`;
