import gql from 'graphql-tag';

export const EVENT_TYPES = gql`
    query eventTypes {
        eventTypes {
            name
            rusName
            id
        }
    }
`;
