import gql from 'graphql-tag';

export const FRAGMENT_EVENT_COMMENT_WITH_USER = gql`
    fragment EventCommentWithUser on EventComment {
        id
        text
        ownerId
        eventId
        owner {
            id
            firstName
            lastName
            avatarUrl
        }
        event {
            name
        }
    }
`;
