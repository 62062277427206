import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { UserStatistic, ProfileSalaryFormula } from '../..';
import './ProfileForm.css';


class ProfileForm extends Component {
  static propTypes = {
    profileOwner: PropTypes.shape().isRequired,
    client: PropTypes.shape().isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 2,
    };
  }

  render() {
    return (
      <div className={`ProfileForm column twelve wide ${this.props.profileOwner.status === 'blocked' && 'custom-disabled'}`}>
        <div className="ui pointing menu item two">
          <div
            className={`item ${this.state.tabIndex === 2 && 'active'}`}
            onClick={() => this.setState({ tabIndex: 2, formError: new Map() })}
          >
            <h4 className="ui header">
              <div className="content">
                Немного цифр
              </div>
            </h4>
          </div>
          <div
            className={`item ${this.state.tabIndex === 3 && 'active'}`}
            onClick={() => this.setState({ tabIndex: 3, formError: new Map() })}
          >
            <h4 className="ui header">
              <div className="content">
                Детализация зарплаты
              </div>
            </h4>
          </div>
        </div>
        <div className={`ui tab segment flex-block ${this.state.tabIndex === 2 && 'active'}`}>
          {this.state.tabIndex === 2 && <UserStatistic profileOwner={this.props.profileOwner} />}
        </div>
        <div className={`ui tab segment flex-block ${this.state.tabIndex === 3 && 'active'}`}>
          {this.state.tabIndex === 3 && <ProfileSalaryFormula profileOwner={this.props.profileOwner} />}
        </div>
      </div>
    );
  }
}

export default ProfileForm;
