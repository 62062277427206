import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import moment from 'moment';

import {
  UserContext,
  OrderInfoCard,
  Modal,
  CurrentStatistic,
  SideColumnWithMobileBottomLine,
  Loader,
} from '../../components';
import { AdminContext, AdminOrderForm } from '../../admin/components';
import { shuffle, sortList } from '../../helpers';

import './Order.css';
import {
  TODAY_ORDERS,
  REMOVE_ORDER,
  ADD_FAKE_ORDERS,
  CURRENT_ORDERS,
  SUBSCRIPTION_ORDER_EDITED,
} from '../../gql';

const divideArray = (list) => {
  const menu = new Map
  const userMenu = list.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 4);
    let table = chunkIndex

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    /**
     * Don't leave the last person alone at the table
     */
    if (index === list.length - 2 && index % 4 === 3) {
      resultArray[chunkIndex + 1] = [item]; // start a new chunk

      table = chunkIndex + 1
    } else {
      resultArray[chunkIndex].push(item);
    }

    /**
     * Need gipperoptimization
     * But it's working
     * I'm shocking
     */
    const pos = resultArray[table].length
    let arrForPairs = []
    const sortableList = sortList({ list: item.dishes, sortBy: 'dish.menuCategoryId' })
    sortableList.forEach(({ dish: o, count: countOfDish }) => {

      if (o.menuCategoryId === '2') {
        arrForPairs = arrForPairs.concat(Array.from({ length: countOfDish }, (v, k) => ({ ...o, counter: k + 1 })));
      } else if (o.menuCategoryId === '4') {

        const counterLoop = Array.from({ length: countOfDish }, (v, k) => k)
        counterLoop.forEach((c) => {
          if (arrForPairs.length) {
            const key = `${arrForPairs.shift(0).name} + ${o.name}`
            const el = menu.get(key) || Object.assign({}, {count: 0, tables: []});
            el.count += 1
            el.tables.push({ table: table + 1, pos, withYou: item.withYou, counter: c + 1 });
            return menu.set(key, el);

          } else {
            const el = menu.get(o.name) || Object.assign({}, {count: 0, tables: []});
            el.count += 1
            el.tables.push({ table: table + 1, pos, withYou: item.withYou, counter: c + 1 });
            return menu.set(o.name, el);
          }
        })

      } else {

        const el = menu.get(o.name) || Object.assign({}, {count: 0, tables: []});
        el.count += countOfDish
        el.tables = el.tables.concat(Array.from({ length: countOfDish }, (v, k) => ({ table: table + 1, pos, withYou: item.withYou, counter: k + 1 })));
        return menu.set(o.name, el);
      }
      return menu
    });

    if (arrForPairs.length) {
      arrForPairs.forEach(d => {
        const el = menu.get(d.name) || Object.assign({}, {count: 0, tables: []});
        el.count += 1
        el.tables.push({ table: table + 1, pos, withYou: item.withYou, counter: d.counter + 1 });
        return menu.set(d.name, el);
      })
    }

    return resultArray;
  }, [])
  return { userMenu, restaurantMenu: menu }
}

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenEditOrder: false,
      isOpenRemoveOrder: false,
      isOpenAddFakeOrders: false,
      isOpenAddNewOrder: false,
      currentOrder: {},
      isShuffle: false,
      orderReceived: false,
      goToTable: props.location.state && props.location.state.goToTable,
    };
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  toggleEditOrder = (currentOrder = {}) => this.setState({ isOpenEditOrder: !this.state.isOpenEditOrder, currentOrder, orderReceived: !this.state.isOpenEditOrder });
  toggleAddNewOrders = () => this.setState({ isOpenAddNewOrder: !this.state.isOpenAddNewOrder, orderReceived: !this.state.isOpenAddNewOrder });
  toggleRemoveOrder = (currentOrder = {}) => this.setState({ isOpenRemoveOrder: !this.state.isOpenRemoveOrder, currentOrder, orderReceived: !this.state.isOpenRemoveOrder })
  toggleAddFakeOrders = () => this.setState({ isOpenAddFakeOrders: !this.state.isOpenAddFakeOrders, orderReceived: !this.state.isOpenAddFakeOrders })

  renderOrderForm = () => (
    <AdminOrderForm
      active={this.state.isOpenEditOrder}
      onClose={this.toggleEditOrder}
      order={this.state.currentOrder}
    />
  )

  renderAddNewOrderForm = (list) => {
    const ownersList = list.map(e => e.ownerId);
    return (
      <AdminOrderForm
        active={this.state.isOpenAddNewOrder}
        onClose={this.toggleAddNewOrders}
        order={this.state.currentOrder}
        ownersList={ownersList}
      />
    );
  }

  getOrderAndTable = (list, user) => {
    let table = 0;
    let order;
    list.some((group, index) => {
      table = index;
      [order] = group.filter(e => e.ownerId === user.id);
      return order;
    });
    return {
      userOrder: order && order.dishes,
      table: table + 1,
    };
  }

  renderRemoveOrder = () => (
    <Mutation mutation={REMOVE_ORDER} onError={error => console.error('REMOVE_ORDER --> ', error)} onCompleted={this.toggleRemoveOrder} >
      {(removeOrder) => {
        const { owner, id } = this.state.currentOrder;
        return (
          <Modal
            active={this.state.isOpenRemoveOrder}
            onClose={this.toggleRemoveOrder}
            header={`Удаление заказа${owner && (`: ${owner.firstName} ${owner.lastName}`)}`}
            body={`Ты действительно хочешь оставить ${owner && (`${owner.firstName} ${owner.lastName}`)}  без обеда?`}
            footer={(
              <div className="actions">
                <div className="ui black deny button" onClick={this.toggleRemoveOrder} >
                  Закрыть
                </div>
                <div
                  className="ui red right labeled icon button"
                  onClick={() => removeOrder({
                    variables: {
                      order: id,
                    },
                    refetchQueries: [{ query: TODAY_ORDERS, variables: { day: moment().date() } }],
                  })}
                >
                  Удалить
                  <i className="checkmark icon" />
                </div>
              </div>
            )}
            size="small"
          />
        )
      }}
    </Mutation>
  )

  renderAddFakeOrders = () => (
    <Mutation mutation={ADD_FAKE_ORDERS} onError={error => console.error('ADD_FAKE_ORDERS --> ', error)} onCompleted={this.toggleAddFakeOrders} >
      {(addFakeOrders) => {
        return (
          <Modal
            active={this.state.isOpenAddFakeOrders}
            onClose={this.toggleAddFakeOrders}
            onSuccess={() => addFakeOrders({
              refetchQueries: [{ query: TODAY_ORDERS, variables: { day: moment().date() } }],
            })}
            header="Добавить заказы на сегодня (для тестирования)"
            body="Функционал доступен только в режиме тестирования сайта. Будут добавлены случайные заказы обедов на сегодня."
            size="small"
          />
        )
      }}
    </Mutation>
  )

  shuffleOrders = () => this.setState({ isShuffle: true });

  queriesCacheUpdate = (order, store) => {
    const queries = [
      { query: TODAY_ORDERS, variables: { day: moment().date() } },
    ];

    queries.forEach((query) => {
      try {
        const cache = store.readQuery(query);
        // add to 'todayOrder' list
        if (cache.todayOrders) {
          //remove
          if (!order.dishes.length) {
            cache.todayOrders = cache.todayOrders.filter(e => e.ownerId !== order.ownerId)
          } else {
            // add or replace in cache
            const i = cache.todayOrders.findIndex(e => e.id === order.id);
            if (i > -1) cache.todayOrders[i] = order;
            else cache.todayOrders.unshift(order);
          }

        }
        cache.todayOrders = sortList({ list: cache.todayOrders, sortBy: 'ownerId', type: 'number' })
        store.writeQuery({ ...query, data: cache });
      } catch (error) {}
    });
  }

  renderMenuByDishes = (menu) => {
    if (!menu.size) return null;
    return (<div className="ui raised segment menu-for-absolute">
      {[...menu.keys()].map((key) => (
        <div className="flex-block menu" key={key}>
          <div className="name">{key}</div>
          <div className="count">
            <div className="number">{menu.get(key).count}</div>
          </div>
          <div className="tables flex-block">
            {menu.get(key).tables.map(el => (
              <div className={`table flex-block ${el.withYou && 'circle'}`} key={`${key}-table${el.table}-position${el.pos}-counter${el.counter}`}>
                {el.table}<small>{el.pos}</small>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>)
  };

  render() {
    const day = moment().date();
    const sideOrderList = [
      {
        name: 'showOrder',
        title: 'Посмотреть заказ',
      }];
    return (
      <AdminContext.Consumer>
        {({ isAdmin, isOfficeManager }) => (
          <Query
            query={TODAY_ORDERS}
            variables={{ day }}
          >
            {({ subscribeToMore, loading, error, data: { todayOrders = [] }, refetch, client }) => {
              if (loading) return <Loader />;
              if (error) throw new Error(error);
              const shuffledList = this.state.isShuffle ? shuffle([...todayOrders]) : todayOrders
              const { userMenu: list, restaurantMenu } = divideArray(shuffledList);

              if (!this.unsubscribe) {
                this.unsubscribe = subscribeToMore({
                  document: SUBSCRIPTION_ORDER_EDITED,

                  updateQuery: (prev, {subscriptionData}) => {
                    if (!subscriptionData.data) return prev;
                    const {orderEdited} = subscriptionData.data;

                    this.queriesCacheUpdate(orderEdited, client)
                  },
                });
              }
              const hasAdminAccess = isAdmin || isOfficeManager;

              return (
                <div
                  className={`Order ${hasAdminAccess ? 'admin-container-with-fixed-header' : 'container-with-fixed-header'}`}>
                  {hasAdminAccess && this.state.isOpenEditOrder && this.renderOrderForm()}
                  {hasAdminAccess && this.renderRemoveOrder()}
                  {/*{hasAdminAccess && this.renderAddFakeOrders()}*/}
                  {hasAdminAccess && this.renderAddNewOrderForm(shuffledList)}
                  <div className={`html ui attached grid ${!hasAdminAccess && 'custom-reverse'}`}>
                    <div className="ui column  twelve wide" id="section-to-print">
                      {hasAdminAccess && this.renderMenuByDishes(restaurantMenu)}
                      {list.length < 1 &&
                        <div className="without-orders">Сегодня заказов еще не было.</div>
                      }
                      <div className="menu-by-table">
                      {list.map((e, index) => (
                        <div className="ui column row with-margin" key={`table-${index}`}>
                          <div className="link-for-scrolling" id={`table-${index + 1}`}></div>
                          <div className="column">
                            <div className="ui raised segment">
                              <a className="ui blue ribbon label big">Стол №{index + 1}</a>
                              <div className="ui column grid right">

                                {e.map(order => (
                                  <div className="ui column row wide" key={order.id}
                                       onClick={hasAdminAccess ? () => this.toggleEditOrder(order) : () => {
                                       }}>
                                    <div className="column">
                                      <div
                                        className={`ui raised segment flex ${order.withYou && 'with-you-background'}`}>
                                        <div className="user-name"><b>{order.owner.firstName} {order.owner.lastName}</b>
                                        </div>
                                        <div className="ui list">

                                          {order.dishes.map(({ dish, count }) => (
                                            <div className="item" key={`${order.ownerId}-${order.id}-${dish.id}`}>
                                              {count > 1 &&
                                              <b>({count} порции) </b>
                                              }
                                              {dish.name}
                                              {hasAdminAccess && <span> ({dish.category})</span>}
                                            </div>
                                          ))}

                                        </div>
                                        {order.withYou && <b className={`with-you ${hasAdminAccess && 'hide-with-you'}`}>С собой!</b>}
                                        {hasAdminAccess &&
                                          <i
                                            className="trash icon red"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              this.toggleRemoveOrder(order);
                                            }}
                                          />
                                        }
                                      </div>
                                    </div>
                                  </div>
                                ))}

                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    </div>

                    {hasAdminAccess ?
                      <div className="ui column four wide">
                        <div className="fixed">
                          <div className="ui labeled icon vertical menu">
                            <a className="item" onClick={() => { refetch().then(() => { window.print(); }); }}>
                              <i className="print green icon" />
                              Распечатать
                            </a>
                            {/*<a className="item" onClick={() => { refetch().then(() => { this.shuffleOrders(); }); }}>*/}
                            {/*  <i className="sync orange icon"/>*/}
                            {/*  Перемешать*/}
                            {/*</a>*/}
                            <a className="item" onClick={this.toggleAddNewOrders}>
                              <i className="add green icon"/> Добавить<br/>заказ за<br/>сотрудника
                            </a>
                            {/*<a className="item" onClick={this.toggleAddFakeOrders}><i*/}
                              {/*className="wrench yellow icon"/> Добавить<br/>заказы<br/>на сегодня</a>*/}
                          </div>
                        </div>
                      </div>
                      :
                      <React.Fragment>
                        <div className="ui column four wide">
                          <div className="ui cards fixed order-mobile-side-menu">
                            <UserContext.Consumer>
                              {({user}) => (
                                <OrderInfoCard {...this.getOrderAndTable(list, user)} />
                              )}
                            </UserContext.Consumer>
                            <CurrentStatistic type="currentOrders" query={CURRENT_ORDERS} />
                          </div>
                        </div>
                        <SideColumnWithMobileBottomLine
                          menuList={sideOrderList}
                        >
                          <div className="ui cards fixed order-mobile-side-menu">
                            <UserContext.Consumer>
                              {({user}) => (
                                <OrderInfoCard {...this.getOrderAndTable(list, user)} />
                              )}
                            </UserContext.Consumer>
                            <CurrentStatistic type="currentOrders" query={CURRENT_ORDERS} />
                          </div>
                        </SideColumnWithMobileBottomLine>
                      </React.Fragment>
                    }
                  </div>
                </div>
              )
            }}
          </Query>
        )}
      </AdminContext.Consumer>

    );
  }
}

export default Order;
