import React from 'react';

import './BrokenPage.css';

function BrokenPage() {
  return (
    <div className="BrokenPage">
      <div className="ui image">
        <img alt="Broken page" src="/assets/images/broken.svg" />
        <div className="text">
          <p>Ой, что-то пошло не так...</p>
          <div className="ui large green button" onClick={() => {
            window.location.replace(window.location.origin)
          }}>Вернуться на главную</div>
        </div>
      </div>
    </div>
  );
}

export default BrokenPage;
