import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import { EDIT_SETTINGS_TRAINEE_LUNCH_LIMIT } from '../../../gql';
import { Modal } from '../../../components';

class AdminEditTraineeLunchLimit extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      limit: props.limit,
    };
  }

  onSubmit = (editSettingsTraineeLunchLimit) => {
    const { limit } = this.state;

    editSettingsTraineeLunchLimit({
      variables: {
        name: 'traineeLunchLimit',
        value: limit,
      },
    });
  };

  renderInput = () => {
    const { limit } = this.state;
    return (
      <div className="field ">
        <div className="ui left icon input ">
          <i className="coffee icon" />
          <input
            type="number"
            name="traineeLunchLimit"
            min="0"
            value={limit}
            onChange={({ target: { value } }) => this.setState({ limit: value })}
          />
        </div>
      </div>
    );
  };

  render() {
    const { onClose } = this.props;
    return (
      <Mutation mutation={EDIT_SETTINGS_TRAINEE_LUNCH_LIMIT} onCompleted={onClose}>
        {editSettingsTraineeLunchLimit => (
          <Modal
            active
            onClose={onClose}
            onSuccess={() => this.onSubmit(editSettingsTraineeLunchLimit)}
            header="Редактирование лимита суммы обеда для стажера"
            body={this.renderInput()}
            size="small"
          />
        )}
      </Mutation>
    );
  }
}

AdminEditTraineeLunchLimit.propTypes = {
  onClose: PropTypes.func,
  limit: PropTypes.string,
};

AdminEditTraineeLunchLimit.defaultProps = {
  limit: '',
  onClose: () => {},
};

export default AdminEditTraineeLunchLimit;
