import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import moment from 'moment';
import 'moment/locale/ru';

import './MonthsSelector.css';

const months = moment.localeData('ru').months();

class MonthsSelector extends Component {
  static propTypes = {
    changeMonth: PropTypes.func,
    month: PropTypes.string.isRequired,
    className: PropTypes.string,
  }
  static defaultProps = {
    changeMonth: () => {},
    className: '',
  }

  constructor(props) {
    super(props);
    this.refInput = React.createRef();
    this.containerInput = React.createRef();
    this.state = {
      isOpenDropdown: false,
      months,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }
  handleClick = ({ target }) => {
    if (this.state.isOpenDropdown) {
      if (this.containerInput.current.contains(target)) return;
      this.setState({ isOpenDropdown: false });
    }
  }

  onChangeMonth = (month) => {
    this.setState({ isOpenDropdown: !this.state.isOpenDropdown });
    this.props.changeMonth(month);
  }

  render() {
    return (
      <div className="MonthsSelector">
        <div
          className={`ui selection search dropdown  ${this.props.className} ${this.state.isOpenDropdown && 'active visible'}`}
          onClick={() => this.setState({ isOpenDropdown: !this.state.isOpenDropdown })}
          ref={this.containerInput}
        >
          <input
            type="text"
            name="user"
            className="search"
            value={this.state.months[this.props.month]}
            autoComplete="off"
            ref={this.refInput}
          />

          <i className="dropdown icon" />
          <div className={`menu transition ${this.state.isOpenDropdown && 'visible'}`}>
            {this.state.months.map(month => (
              <div
                key={month}
                className={`item ${month === (this.state.months[this.props.month]) && 'active selected'}`}
                data-value={month}
                onClick={() => this.onChangeMonth(this.state.months.indexOf(month))}
              >
                {month}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default MonthsSelector;
