import gql from 'graphql-tag';

export const FRAGMENT_USER_MAIN_INFO = gql`
    fragment UserMainInfo on User {
        id
        firstName
        lastName
        phone
        avatarUrl
        priority
        createdAt
        updatedAt
    }
`;
export const FRAGMENT_USER_FULL_INFO = gql`
    fragment UserFullInfo on User {
        ...UserMainInfo
        role {
            id
            name
            rusName
        }
        departments {
            id
            slug
            name
        }
    }
    ${FRAGMENT_USER_MAIN_INFO}
`;
