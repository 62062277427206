import gql from 'graphql-tag';
import { FRAGMENT_USER_FULL_INFO } from '../';

export const EDIT_USER = gql`
    mutation editUser($user: EditUserCredentials!) {
        editUser(user: $user) {
            ...UserFullInfo
        }
    }
    ${FRAGMENT_USER_FULL_INFO}
`;
export const CHANGE_PASSWORD = gql`
    mutation changePassword($user: EditUserCredentials!) {
        changePassword(user: $user) {
            ...UserFullInfo
        }
    }
    ${FRAGMENT_USER_FULL_INFO}
`;
export const SAVE_AVATAR = gql`
    mutation saveAvatar($user: EditUserCredentials!) {
        saveAvatar(user: $user) {
            ...UserFullInfo
        }
    }
    ${FRAGMENT_USER_FULL_INFO}
`;
export const CHANGE_STATUS = gql`
    mutation changeStatus($user: EditUserCredentials!) {
        changeStatus(user: $user) {
            ...UserFullInfo
        }
    }
    ${FRAGMENT_USER_FULL_INFO}
`;
export const CHANGE_ROLE = gql`
    mutation changeRole($user: EditUserCredentials!) {
        changeRole(user: $user) {
            ...UserFullInfo
        }
    }
    ${FRAGMENT_USER_FULL_INFO}
`;
