import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { sortList } from '../../helpers';

import './PageSidePanel.css';

class PageSidePanel extends Component {
  static propTypes = {
    changeContent: PropTypes.func.isRequired,
    showContent: PropTypes.string.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    menuList: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      userPosition: PropTypes.number.isRequired,
      adminPosition: PropTypes.number.isRequired,
    })).isRequired,
    fixed: PropTypes.bool,
  }
  static defaultProps = {
    fixed: false,
  }

  isActiveClass = (element) => {
    if (this.props.showContent) {
      return this.props.showContent === element.name && 'active';
    } else {
      return element[this.props.isAdmin ? 'adminPosition' : 'userPosition'] === 1 && 'active';
    }
  }

  render() {
    const list = sortList({ list: this.props.menuList, sortBy: this.props.isAdmin ? 'adminPosition' : 'userPosition' })
    return (
      <div className="PageSidePanel">
        <div className={`ui vertical steps right ${this.props.fixed && 'fixed'}`}>
          {list.map((element) => {
            if (!this.props.isAdmin && element.userPosition < 1) return null;
            return (
              <div
                data-modal="shouldClose"
                className={`step pointer ${this.isActiveClass(element)}`}
                key={element.name}
                onClick={() => this.props.changeContent(element.name)}
              >
                <div className="content">
                  <div className="title">{element.title}</div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    );
  }
}

export default PageSidePanel;
