import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation, Query } from 'react-apollo';

import { Modal } from '../../../components';
import { EDIT_SETTINGS_ORDER_TIME } from '../../../gql';
import './AdminEditTime.css';

class AdminEditTime extends Component {
  // static getDerivedStateFromProps(nextProps) {
  //   const { time } = nextProps;
  //   return { time };
  // }

  constructor(props) {
    super(props);
    this.state = {
      time: props.time || '10:00',
    };
  }

  onSubmit = (editSettingsOrderTime) => {
    editSettingsOrderTime({
      variables: {
        name: 'time',
        value: this.state.time,
      },
    });
  }


  renderTimeInput = () => (
    <div className="field ">
      <div className="ui left icon input ">
        <i className="time icon" />
        <input
          type="time"
          name="time"
          value={this.state.time}
          onChange={({ target: { value } }) => this.setState({ time: value })}
        />
      </div>
    </div>
  )

  render() {
    return (
      <Mutation mutation={EDIT_SETTINGS_ORDER_TIME} onCompleted={this.props.onClose} >
        {(editSettingsOrderTime, { loading, data , error }) => {
          return (
            <Modal
              active={this.props.active}
              onClose={this.props.onClose}
              onSuccess={() => this.onSubmit(editSettingsOrderTime)}
              header="Редактирование времени для окончания заказа"
              body={this.renderTimeInput()}
              size="small"
            />
          );
        }}
      </Mutation>
    );
  }
}
AdminEditTime.defaultProps = {
  time: '',
  active: false,
  onClose: () => {},
};
AdminEditTime.propTypes = {
  time: PropTypes.string,
  active: PropTypes.bool,
  onClose: PropTypes.func,
  client: PropTypes.shape().isRequired,
};


export default AdminEditTime;
