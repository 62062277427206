import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import InputMask from 'react-input-mask';

import './ForgotPassword.css';
import { getErrorMessages, showErrors } from '../../helpers';
import { CREATE_TEMP_PASSWORD } from '../../gql';

class ForgotPassword extends Component {
  static propTypes = {
    history: PropTypes.shape().isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      formError: new Map(),
      form: new Map(),
    };
  }

  onCompleted = (data) => {
    if (!data.createTempPassword) {
      const { formError } = this.state;
      formError.set('response', 'Неверный номер телефона');
      this.captcha.reset();
      this.setState({ formError });
      return;
    }

    this.props.history.replace('/login');
  }

  onError = (error) => {
    const errorMessage = getErrorMessages(error);
    const { formError } = this.state;
    formError.set('response', errorMessage[0]);
    this.setState({ formError });
  }

  onCaptcha = () => {
    const { formError, form } = this.state;
    let isError = false;
    if (!form.has('phone')) {
      formError.set('phone', 'Введите номер телефона');
      isError = true;
    }
    this.setState({ formError });
    !isError && this.captcha.execute();
  }

  validate = ({ target: { name, value } }) => {
    const { formError, form } = this.state;
    form.set(name, value);
    formError.has('response') && formError.delete('response');

    form.set(name, value.replace(/\D/g, '').substring(1));
    form.get(name).length === 10 ?
      formError.delete(name) :
      formError.set(name, 'Неверный номер телефона');

    this.setState({ form, formError });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.onCaptcha();
  }

  render() {
    return (
      <Mutation mutation={CREATE_TEMP_PASSWORD} onError={this.onError} onCompleted={this.onCompleted} >
        {createTempPassword => (
          <div className="ForgotPassword">
            <div className="ui center aligned grid">
              <div className="column">
                <form
                  className={`ui large form ${this.state.formError.size && 'error'}`}
                  onSubmit={e => this.handleSubmit(e)}
                >
                  <div className="ui segment">
                    <h2 className="ui">
                      Восстановление пароля
                    </h2>
                    <div className={`field ${this.state.formError.has('phone') && 'error'}`}>
                      <label className="leftLabel">Введите номер телефона на который будет выслан временный пароль</label>
                      <div className="ui left icon input">
                        <i className="user icon" />
                        <InputMask
                          name="phone"
                          mask="+7 (999) 999-99-99"
                          placeholder="Номер телефона"
                          value={this.state.form.get('phone')}
                          onChange={this.validate}
                        />
                      </div>
                    </div>

                    <button
                      className={`ui fluid large green submit button ${(!this.state.form.has('phone') || this.state.formError.size) && 'disabled'}`}
                      type="submit"
                    >
                      Прислать пароль
                    </button>
                    <div className="ui login-link">
                      Уже есть аккаунт? <Link to="/login">Войдите</Link>
                    </div>
                  </div>

                  <div className="ui error message">
                    <ul className="list">
                      {showErrors(this.state.formError)}
                    </ul>
                  </div>
                  <ReCAPTCHA
                    ref={(el) => { this.captcha = el; }}
                    size="invisible"
                    sitekey="6LfVi1YUAAAAAC7MlG-PIcC1oqdnNeHOz81-qhxP"
                    onChange={reCaptcha => createTempPassword({ variables: { reCaptcha, phone: this.state.form.get('phone') } })}
                  />
                </form>
              </div>
            </div>
          </div>
        )}
      </Mutation>
    );
  }
}

export default ForgotPassword;
