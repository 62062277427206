import React, { Component } from 'react';
import { graphql, Mutation } from 'react-apollo';
import moment from 'moment';
import axios from 'axios';
import DatePicker from 'react-datepicker';

import './AdminCalendar.css';

import { CALENDAR_HOLIDAYS, CALENDAR_ADD_HOLIDAYS } from '../../../gql';
import { Loader, Modal, YearsSelector } from '../../../components';

const currentYear = moment().year();
const months = moment.localeData('ru').months();

class AdminCalendar extends Component {
  static getDerivedStateFromProps(nextProps, state) {
    const { holidays = [], loading } = nextProps.data;
    if (!state.holidaysReceived && !loading) {
      const holidaysDays = holidays.map(e => moment(moment(e.date).startOf('day').format()))
      return {
        holidays: holidaysDays,
        holidaysReceived: true,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      holidaysReceived: false,
      calendarWasChanged: false,
      isOpenConfirmChanges: false,
      selectedYear: currentYear,
      holidays: [],
      selectedStart: moment().set('year', currentYear).startOf('year')
    };
  }

  componentDidUpdate() {
    if (this.state.selectedStart) setTimeout(() => this.setState({ selectedStart: '' }),500)
  }

  changeHolidays = (day) => {
    const { holidays } = this.state;
    const filteredHolidays = holidays.filter(e => moment(e).diff(moment(day), 'days'))
    if (holidays.length <= filteredHolidays.length) {
      this.setState({ holidays: [...holidays, day], calendarWasChanged: true });
    } else {
      this.setState({ holidays: filteredHolidays, calendarWasChanged: true });
    }
  }

  toggleConfirmChanges = () => this.setState({ isOpenConfirmChanges: !this.state.isOpenConfirmChanges });

  addWeekends = () => {
    const start = moment(moment().set('year', this.state.selectedYear).startOf('year'));
    const end = moment(moment().set('year', this.state.selectedYear).endOf('year'));
    let counter = 0;

    const weekends = [];
    const current = start.clone();

    while (current.day(7).isBefore(end) && counter < 1000) {
      weekends.push(current.clone().subtract(1, 'day'));
      weekends.push(current.clone());
      counter += 1;
    }

    this.setState({ holidays: weekends, calendarWasChanged: true });
  }
  clearHolidays = () => this.setState({ holidays: [], calendarWasChanged: true });

  parseHeadHunter = () => {
    axios('https://taganrog.hh.ru/article/calendar2019', {
      method: "GET",
      withCredentials: true,
      headers: new Headers({
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
        'Content-Type' : 'application/x-www-form-urlencoded'
    })})
      .then((response) => {
        console.log('--===>>>');
      })
  }

  onChangeSelectedYear = (year) => {
    if (year === this.state.selectedYear) return;
    this.setState({ selectedYear: year, holidaysReceived: false, selectedStart: moment().set('year', year).startOf('year') })
    this.props.data.refetch({ year: `${year}` })
  }

  renderConfirmChanges = () => {
    const { holidays, selectedYear, isOpenConfirmChanges } = this.state;

    return (
      <Mutation
        mutation={CALENDAR_ADD_HOLIDAYS}
        onError={(data) => {
          console.error('===e=', data);
        }}
        onCompleted={() => this.setState({ calendarWasChanged: false, isOpenConfirmChanges: false })}
      >
        {addHolidays => (
          <Modal
            active={isOpenConfirmChanges}
            onClose={this.toggleConfirmChanges}
            onSuccess={() => addHolidays({
              variables: {
                year: `${selectedYear}`,
                days: holidays,
              },
            })}
            header="Редактирование календаря"
            body={`Ты действительно хочешь внести измениния в праздники и выходные ${selectedYear} года`}
            size="small"
          />
        )}
      </Mutation>
    );
  };

  render() {
    const { holidays, selectedYear } = this.state;
    const highlightWithRanges = [
      { 'react-datepicker__day--highlighted-custom-holidays': holidays },
    ]
    const { loading, error } = this.props.data || {}
    if (loading) return <Loader />;
    if (error) throw new Error(error);

    return (
      <div className="AdminCalendar">
        <div className="html ui grid">
          <div className="column twelve wide">
            <YearsSelector year={selectedYear} changeYear={this.onChangeSelectedYear} />
            <DatePicker
              selected={this.state.selectedStart}
              inline
              fixedHeight
              onChange={this.changeHolidays}
              monthsShown={12}
              highlightDates={highlightWithRanges}
            />
          </div>
          <div className="column four wide">
            <div className="monthCard ui card floated right">
              <div className="content spaced">
                <div className="header">Получить данные</div>

                <div className="description">
                  <button
                    className={`ui blue fluid button`}
                    onClick={this.addWeekends}
                  >
                    Только выходные дни
                  </button>
                  {/*<button*/}
                    {/*className={`ui fluid blue button`}*/}
                    {/*onClick={this.parseHeadHunter}*/}
                  {/*>*/}
                    {/*Сервис HeadHunter*/}
                  {/*</button>*/}

                    <button
                      className={`ui button fluid ${!holidays.length && 'disabled'}`}
                      onClick={this.clearHolidays}
                    >
                      Очистить
                    </button>
                    <button
                      className={`ui positive button fluid ${!this.state.calendarWasChanged && 'disabled'}`}
                      onClick={this.toggleConfirmChanges}
                    >
                      Сохранить
                    </button>
                </div>
              </div>
            </div>
            <div className="monthCard ui card floated right">
              <div className="content">
                <div className="header">
                  <div>
                  Рабочие дни:
                  <span className="right"><b>{moment().year(selectedYear).endOf('year').dayOfYear() - holidays.length}</b></span>
                  </div>
                <div>
                  Выходные дни:
                  <span className="right"><b>{holidays.length}</b></span>
                </div>
                </div>

                <div className="description">
                  {months.map((month, index) => {
                    const daysInMonth = moment().year(selectedYear).month(month).daysInMonth();
                    const holidayDays = holidays.filter(d => moment(d).month() === index).length;
                    return (
                      <div
                        key={month}
                        className="month"
                      >
                        {month}
                        <span className="right"><b>{`${daysInMonth - holidayDays} / ${holidayDays}`}</b></span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.renderConfirmChanges()}
      </div>
    );
  }
}

export default graphql(CALENDAR_HOLIDAYS, {
  options: { variables: { year: `${currentYear}` } },
})(AdminCalendar);
