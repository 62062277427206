import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import moment from 'moment';

import { secondsToTime } from '../../helpers';
import { COMMON_SUBSCRIPTION, SETTINGS_ORDER_TIME } from '../../gql';
import './OrderTime.css';

const getSeconds = (time = '10:00') => {
  const [hour, minute] = time.split(':');
  const now = moment().utcOffset('+0300');
  const limitTime = now.hour(Number(hour)).minute(Number(minute)).second(0).valueOf();

  return Math.max((limitTime - new Date()) / 1000, 0);
}

class OrderTime extends Component {
  static propTypes = {
    timeExpire: PropTypes.func.isRequired,
    isOrderExist: PropTypes.bool.isRequired,
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { orderTime = {} } = nextProps.data;
    if (orderTime.value && !state.timeReceived) {
      const seconds = getSeconds(orderTime.value);
      nextProps.timeExpire(seconds);
      return {
        time: secondsToTime(seconds),
        seconds,
        orderTime: orderTime.value,
        timeReceived: true,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      timer: null,
      timeReceived: false,
      time: {},
      seconds: 0,
      orderTime: '10:00',
      isTimeExpired: false,
    };
  }

  componentDidMount() {
    this.startTimer();
    if (!this.unsubscribe) {
      this.unsubscribe = this.props.data.subscribeToMore({
        document: COMMON_SUBSCRIPTION,

        updateQuery: (prev, {subscriptionData}) => {
          if (!subscriptionData.data) return prev;
          const {commonSubscription: {orderTime = {}} = {}} = subscriptionData.data;
          /**
           * we can different subscriptions data
           * so "orderTime" can returning as "null"
           */
          if (!(orderTime && orderTime.id)) return prev;
          const seconds = getSeconds(orderTime.value);

          this.props.timeExpire(seconds);
          if (!this.state.timer) this.startTimer();

          this.setState({
            time: secondsToTime(seconds),
            seconds,
            orderTime: orderTime.value,
          })

          return Object.assign({}, prev, {
            orderTime,
          });
        },
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.timer);
    if (this.unsubscribe) this.unsubscribe();
  }

  countDown = () => {
    const seconds = this.state.seconds - 1;
    if (seconds <= 0) {
      // clearInterval(this.state.timer);
      this.setState({ seconds: getSeconds(this.state.orderTime), isTimeExpired: true });
      this.props.timeExpire();
    } else {
      if (this.state.isTimeExpired) {
        this.props.client.resetStore()
      }
      this.setState({ seconds, time: secondsToTime(seconds), isTimeExpired: false });
    }
  }

  startTimer = () => {
    const timer = setInterval(this.countDown, 1000);
    this.setState({ timer });
  }

  render() {
    const {
      time,
      seconds,
      orderTime,
    } = this.state;

    return (
      <React.Fragment >
        <div className="ui column twelve wide time-message">
          {seconds ?
            <div className="ui message success">
              Успей заказать до <b>{orderTime}</b>.
            </div>
            :
            <div className="ui floating message error">
              Заказ обеда осуществляется до <b>{orderTime}</b>.
            </div>
          }
        </div>
        <div className="ui column four wide time-message fixed">
          {seconds ?
            <div className="ui fixed message success">
              <span className="full-sub-message">Заказ возможен еще </span>
              <span className="short-sub-message">Осталось </span>
              <b>{time.h}:{time.m}:{time.s}</b>
            </div>
            :
            <div className={`ui fixed message ${this.props.isOrderExist ? 'success' : 'error'}`}>
              {this.props.isOrderExist ? 'Заказ сделан' : 'Время вышло.'}
            </div>
          }
        </div>
      </React.Fragment>
    );
  }
}


export default graphql(SETTINGS_ORDER_TIME)(OrderTime);
