import React, { useState } from 'react';
import { Query } from 'react-apollo';
import moment from 'moment';

import { WORK_DAYS } from '../../../gql';
import './AdminWorkDaysNotification.css';

function AdminWorkDaysNotification() {
  const [isHide, setHide] = useState(false);

  return (
    <Query
      query={WORK_DAYS}
    >
      {({ loading, error, data }) => {
        if (loading || error) return null;
        if (isHide || (data.workDays === 247 || (moment().isLeapYear() && data.workDays === 248))) return null;

        return (
          <div className="AdminWorkDaysNotification ui message error">
            <i className="close icon" onClick={() => setHide(true)} />
            <div className="header">
              Неверное количество рабочих дней в году!
            </div>
            <p>Количество рабочих дней в году не соответствует 247 (248 для високосного года) дням.</p>
            <p>Перейдите в календарь и проверьте правильно ли выставлены все праздничные и выходные дни.</p>
          </div>
        );
      }}
    </Query>
  );
}

AdminWorkDaysNotification.defaultProps = {};
AdminWorkDaysNotification.propTypes = {};

export default AdminWorkDaysNotification;
