import './index.scss';


export default function HandbookContacts(props) {
    return (
      <div className="handbook-contacts handbook__section">
        <h1 className="handbook__title">
          Основные контакты
        </h1>
        <div className="handbook-contacts__container">
          {props.data.map(({ name, post, img }) =>
            <div key={name} className="handbook-contacts__item">
              <img className="handbook-contacts__item-image" src={img} alt={name}/>
              <div className="handbook-contacts__item-content">
                <div className="handbook-contacts__item-name">{name}</div>
                <div className="handbook-contacts__item-post">{post}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
}
