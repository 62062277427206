import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import { CreateEventForm, EventView, Loader } from '../../components';
import { EVENT, SUBSCRIPTION_EVENTS_EDITED } from '../../gql';

import './Event.css';

class Event extends Component {
  static propTypes = {
    match: PropTypes.shape().isRequired,
    history: PropTypes.shape().isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
    };
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  renderInfoBlock = () => (
    <div className="ui floating message info">
      <p>Вы можете "подписаться" на участие до окончания срока регистрации</p>
      <p>Если вы выбрали Точно буду, то отмазать вас сможет только администратор</p>
      <p>Если количество гарантированных участников меньше чем требуемое количество человек, то может и не стоило оно того?</p>
    </div>
  )
  goToBack = () => {
    this.props.history.goBack()
  }
  handleToggleEdit = () => {
    this.setState({ isEdit: !this.state.isEdit })
  }

  render() {
    return (
      <div className="Event container-with-fixed-header">
        <Query
          query={EVENT}
          variables={{ id: this.props.match.params.id }}
        >
          {({loading, error, data, subscribeToMore }) => {
            if (loading) return <Loader outerStyles={{ marginTop: '73px' }} />;
            if (error) throw new Error(error);

            const { event = {} } = data || {};

            if (!this.unsubscribe) {
              this.unsubscribe = subscribeToMore({
                document: SUBSCRIPTION_EVENTS_EDITED,

                updateQuery: (prev, { subscriptionData }) => {
                  if (!subscriptionData.data) return prev;
                  const { eventEdited = {} } = subscriptionData.data;

                  // we must update event only if an editable event is open
                  if (prev.event.id !== eventEdited.id) return prev;
                  return Object.assign({}, prev, {
                    event: { ...prev.event, ...eventEdited },
                  });
                },
              });
            }

            return (
              <div className="html ui attached grid">
                {this.state.isEdit ?
                  <CreateEventForm changeContent={this.handleToggleEdit} event={event} />
                  :
                  <EventView event={event} renderInfoBlock={this.renderInfoBlock} onEdit={this.handleToggleEdit} />
                }
              </div>
            )
          }}
        </Query>

      </div>
    );
  }
}

export default Event;
