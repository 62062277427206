import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import { EVENT_TYPES } from '../../gql';
import { Loader } from '../';

import './EventTypesSelector.css';

class EventTypesSelector extends Component {
  static propTypes = {
    changeEventType: PropTypes.func,
    eventType: PropTypes.shape(),
    className: PropTypes.string,
    defaultValue: PropTypes.number,
  }
  static defaultProps = {
    eventType: {},
    changeEventType: () => {},
    className: '',
    defaultValue: 0,
  }

  constructor(props) {
    super(props);
    this.containerInput = React.createRef();
    this.state = {
      isOpenDropdown: false,
      searchEventType: props.eventType.rusName || '',
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }
  handleClick = ({ target }) => {
    if (this.state.isOpenDropdown) {
      if (this.containerInput.current.contains(target)) return;
      this.setState({ isOpenDropdown: false });
    }
  }

  onChangeEventType = (eventType) => {
    this.setState({ isOpenDropdown: !this.state.isOpenDropdown, searchEventType: eventType.rusName });
    this.props.changeEventType(eventType);
  }

  clearSelector = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.refInput.current.focus();
    this.setState({ searchEventType: '', isOpenDropdown: true });
    this.props.changeEventType({});
  }

  filterList = list => list.filter(e => (
    e.rusName.toLowerCase().includes(this.state.searchEventType.toLowerCase())
  ));

  render() {
    return (
      <div className={`EventTypesSelector ${this.props.className}`}>
        <Query
          query={EVENT_TYPES}
        >
          {({ loading, error, data }) => {
            if (loading) return <Loader />;
            if (error) throw new Error(error);

            const eventTypes = (data && data.eventTypes) || [];

            return (
              <div
                className={`ui selection search dropdown ${this.state.isOpenDropdown && 'active visible'}`}
                onClick={() => this.setState({ isOpenDropdown: !this.state.isOpenDropdown })}
                ref={this.containerInput}
              >
                <input
                  type="button"
                  name="eventType"
                  className="search"
                  value={this.state.searchEventType || (eventTypes.find(({ id }) => id === this.props.defaultValue) || {}).rusName}
                  autoComplete="off"
                />

                <i className="dropdown icon" />
                <div className={`menu transition ${this.state.isOpenDropdown && 'visible'}`}>
                  {eventTypes.map(eventType => (
                    <div
                      key={eventType.id}
                      className={`item ${eventType.id === (this.props.eventType.id) && 'active selected'}`}
                      data-value={eventType.rusName}
                      onClick={() => this.onChangeEventType(eventType)}
                    >
                      {eventType.rusName}
                    </div>
                  ))}
                </div>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default EventTypesSelector;
