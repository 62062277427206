import React from 'react';
import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype';
import { Mutation, Query } from 'react-apollo';

import './Chat.css';
import { Loader, UserContext } from '..';
import { ADD_EVENT_COMMENT, EVENT_COMMENTS } from '../../gql';

class Chat extends React.PureComponent {
  state = {
    comment: '',
  };

  handleChangeComment = ({ target: { value: comment } }) => {
    this.setState({ comment });
  };

  handleCleanComment = () => this.setState({ comment: '' });

  handleAddToEvent = (addEventComment) => {
    const { eventId } = this.props;
    addEventComment({
      variables: {
        eventId,
        text: this.state.comment,
      },
    });
  };

  renderCommentsList = eventComments => eventComments.map(comment => (
    <div className="item" key={comment.id}>
      <img className="ui avatar image" alt="Commentator's face" src={comment.owner.avatarUrl} />
      <div className="content">
        <a className="header">
          {comment.owner.firstName} {comment.owner.lastName}
        </a>
        <div className="description">{comment.text}</div>
      </div>
    </div>
  ));

  renderComments = eventComments => (
    <div className="ui segment">
      <div className="ui list divided very relaxed">
        {this.renderCommentsList(eventComments)}
      </div>
    </div>
  );

  render() {
    return (
      <UserContext.Consumer>
        {({ user }) => (
          <Query
            query={EVENT_COMMENTS}
            variables={{ eventId: this.props.eventId }}
          >
            {({
              loading, error, data,
            }) => {
              if (loading) return <Loader />;
              if (error) throw new Error(error);

              const { eventComments = [] } = data || {};

              return (
                <div className="Chat" style={this.props.style}>
                  <div className="ui segments">
                    <div className="ui segment">
                      <h3 className="margin-15">Коментарии: {eventComments.length}</h3>
                    </div>

                    <div className="ui segment flex-block">
                      <img className="ui avatar image" src={user.avatarUrl} />
                      <div className="ui transparent input comment-input">
                        <input
                          placeholder="Оставить комментарий..."
                          type="text"
                          value={this.state.comment}
                          onChange={this.handleChangeComment}
                        />
                      </div>

                      <Mutation
                        mutation={ADD_EVENT_COMMENT}
                        onError={e => console.error('error', e)}
                        onCompleted={this.handleCleanComment}
                      >
                        {addEventComment => (
                          <div
                            className={`ui button blue send-button ${!(this.state.comment && this.state.comment.trim()) && 'disabled'}`}
                            onClick={() => this.handleAddToEvent(addEventComment)}
                          >
                            Отправить
                          </div>
                        )}
                      </Mutation>
                    </div>
                    {eventComments.length > 0 && this.renderComments(eventComments)}
                  </div>
                </div>
              );
            }}
          </Query>
        )}
      </UserContext.Consumer>
    );
  }
}

Chat.propTypes = {
  eventId: PropTypes.string.isRequired,
  style: stylePropType,
};

Chat.defaultProps = {
  style: {},
};

export default Chat;
