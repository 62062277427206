import React from 'react'; 
import { Query, ApolloConsumer } from 'react-apollo'; 
import { useQuery, ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import moment from 'moment';

import { NEWS, BEST_USER } from '../../gql'; 
import { UserContext, SideColumnWithMobileBottomLine, Loader } from '../../components'; 
import DuniceStarsBlock from '../../components/DuniceStarsBlock'; 

import './Home.css';

const linkify = (text) => {
  return text
    .split(/(\[[^\]]+\]\(https?:\/\/[^\)]+\))/ig)
    .map((part) => {
      const splittedPart = part.split(/\[([^\]]+)\]\((https?:\/\/[^\)]+)\)/ig);
      if (splittedPart.length === 1) return part;
      const [_, name, link] = splittedPart;
      return <a key={link} href={link} target="_blank">{name}</a>
    });
};

const UserBlock = ({editable}) => {
  const { loading, error, data } = useQuery(BEST_USER, {});
  return (
    <div className='user-best-block'>
      <DuniceStarsBlock type={'best_employee'} data={data} editable={editable} />
      <DuniceStarsBlock type={'best_student'} data={data} editable={editable} />
    </div>
  )
}

const SideInfoBlock = () => {
  const sideMenuList = [
    {
      name: 'homeNews',
      title: 'Новости IBS Dunice',
    }];
  const news = (
    <Query
      query={NEWS}
      variables={{ hide: false }}
    >
      {({ loading, error, data }) => {
        if (loading) return <Loader />;
        if (error) throw new Error(error);
        return (
          <div className="ui raised segment home-info-block">
            <h3>Наша миссия - способствовать бизнес успеху наших клиентов.</h3>
            <h2>Новости IBS Dunice</h2>
            {data.news.length < 0 ? (
              <div>нет новостей</div>
            ) : (
              data.news.map(news => (
                <div key={news.id}>
                  <h3>{news.title}</h3>
                  <div className="news-text">{linkify(news.text)}</div>
                  <em className="news-date">{moment(new Date(news.createdAt)).format('LL')}</em>
                </div>
              ))
            )}
          </div>
        )
      }}
    </Query>
  );
  return (
    <>
      <div className="ui column six wide">{news}</div>
      <SideColumnWithMobileBottomLine
        mainClass="six"
        menuList={sideMenuList}
      >
        {news}
      </SideColumnWithMobileBottomLine>
    </>
  );
}

function Home() {
  const news = (
    <Query
      query={NEWS}
      variables={{ hide: false }}
    >
      {({ loading, error, data }) => {
        if (loading) return <Loader />;
        if (error) throw new Error(error);
        return (
          <div className="ui raised segment home-info-block">
            <h3>Наша миссия - способствовать бизнес успеху наших клиентов.</h3>
            <h2>Новости IBS Dunice</h2>
            {data.news.length < 0 ? (
              <div>нет новостей</div>
            ) : (
              data.news.map(news => (
                <div key={news.id}>
                  <h3>{news.title}</h3>
                  <div className="news-text">{linkify(news.text)}</div>
                  <em className="news-date">{moment(new Date(news.createdAt)).format('LL')}</em>
                </div>
              ))
            )}
          </div>
        )
      }}
    </Query>
  );

  return (
    <UserContext.Consumer>
      {({ isRole }) => {
        return (
          <ApolloConsumer>
            {client => {
              return <ApolloHooksProvider client={client}>
                <div className="Home container-with-fixed-header">
                  <div className="html ui attached grid custom-reverse">
                    <div className="ui column ten wide">
                      <UserBlock editable={isRole(['admin', 'hrmanager'])} />
                    </div>
                    <SideInfoBlock />
                  </div>
                </div>
              </ApolloHooksProvider>
            }}
          </ApolloConsumer>
        )
      }
      }
    </UserContext.Consumer>
  );
}


export default Home;
