import _ from 'lodash';
import { Link, withRouter } from 'react-router-dom';

import './index.scss';

function HandbookTipsComponent(props) {
    return (
      <div className="handbook-tips handbook__section">
        <h1 className="handbook__title">
          Полезная информация
        </h1>
        <div className="handbook-tips__container">
          {_.chain(props.data)
          .map( ({icon, title, Content}) => 
            <div key={icon} className="handbook-tips__item">
              <div className="handbook-tips__item-header">
                <img className="handbook-tips__item-icon" alt="icon" src={require(`./icons/${icon}.png`).default}/>
                <h2 className="handbook-tips__item-title">{title}</h2>
              </div>
              <div className="handbook-tips__item-content">
                <Content className="handbook-tips__item-content"/>
              </div>
            </div>
          )
          .value()}
        </div>
      </div>
    )
}

export default withRouter(HandbookTipsComponent);