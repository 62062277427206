import React, { Component } from 'react';
import { Query } from 'react-apollo';

import { getErrorMessages } from '../../../helpers';
import { NEWS } from '../../../gql';
import { AdminEditNews } from '../';
import { Loader } from '../../../components';
import './AdminNews.css';

class AdminNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formError: new Map(),
      isOpenEditNews: false,
      selectedNews: {},
    };
  }

  onError = (error) => {
    const errorMessage = getErrorMessages(error);
    const { formError } = this.state;
    formError.set('response', errorMessage[0]);
    this.setState({ formError });
  }
  onOpenModal = (selectedNews = {}) => {
    this.setState({ isOpenEditNews: true, selectedNews });
  }

  closeModals = () => {
    this.setState({ isOpenEditNews: false });
  }

  renderAdminAddOrEditNews = () => (
    <AdminEditNews
      active={this.state.isOpenEditNews}
      onClose={this.closeModals}
      news={this.state.selectedNews}
    />
  )

  render() {
    return (
      <Query
        query={NEWS}
      >
        {({ loading, error, data }) => {
          if (loading) return <Loader />;
          if (error) throw new Error(error);

          const list = data.news || [];
          return (
            <div className="AdminNews">
              {this.state.isOpenEditNews && this.renderAdminAddOrEditNews()}

              <div className="ui raised segment flex">
                <div>
                  <b>Список новостей:</b> вы можете редактировать новость кликнув по ней.
                </div>
                <button
                  className={`ui blue button`}
                  onClick={() => this.onOpenModal()}
                >
                  Добавить новость
                </button>
                {list.map(e => (
                  <div className={`ui raised segment flex pointer ${e.hide && 'hidden-news'}`} key={e.id} onClick={() => this.onOpenModal(e)}>
                    <h3>{e.title}</h3>
                    <div className="editAdminNews">
                      <div>{e.text}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default AdminNews;
