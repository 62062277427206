import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import './ProfileStatusesCard.css';
import { CHANGE_STATUS } from '../../../gql';
import { USER_STATUSES } from '../../../environment';

const ProfileStatusesCard = props => (
  <div className="ProfileStatusesCard">
    <div className="ui segment top attached secondary">
      <h4 className="header">Статус Сотрудника</h4>
    </div>
    <Mutation mutation={CHANGE_STATUS}>
      {(changeStatus, { loading }) => (
        <div className={`ui segment attached without-bottom-border ${loading && 'loading'}`}>
          {USER_STATUSES.map(status => (
            <div className="field ui" key={status.name}>
              <div
                className="ui radio checkbox"
                onClick={() => changeStatus({
                          variables: {
                            user: {
                              status: status.name,
                              id: props.profileOwner.id,
                            },
                          },
                        })
                      }
              >
                <input type="radio" name="status" checked={props.profileOwner.status === status.name} onChange={()=>{}} />
                <label>{status.rusName}</label>
              </div>
            </div>
          ))}
        </div>
      )}
    </Mutation>
  </div>
);

ProfileStatusesCard.defaultProps = {
  profileOwner: {},
};

ProfileStatusesCard.propTypes = {
  profileOwner: PropTypes.shape(),
};

export default ProfileStatusesCard;
