import gql from 'graphql-tag';
import { FRAGMENT_SICK_INFO } from '../';

export const SUBSCRIPTION_SICK_CREATE = gql`
    subscription sickCreate($ownerId: ID!) {
        sickCreate(ownerId: $ownerId) {
            ...SicktInfo
        }
    }
    ${FRAGMENT_SICK_INFO}
`;
