import React from 'react';

import './Forbidden.css';

function Forbidden() {
  return (
    <div className="NotFound">
      <div className="ui image">
        <img alt="Page not found" src="/assets/images/no-access.svg" />
        <div className="text">
          <p>Ресурс не доступен для Вашего подразделения</p>
          <div className="ui large green button" onClick={() => {
            window.location.replace(process.env.REACT_APP_SSO_FE_URL)
          }}>Вернуться на Dunice Space SSO</div>
        </div>
      </div>
    </div>
  );
}

export default Forbidden;
