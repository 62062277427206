import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation, Query } from 'react-apollo';

import { getErrorMessages, showErrors } from '../../../helpers';
import { Loader, Modal } from '../../../components';
import './AdminMenuForm.css';

import { EDIT_DISH, MENU_CATEGORIES, MENU } from '../../../gql';

const ERROR_TEXT = {
  passwordCompare: 'Пароли должны быть одинаковыми',
  onlyLetters: 'Используйте только русские буквы',
  phoneIncorrect: 'Используйте только цифры',
  removeSpace: 'Пожалуйста не используйте пробелы.',
};
const PHONE_REGEXP = /^[0-9]+$/i;// ^[+]?[()\d]{5,25}$/;

class AdminMenuForm extends Component {

  constructor(props) {
    super(props);

    const form = new Map();
    props.dish.name && form.set('name', props.dish.name);
    props.dish.description && form.set('description', props.dish.description);
    form.set('available', props.dish.available || false);
    props.dish.cost && form.set('cost', props.dish.cost);
    props.dish.category && form.set('category', props.dish.category);

    this.state = {
      formError: new Map(),
      form,
      isOpenDropdown: false,
      selectedCategoryId: props.dish.menuCategoryId,
    };
  }

  onSubmit = (editDish) => {
    if (this.state.formError.size || !this.formIsChanged()) return;

    editDish({
      variables: {
        dish: {
          id: this.props.dish.id,
          name: this.state.form.get('name'),
          description: this.state.form.get('description'),
          available: this.state.form.get('available'),
          menuCategoryId: +this.state.selectedCategoryId,
          cost: +this.state.form.get('cost'),
        },
      },
      refetchQueries: [ { query: MENU, variables: { filter: {} } } ],
    });
  }

  onError = (error) => {
    const errorMessage = getErrorMessages(error);
    const { formError } = this.state;
    formError.set('response', errorMessage[0]);
    this.setState({ formError });
  }

  isSpaceEnable = value => !value.trim().length;

  validate = ({ target: { name, value } }) => {
    const { formError, form } = this.state;
    form.set(name, value);
    formError.has('response') && formError.delete('response');

    if (!value) {
      formError.has(name) && formError.delete(name);
      form.delete(name);
    } else {
      switch (name) {
        case 'name':
        case 'description':
          this.isSpaceEnable(value) ?
            formError.set(name, ERROR_TEXT.onlyLetters) :
            formError.delete(name);
          break;
        case 'cost':
          !PHONE_REGEXP.test(form.get(name)) ?
            formError.set(name, ERROR_TEXT.phoneIncorrect) :
            formError.delete(name);
          break;
        default:
          formError.has(name) && formError.delete(name);
      }
    }

    this.setState({ form, formError });
  }

  changeCheckbox = name => {
    const { form } = this.state;
    form.set(name, !form.get(name));

    this.setState({ form });
  }

  formIsChanged = () => {
    if (!this.props.dish.id) return true;
    const isNewName = this.state.form.get('name') !== this.props.dish.name;
    const isNewDescription = this.state.form.get('description') !== this.props.dish.description;
    const isNewCost = +this.state.form.get('cost') !== this.props.dish.cost;
    const isNewAvailable = this.state.form.get('available') !== this.props.dish.available;
    const isNewCategory = this.state.form.get('category') !== this.props.dish.category;
    return isNewName || isNewDescription || isNewCost || isNewAvailable || isNewCategory;
  }

  changeCategory = (category) => {
    const { form } = this.state;
    form.set('category', category.rusName);
    this.setState({ isOpenDropdown: !this.state.isOpenDropdown, form, selectedCategoryId: category.id });
  }

  renderMenuForm = () => (
    <div className="AdminMenuForm">
      <form className={`ui large form  ${this.state.formError.size && 'error'}`} >
        <div className={`field ${this.state.formError.has('name') && 'error'}`}>
          <label htmlFor="name">Название</label>
          {/* <a className="ui grey circular label">?</a> */}
          <div className="ui left icon input">
            <i className="utensils icon" />
            <input
              type="text"
              name="name"
              value={this.state.form.get('name')}
              onChange={this.validate}
            />
          </div>
        </div>
        <div className="two fields">
          <div className={`field ${this.state.formError.has('category') && 'error'}`}>
            <label htmlFor="name">Категория</label>
            <Query
              query={MENU_CATEGORIES}
            >
              {({ client, loading, error, data }) => {
                if (loading) return <Loader />;
                if (error) throw new Error(error);

                const list = data.menuCategories || [];
                return (
                  <div className={`ui selection dropdown ${this.state.isOpenDropdown && 'active visible'}`} onClick={() => this.setState({ isOpenDropdown: !this.state.isOpenDropdown })}>
                    <input type="hidden" name="category" value={this.state.form.get('category')}/>
                    <i className="dropdown icon" />
                    <div className="text">{this.state.form.get('category')}</div>
                    <div className={`menu transition ${this.state.isOpenDropdown && 'visible'}`} tabIndex="-1">
                      {list.map(category => (
                        <div className={`item w${category.rusName === this.state.form.get('category') && 'active selected'}`} data-value={category.rusName} onClick={() => this.changeCategory(category)}>{category.rusName}</div>
                      ))}
                    </div>
                  </div>
                )
              }}
            </Query>
          </div>
          <div className={`field ${this.state.formError.has('cost') && 'error'}`}>
            <label htmlFor="cost">Цена</label>
            <div className="ui left icon input">
              <i className="ruble sign icon" />
              <input
                type="number"
                name="cost"
                value={this.state.form.get('cost')}
                onChange={this.validate}
              />
            </div>
          </div>
        </div>
        <div className={`field ${this.state.formError.has('description') && 'error'}`}>
          <label htmlFor="description">Описание</label>
          <div className="ui left icon">
            <textarea
              rows={3}
              type="text"
              name="description"
              value={this.state.form.get('description')}
              onChange={this.validate}
            />
          </div>
        </div>

        <div className="field ui">
          <div className="ui radio checkbox" onClick={() => this.changeCheckbox('available')} >
            <input type="radio" name="available" checked={this.state.form.get('available')} />
            <label>Доступно для заказа</label>
          </div>
        </div>

        <div className="ui error message">
          <ul className="list">
            {showErrors(this.state.formError)}
          </ul>
        </div>

      </form>
    </div>
  )

  render() {
    const { active, onClose } = this.props;
    const { formError, form } = this.state;
    return (
      <Mutation mutation={EDIT_DISH} onError={this.onError} onCompleted={onClose} >
        {(editDish, { loading, data }) => {
          return (
            <Modal
              active={active}
              onClose={onClose}
              onSuccess={() => this.onSubmit(editDish)}
              header="Добавление блюда!"
              body={this.renderMenuForm()}
              disableButton={!!formError.size || form.size !== 5 || !this.formIsChanged()}
              size="small"
            />
          );
        }}
      </Mutation>
    );
  }
}
AdminMenuForm.defaultProps = {
  dish: {},
  active: false,
  onClose: () => {},
};
AdminMenuForm.propTypes = {
  dish: PropTypes.shape(),
  active: PropTypes.bool,
  onClose: PropTypes.func,
  client: PropTypes.shape().isRequired,
};


export default AdminMenuForm;
