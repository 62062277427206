import gql from 'graphql-tag';
import { FRAGMENT_NEWS_INFO } from '../';

export const NEWS = gql`
    query news($hide: Boolean) {
        news(hide: $hide) {
            ...NewsInfo
        }
    }
    ${FRAGMENT_NEWS_INFO}
`;
