import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation, Query } from 'react-apollo';

import { Checkbox, Modal } from '../../../components';
import { ADD_NEWS, NEWS, EDIT_NEWS } from '../../../gql';
import './AdminEditNews.css';

class AdminEditNews extends Component {

  constructor(props) {
    super(props);
    this.state = {
      news: props.news,
    };
  }

  onSubmit = (editNews) => {
    editNews({
      variables: {
        id: this.props.news.id,
        title: this.state.news.title,
        text: this.state.news.text,
        hide: this.state.news.hide,
      },
      refetchQueries: [
        { query: NEWS },
      ],
    });
  }

  disableSubmin = () => !this.state.news.title || !this.state.news.text

  renderTimeInput = () => {
    const { news } = this.state
    return (
      <form
        className={`ui large form flex-1-to-4 ${false && 'error'}`}
      >
        <div className={`field `}>
          <label htmlFor="title" >Заголовок</label>
          <div className="ui left input">
            <input
              type="text"
              name="title"
              value={news.title}
              onChange={({ target: { value } }) => this.setState({ news: { ...news, title: value } })}
            />
          </div>
        </div>
        <div className={`field `}>
          <label htmlFor="text" className="with-textarea">Текст</label>
          <div className={`ui left input`}>
            <textarea
              rows={3}
              type="text"
              name="text"
              value={news.text}
              onChange={({ target: { value } }) => this.setState({ news: { ...news, text: value } })}
            />
          </div>
        </div>
        <div
          onClick={() => this.setState({ news: { ...news, hide: !news.hide } })}
          className={`description flex-block`}
        >
          <Checkbox checked={!news.hide}/>
          <label>Показывать на странице новостей</label>
        </div>
      </form>
    );
  }

  render() {
    return (
      <Mutation mutation={this.props.news.id ? EDIT_NEWS : ADD_NEWS} onCompleted={this.props.onClose} >
        {(editNews, { loading, data , error }) => {
          return (
            <Modal
              active={this.props.active}
              onClose={this.props.onClose}
              onSuccess={() => this.onSubmit(editNews)}
              header={`${this.props.news.id ? 'Редактирование' : 'Создание'} новости`}
              body={this.renderTimeInput()}
              bodyClass="content"
              size="small"
              disableButton={this.disableSubmin()}
            />
          );
        }}
      </Mutation>
    );
  }
}
AdminEditNews.defaultProps = {
  time: '',
  active: false,
  onClose: () => {},
};
AdminEditNews.propTypes = {
  time: PropTypes.string,
  active: PropTypes.bool,
  onClose: PropTypes.func,
  client: PropTypes.shape().isRequired,
};


export default AdminEditNews;
