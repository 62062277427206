import React from 'react';
import PropTypes from 'prop-types';
import './OrderInfoCard.css';

const OrderInfoCard = (props) => (
  <div className="OrderInfoCard ui card">
    <div className="content">
      <div className="header">Сегодня </div>
      <div className="meta">
        {props.userOrder.length < 1 ?
          <span className="date">вы ничего не заказали</span>
        :
          <React.Fragment>
            <span className="date">вы обедаете за: </span>
            <a className="ui label blue tag" href={`#table-${props.table}`} data-modal="shouldClose">
              {props.table} столом
            </a>
          </React.Fragment>
        }
      </div>
      <div className="description">
        {props.userOrder.map(({ dish, count }) => (
          <div key={dish.id}>
            {count > 1 &&
            <b>({count} порции) </b>
            }
            {dish.name}
          </div>
        ))}
      </div>
    </div>

    <div className="ui buttons bottom attached" >

    </div>

  </div>
);

OrderInfoCard.propTypes = {
  userOrder: PropTypes.arrayOf(PropTypes.shape),
  table: PropTypes.number.isRequired,
};

OrderInfoCard.defaultProps = {
  userOrder: [],
};

export default OrderInfoCard;
