import gql from 'graphql-tag';

export const AVAILABLE_DISH_RATING = gql`
    query availableDishRating {
        availableDishRating {
            dish{
                name
                id
            }
        }
    }
`;
