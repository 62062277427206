import gql from 'graphql-tag';
import { FRAGMENT_ROLE_INFO } from '../';

export const CREATE_ROLE = gql`
    mutation createRole($name: String!, $rusName: String!) {
        createRole(name: $name, rusName: $rusName) {
            ...RoleInfo
        }
    }
    ${FRAGMENT_ROLE_INFO}
`;

export const EDIT_ROLE = gql`
    mutation editRole($id: ID!, $rusName: String!) {
        editRole(id: $id, rusName: $rusName) {
            ...RoleInfo
        }
    }
    ${FRAGMENT_ROLE_INFO}
`;
