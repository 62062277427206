import React from 'react';
import PropTypes from 'prop-types';
import { Mutation, graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';

import { VACATION_STATUSES } from '../../../environment';
import { Modal, VacationList } from '../../../components';
import { CLOSE_VACATION, VACATION_BY_ID } from '../../../gql';

import './AdminVacationsList.css';

class AdminVacationsList extends VacationList {
  static propTypes = {
    history: PropTypes.shape().isRequired,
    location: PropTypes.shape().isRequired,
    variables: PropTypes.shape(),
  }

  static defaultProps = {
    variables: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      isOpenVacationModal: false,
      isSickRejecting: false,
      isSickApproving: false,
      selectedVacation: {
        owner: {},
      },
    };
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (!nextProps.data) return null;
    const { loading } = nextProps.data;
    const selectedVacation = (nextProps.data.vacationById || { owner: {} });
    if (!loading && !state.selectedVacation.id) {
      return { isOpenVacationModal: true, selectedVacation };
    }
    return null;
  }

  showVacationModal = (vacation) => {
    this.props.history.push({
      ...this.props.location,
      search: `?id=${vacation.id}`,
    });
    this.setState({ isOpenVacationModal: true, selectedVacation: vacation })
  };
  hideVacationModal = () => {
    this.props.history.push({
      ...this.props.location,
      search: '',
    });
    this.setState({ isOpenVacationModal: false, selectedVacation: { comment: '', owner: {} }, isSickRejecting: false, isSickApproving: false });
  };

  renderSelectedVacation = () => (
    <div className="full-width">
      <h3 className="ui image header">
        <div className="content">
          {this.state.selectedVacation.owner.firstName}
          <div className="sub header">{this.state.selectedVacation.owner.lastName}</div>
        </div>
      </h3>
      <h4 className={`field`}>
        <label htmlFor="comment">Комментарий</label>
        <div className="ui left icon">
          <textarea
            rows={3}
            type="text"
            name="comment"
            value={this.state.selectedVacation.comment}
            onChange={({target: { value }}) => this.setState({ selectedVacation: { ...this.state.selectedVacation, comment: value } })}
          />
        </div>
      </h4>
    </div>
  )

  modalFooter = () => (
    <Mutation mutation={CLOSE_VACATION} onCompleted={this.hideVacationModal} >
      {(closeVacation) => (
        <div className="actions">
          <div
            className={`ui black deny button ${this.state.isSickRejecting && 'loading'} ${(this.state.isSickApproving || this.state.isSickRejecting || this.state.selectedVacation.status !== VACATION_STATUSES.waiting) && 'disabled'}`}
            onClick={() => {
              this.setState({ isSickRejecting: true });
              closeVacation({
                variables: {
                  id: this.state.selectedVacation.id,
                  comment: this.state.selectedVacation.comment,
                  status: VACATION_STATUSES.rejected,
                },
              });
            }}
          >
            Отклонить
          </div>
          <div
            className={`ui positive right labeled icon button ${this.state.isSickApproving && 'loading'} ${(this.state.isSickApproving || this.state.isSickRejecting || this.state.selectedVacation.status !== VACATION_STATUSES.waiting) && 'disabled'}`}
            onClick={() => {
              this.setState({ isSickApproving: true });
              closeVacation({
                variables: {
                  id: this.state.selectedVacation.id,
                  comment: this.state.selectedVacation.comment,
                  status: VACATION_STATUSES.done,
                },
              });
            }}
          >
            Одобрить
            <i className="checkmark icon" />
          </div>
        </div>
      )}
    </Mutation>
  )
  renderCloseVacationModal = () => (
    <Modal
      active={this.state.isOpenVacationModal}
      onClose={this.hideVacationModal}
      header="Редактировать отпуск"
      body={this.renderSelectedVacation()}
      footer={this.modalFooter()}
    />
  )

  render() {
    return (
      <div className="AdminVacationsList">
        {this.renderCloseVacationModal()}
        {super.render()}
      </div>
    );
  }
}

export default withRouter(graphql(VACATION_BY_ID, {
  skip: ({ history }) => !history.location.search,
  options: ({ history }) =>
    ({
      variables: { id: history.location.search.split('id=')[1] },
    }),
})(AdminVacationsList));
