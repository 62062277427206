import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';

import { getErrorMessages, showErrors, setDataToStorage } from '../../helpers';
import { SmsCode } from '../../components';
import { SIGNUP } from '../../gql';
import './Signup.css';

const ERROR_TEXT = {
  passwordCompare: 'Пароли должны быть одинаковыми.',
  onlyLetters: 'Используйте только русские буквы.',
  phoneIncorrect: 'Используйте только цифры.',
  removeSpace: 'Пожалуйста не используйте пробелы.',
  phoneNotValid: 'Некорректный номер.',
};
const PHONE_REGEXP = /^[0-9]+$/i;// ^[+]?[()\d]{5,25}$/;
const LETTER_REGEXP = /^[А-я,Ё,ё]+$/i;
const styles = {
  smsButton: {
    fontSize: 15,
  },
};

class Signup extends Component {
  static propTypes = {
    history: PropTypes.shape().isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      formError: new Map(),
      form: new Map(),
    };
  }

  onCompleted = ({ signup }) => {
    if (!signup) return;

    const {
      firstName, lastName, id, phone,
    } = signup;
    setDataToStorage({
      token: signup.token,
      user: JSON.stringify({
        firstName, lastName, id, phone,
      }),
    });
    this.state.form.clear();
    this.setState({ form: this.state.form });
    this.props.history.replace(`/profile/${id}`);
  }

  onSubmit = (e, signup) => {
    e.preventDefault();
    if (this.state.formError.size || this.state.form.size < 7) return;

    signup({
      variables: {
        user: {
          login: this.state.form.get('login'),
          password: this.state.form.get('password'),
          firstName: this.state.form.get('firstName'),
          lastName: this.state.form.get('lastName'),
          phone: String(this.state.form.get('phone')),
          code: Number(this.state.form.get('code')),
        },
      },
    });
  }

  onError = (error) => {
    const errorMessage = getErrorMessages(error);
    const { formError } = this.state;
    formError.set('response', errorMessage[0]);
    this.setState({ formError });
  }

  isSpaceEnable = value => value !== value.replace(/\s/g, '');

  validate = ({ target: { name, value } }) => {
    const { formError, form } = this.state;
    form.set(name, value);
    formError.has('response') && formError.delete('response');

    if (!value) {
      formError.has(name) && formError.delete(name);
      form.delete(name);
    } else if (this.isSpaceEnable(value) && name !== 'phone') {
      formError.set(name, ERROR_TEXT.removeSpace);
    } else {
      switch (name) {
        case 'confirmPassword':
        case 'password':
          (form.has('confirmPassword') && form.get('confirmPassword') !== form.get('password')) ?
            formError.set('confirmPassword', ERROR_TEXT.passwordCompare) : formError.delete('confirmPassword');
          break;
        case 'firstName':
        case 'lastName':
          !LETTER_REGEXP.test(form.get(name)) ?
            formError.set(name, ERROR_TEXT.onlyLetters) :
            formError.delete(name);
          break;
        case 'phone':
          form.set(name, value.replace(/\D/g, '').substring(1));
          form.get(name).length === 10 ?
            formError.delete(name) :
            formError.set(name, ERROR_TEXT.phoneNotValid);
          break;
        default:
          formError.has(name) && formError.delete(name);
      }
    }
    this.setState({ form, formError });
  };

  render() {
    return (
      <Mutation mutation={SIGNUP} onCompleted={this.onCompleted} onError={this.onError}>
        {(signup, { loading }) => (

          <div className="Signup">
            <div className="ui center aligned grid">
              <div className="column">

                <form
                  className={`ui large form ${this.state.formError.size && 'error'}`}
                  onSubmit={e => this.onSubmit(e, signup)}
                >
                  <div className="ui segment">
                    <h2 className="ui">
                      Создайте аккаунт
                    </h2>
                    <div className={`field ${this.state.formError.has('login') && 'error'}`}>
                      <div className="ui left icon input">
                        <i className="user icon" />
                        <input
                          type="text"
                          name="login"
                          placeholder="Логин"
                          value={this.state.form.get('login')}
                          onChange={this.validate}
                        />
                      </div>
                    </div>

                    <div className={`field ${this.state.formError.has('password') && 'error'}`}>
                      <div className="ui left icon input">
                        <i className="lock icon" />
                        <input
                          type="password"
                          name="password"
                          placeholder="Пароль"
                          value={this.state.form.get('password')}
                          onChange={this.validate}
                        />
                      </div>
                    </div>
                    <div className={`field ${this.state.formError.has('confirmPassword') && 'error'}`}>
                      <div className="ui left icon input">
                        <i className="lock icon" />
                        <input
                          type="password"
                          name="confirmPassword"
                          placeholder="Повторите пароль"
                          value={this.state.form.get('confirmPassword')}
                          onChange={this.validate}
                        />
                      </div>
                    </div>
                    <div className={`field ${this.state.formError.has('firstName') && 'error'}`}>
                      <div className="ui left icon input">
                        <i className="user icon" />
                        <input
                          type="text"
                          name="firstName"
                          placeholder="Имя"
                          value={this.state.form.get('firstName')}
                          onChange={this.validate}
                        />
                      </div>
                    </div>
                    <div className={`field ${this.state.formError.has('lastName') && 'error'}`}>
                      <div className="ui left icon input">
                        <i className="user icon" />
                        <input
                          type="text"
                          name="lastName"
                          placeholder="Фамилия"
                          value={this.state.form.get('lastName')}
                          onChange={this.validate}
                        />
                      </div>
                    </div>
                    <div className={`field ${this.state.formError.has('phone') && 'error'}`}>
                      <div className="ui left icon input">
                        <i className="phone icon" />
                        <InputMask
                          name="phone"
                          mask="+7 (999) 999-99-99"
                          value={this.state.form.get('phone')}
                          onChange={this.validate}
                          placeholder="Мобильный телефон"
                        />
                      </div>
                    </div>
                    <div className="field">
                      <SmsCode
                        disabled={(this.state.formError.has('phone') || !this.state.form.has('phone'))}
                        code={this.state.form.get('code')}
                        onChange={this.validate}
                        onError={this.onError}
                        phone={this.state.form.get('phone')}
                        buttonStyles={styles.smsButton}
                      />
                    </div>

                    <button
                      type="submit"
                      className={`ui fluid large green submit button ${loading && 'loading'} ${(loading || this.state.formError.size || this.state.form.size < 7) && 'disabled'}`}
                    >
                      Зарегистрироваться
                    </button>
                    <div className="ui login-link">
                      Уже есть аккаунт? <Link to="/login">Войдите</Link>
                    </div>
                  </div>

                  {loading && <p>Загрузка...</p>}
                  <div className="ui error message">
                    <ul className="list">
                      {showErrors(this.state.formError)}
                    </ul>
                  </div>

                </form>
              </div>
            </div>
          </div>
        )}
      </Mutation>
    );
  }
}

export default Signup;
