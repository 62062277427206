import React, { memo, useCallback, useState } from "react";
import './styles.scss';
import UserCard from "../UserCard/UserCard";
import Modal from "../Modal";
import { BEST_USER } from "../../gql";
import { Mutation } from "react-apollo";
import UsersSelector from "../UsersSelector/UsersSelector";
import { ADD_USER_STAR } from "../../gql/bestUser/mutation";

const blockTitles = {
  best_student: "Матрица компетенций",
  best_employee: "IBS Dunice All-Stars",
};

const DuniceStarsBlock = ({ type, editable, data }) => {

  const bestStudents = data?.bestUser?.filter((starUser) => starUser.blockSlug === type);

  const [starUser, setStarUser] = useState(null);
  const [active, setActive] = useState(false);


  const onSubmit = (addStarUser) => {
    addStarUser({
      variables: {
        userId: starUser,
        blockSlug: type,
      },
      refetchQueries: [
        { query: BEST_USER },
      ],
    });
    setActive((prev) => !prev)
  }


  const handleOpenModal = () => setActive((prev) => !prev);

  return (
    <>
      <Mutation mutation={ADD_USER_STAR}>
        {(addStarUser, { loading, data, error }) => {
          return (
            <Modal
              active={active}
              onClose={() => setActive((prev) => !prev)}
              onSuccess={() => onSubmit(addStarUser)}
              header="Добавление карточки"
              body={<UsersSelector changeUser={(user) => setStarUser(user.id)} />}
              size="small"
            />
          );
        }}
      </Mutation>
      <h3 className={`title ui horizontal divider header`}>
        {blockTitles[type]}
        {editable && <button onClick={handleOpenModal} className="ui-green-button"><i className="icon button add" /></button>}
      </h3>
      <div className='ui six cards padding-for-search-block'>
        <div className="handbook-contacts handbook__section">
          <div className="handbook-contacts__container">
            {bestStudents?.map(({ user, id }) => <UserCard blockTitle={blockTitles[type]} id={id} user={user} key={id} editable={editable} />)}
          </div>
        </div>
      </div>
    </>
  )
};

export default memo(DuniceStarsBlock)