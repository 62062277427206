import gql from 'graphql-tag';

export const EDIT_NEWS = gql`
    mutation editNews($id: ID!, $title: String, $text: String, $hide: Boolean) {
        editNews(id: $id,title: $title, text: $text, hide: $hide)
    }
`;
export const ADD_NEWS = gql`
    mutation addNews($id: ID, $title: String!, $text: String!, $hide: Boolean) {
        addNews(id: $id,title: $title, text: $text, hide: $hide)
    }
`;
