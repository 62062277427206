import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import { ImageUploader } from '../../index';
import { showErrors } from '../../../helpers';
import { CREATE_REQUEST } from '../../../gql';
import formValidator from '../../../hoc/formValidator';

import './CreateRequestForm.css';

class CreateRequestForm extends Component {
  static propTypes = {
    changeContent: PropTypes.func.isRequired,
    form: PropTypes.shape(),
  }

  static defaultProps = {
    form: {
      validate: () => {},
      onError: () => {},
      updateState: () => {},
      data: {},
      errors: {},
    },
  }

  constructor(props) {
    super(props);
    this.state = {
      isRequestCreating: false,
    };
  }


  onSubmit = (e, createRequest) => {
    e.preventDefault();
    const { data, errors } = this.props.form;
    if (errors.size) return;
    this.setState({ isRequestCreating: true });

    createRequest({
      variables: {
        request: {
          name: data.get('name'),
          description: data.get('description'),
          days: Number(data.get('days')),
          file: data.get('file'),
        },
      },
    });
  }

  changeFile = (url) => {
    const { data, updateState } = this.props.form;

    if (url) data.set('file', url);
    updateState({ data });
  }

  render() {
    const { changeContent, form } = this.props;
    const disabled = !form.data.get('name') || !form.data.get('description') || !form.data.get('days') || form.errors.size || this.state.isRequestCreating;
    return (
      <div className="CreateRequestForm">
        <div className="ui floating message warning">
          <div className="header">
            Система заявок
          </div>
          <p>Хотите чтобы ваша заявка была выполнена быстро? Убедитесь что она удовлетворяет следующим пунктам:</p>
          <ul className="ui list">
            <li>однозначно трактуется</li>
            <li>понятна (полнота информации для реализации)</li>
            <li>исполнение заявки целесообразно</li>
          </ul>
        </div>
        <h2 className="margin-15">Открыть новую заявку</h2>
        <Mutation
          mutation={CREATE_REQUEST}
          onCompleted={() => {
            form.updateState({ form: new Map() });
            changeContent('all');
          }}
          onError={form.onError}
        >
          {createRequest => (
            <form
              className={`ui large form segment flex-1-to-4 ${form.errors.size && 'error'}`}
              onSubmit={e => this.onSubmit(e, createRequest)}
            >
              <div className={`field ${form.errors.has('name') && 'error'}`}>
                <label htmlFor="name">Название</label>
                <div className="ui left input ">
                  <input
                    placeholder="Прошу заменить стул"
                    type="text"
                    name="name"
                    value={form.data.get('name')}
                    onChange={form.validate}
                  />
                </div>
              </div>
              <div className={`field ${form.errors.has('description') && 'error'}`}>
                <label htmlFor="description" className="with-textarea">Текст заявки</label>
                <div className="ui left input">
                  <textarea
                    rows={3}
                    type="text"
                    placeholder="На моем стуле сломался механизм регуляции спинки"
                    name="description"
                    value={form.data.get('description')}
                    onChange={form.validate}
                  />
                </div>
              </div>

              <div className={`field ${form.errors.has('days') && 'error'}`}>
                <label htmlFor="days">Дней на выполнение</label>
                <div className="ui left input">
                  <input
                    type="number"
                    name="days"
                    placeholder="Сколько, по вашему эту заявку можно делать"
                    value={form.data.get('days')}
                    onChange={form.validate}
                  />
                </div>
              </div>
              <div className={`field ${form.errors.has('file') && 'error'}`}>
                <label htmlFor="phone">Добавить файл</label>
                <div className="extra content">
                  <ImageUploader
                    onChange={this.changeFile}
                    imageType="requestUpload"
                  />

                </div>
              </div>
              <div>
                {form.data.get('file') &&
                <div className="ui small image">
                  <img src={form.data.get('file')} alt="uploads-file" />
                </div>
                }
              </div>

              <button
                type="submit"
                className={`ui large green submit left button ${this.state.isRequestCreating && 'loading'} ${disabled && 'disabled'}`}
              >
                Открыть заявку
              </button>
              <div className="ui error message">
                <ul className="list">
                  {showErrors(form.errors)}
                </ul>
              </div>

            </form>
          )}
        </Mutation>
      </div>
    );
  }
}

export default formValidator(CreateRequestForm);
