import React, { Component } from 'react';

import './AdminSettingsPage.css';

import { AdminSettings, AdminNews, AdminContext, AdminUserRoles } from '../../components';

class AdminSettingsPage extends Component {
  state = {
    tabIndex: 0,
  }

  render() {
    const { tabIndex } = this.state;
    return (
      <div className="AdminSettingsPage">
        <div className="ui styled accordion">
          <div
            className={`title ${tabIndex === 0 && 'active'}`}
            onClick={() => this.setState({ tabIndex: 0 })}
          >
            <i className="dropdown icon" />
            Служебные настройки
          </div>
          {tabIndex === 0 && (
            <div className={`content ${tabIndex === 0 && 'active'}`}>
              <AdminSettings />
            </div>
          )}

          <div
            className={`title ${tabIndex === 2 && 'active'}`}
            onClick={() => this.setState({ tabIndex: 2 })}
          >
            <i className="dropdown icon" />
            Новости фирмы
          </div>
          {tabIndex === 2 && (
            <div className={`content ${tabIndex === 2 && 'active'}`}>
              <AdminNews />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default AdminSettingsPage;
