import gql from 'graphql-tag';
import { FRAGMENT_DISH_FULL_INFO } from '../';

export const SUBSCRIPTION_MENU_EDITED = gql`
    subscription menuEdited {
        menuEdited {
            ...DishFullInfo
        }
    }
    ${FRAGMENT_DISH_FULL_INFO}
`;
