import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import { Mutation } from 'react-apollo';
import { RATE_DISHES } from '../../../gql';
import { Modal } from '../../';
import './DishRatingModal.css';

const values = [1,2,3,4,5,6,7,8,9,10];

class DishRatingModal extends PureComponent {
    static propTypes = {
        isOpen: PropTypes.bool,
        dishes: PropTypes.array,
        hide: PropTypes.func,
        query1: PropTypes.shape().isRequired,
        query2: PropTypes.shape().isRequired,
    }
    static defaultProps = {
        isOpenSickModal: false,
        dishes:{},
        hide: () => {},
    }

    constructor(props) {
        super(props);
        this.state = {
            dishesRates:{},
        };
    }

    componentDidMount(){
        this.props.dishes.forEach((dishRate)=>{
            this.state.dishesRates[dishRate.dish.id] = 7;
        })

    }

    handleChange(event, index){
        let dishesRates = this.state.dishesRates;
        dishesRates = {...dishesRates,...{[this.props.dishes[index].dish.id]: Number(event.target.value)}};

        this.setState({dishesRates})
    }


    modalFooter = () => (
        <Mutation mutation={RATE_DISHES} onCompleted={this.props.hide} >
            {(rateDishes) => (
                <div className="actions">
                    <div  className={`ui neutral right labeled icon button `}
                          onClick={this.props.hide}
                    >
                        Пропустить
                    </div>
                    <div
                        className={`ui positive right labeled icon button `}
                        onClick={() => {
                            rateDishes({
                                variables:{
                                    rates: Object.entries(this.state.dishesRates).map((i)=>({
                                        id: i[0],
                                        rate: i[1]
                                    }))
                                },
                                refetchQueries: [
                                    {query: this.props.query1},
                                    { query: this.props.query2}
                                ],
                            });
                        }}
                    >
                        Оценить
                        <i className="checkmark icon" />
                    </div>
                </div>
            )}
        </Mutation>
    )

    modalBody = () => {
        return (
            <div className="full-width">
                <div className="ui image header">
                    <div className="content white-space-pre-line">
                        {this.props.dishes.map((dishRate,index)=>(
                            <div className="dish-item" key={dishRate.dish.id}>

                                <div className="label">
                                    {dishRate.dish.name}
                                </div>
                                <div>
                                    {values.map((item)=>(<input type="radio"
                                                                name={this.props.dishes[index].dish.id}
                                                                onChange={(event) => this.handleChange(event, index)}
                                                                checked={this.state.dishesRates[this.props.dishes[index].dish.id] == item}
                                                                value={item}
                                                                key={item}/>))}
                                    <div className="value">
                                        {this.state.dishesRates[this.props.dishes[index].dish.id]}
                                    </div>
                                </div>


                            </div>)
                    )}
                    </div>
                </div>
                <div>
            </div>
            </div>
        )
    }

    render() {
        const {
            isOpen,
            hide,
        } = this.props;

        if (!isOpen) return null;
        return (
            <div className="dish-rating-modal">
                <Modal
                    active={isOpen}
                    onClose={hide}
                    header="Оцените заказанные раннее блюда"
                    body={this.modalBody()}
                    footer={this.modalFooter()}
                />
            </div>
        );
    }
}


export default DishRatingModal;
