import { MENU_GROUP_BY_CATEGORY } from '../../gql';

export const updateMenuCache = (client, menu) => {
  const queries = [
    { query: MENU_GROUP_BY_CATEGORY },
  ];
  queries.forEach((query) => {
    try {
      const cache = client.cache.readQuery(query);
      const { dish } = menu;
      // clean menu if passed null
      if (dish === null) {
        cache.menuGroupByCategory = [
          ...cache.menuGroupByCategory.map(category => ({ ...category, menu: [] })),
        ];
      } else {
        cache.menuGroupByCategory = [
          ...cache.menuGroupByCategory.map((category) => {
            if (category.id === dish.menuCategoryId) {
              const filteredMenu = [...category.menu.filter(d => d.id !== dish.id)]
              if (dish.available) {
                filteredMenu.push(dish);
              }
              return { ...category, menu: filteredMenu };
            }
            return category;
          }),
        ];
      }
      client.cache.writeQuery({ ...query, data: cache });
    } catch (error) {}
  });
};
