import gql from 'graphql-tag';
import { FRAGMENT_VACATION_INFO } from '../';

export const CREATE_VACATION = gql`
    mutation createVacation($vacation: VacationCredentials!) {
        createVacation(vacation: $vacation) {
            ...VacationInfo
        }
    }
    ${FRAGMENT_VACATION_INFO}
`;

export const CLOSE_VACATION = gql`
    mutation closeVacation($id: ID!, $status: String!, $comment: String) {
        closeVacation(id: $id, status: $status, comment: $comment) {
            ...VacationInfo
        }
    }
    ${FRAGMENT_VACATION_INFO}
`;