import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/ru';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';

import './CurrentStatistic.css';
import { titleCase, caseOfMonth } from '../../helpers';

const getCurrentMonth = () => moment.localeData('ru').months()[moment().month()];

const getPageNameByType = (type) => {
  if (type === 'currentOrders') return 'заказов';
  if (type === 'currentSicks') return 'болезней';
  if (type === 'currentVacations') return 'отпусков';
}
const getLinkByType = (type) => {
  if (type === 'currentOrders') return '/orderHistory';
  if (type === 'currentSicks') return '/sickHistory';
  if (type === 'currentVacations') return '/vacationHistory';
}
const getInfoByType = (type, { month }) => {
  if (type === 'currentOrders') {
    return <div className="wide-content">
      {console.log(month)}
      Вычет из вашей зарплаты за {titleCase(getCurrentMonth())}: <b>{Intl.NumberFormat().format(month.totalCost - month.payByCompany)}р</b>
      {month.additionalCost > 0 && <b className="red"> +{month.additionalCost}p</b>}
    </div>;
  }
  if (type === 'currentSicks') {
    return <div className="wide-content">Количество дней на больничном в {caseOfMonth(getCurrentMonth())}: <b>{month}</b></div>;
  }
  if (type === 'currentVacations') {
    return <div className="wide-content">Количество дней в отпуске в {caseOfMonth(getCurrentMonth())}: <b>{month}</b></div>;
  }
}

const CurrentStatistic = (props) => (
  <Query
    query={props.query}
  >
    {({loading, error, data}) => {
      if (loading) return <div />;

      const { [props.type]: currentData = [] } = data || {};

      return (
        <div className="CurrentStatistic ui card">
          {props.type === 'currentOrders' &&
            <div className="content pointer">
              <Link to={getLinkByType(props.type)}>
                <div className="wide-content">Посмотреть историю {getPageNameByType(props.type)}<i
                  className="chevron right icon"/></div>
              </Link>
            </div>
          }

          <div className="content">
            {getInfoByType(props.type, currentData)}
          </div>
        </div>
      )
    }}
  </Query>
);

CurrentStatistic.propTypes = {
  type: PropTypes.string.isRequired,
  query: PropTypes.shape().isRequired,
};

CurrentStatistic.defaultProps = {
};

export default CurrentStatistic;
