import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import moment from 'moment';

import './YearsSelector.css';

const currentYear = moment().year();

class YearsSelector extends Component {
  static propTypes = {
    changeYear: PropTypes.func,
    year: PropTypes.string.isRequired,
    className: PropTypes.string,
  }
  static defaultProps = {
    changeYear: () => {},
    className: '',
  }

  constructor(props) {
    super(props);
    const startYear = 2012
    this.refInput = React.createRef();
    this.containerInput = React.createRef();
    this.state = {
      isOpenDropdown: false,
      years: Array.from({ length: currentYear - startYear + 3 }, (v, k) => (k + startYear)).reverse(),
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }
  handleClick = ({ target }) => {
    if (this.state.isOpenDropdown) {
      if (this.containerInput.current.contains(target)) return;
      this.setState({ isOpenDropdown: false });
    }
  }

  onChangeYear = (year) => {
    this.setState({ isOpenDropdown: !this.state.isOpenDropdown });
    this.props.changeYear(year);
  }

  render() {
    return (
      <div className="YearsSelector">
        <div
          className={`ui selection search dropdown  ${this.props.className} ${this.state.isOpenDropdown && 'active visible'}`}
          onClick={() => this.setState({ isOpenDropdown: !this.state.isOpenDropdown })}
          ref={this.containerInput}
        >
          <input
            type="text"
            name="user"
            className="search"
            value={this.props.year}
            autoComplete="off"
            ref={this.refInput}
          />

          <i className="dropdown icon" />
          <div className={`menu transition ${this.state.isOpenDropdown && 'visible'}`}>
            {this.state.years.map(year => (
              <div
                key={year}
                className={`item ${year === (this.props.year) && 'active selected'}`}
                data-value={year}
                onClick={() => this.onChangeYear(year)}
              >
                {year}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default YearsSelector;
