import gql from 'graphql-tag';
import { FRAGMENT_ROLE_INFO } from '../';

export const ROLES = gql`
    query roles {
        roles {
            ...RoleInfo
        }
    }
    ${FRAGMENT_ROLE_INFO}
`;
