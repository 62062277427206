import moment from 'moment';
import { PAGINATION_EVENTS } from '../../gql';

export const updateEventsCache = (client, event) => {
  const queries = [
    { query: PAGINATION_EVENTS, variables: { filter: { search: '', startDate: moment().startOf('day') } } },
  ];
  queries.forEach((query) => {
    try {
      const cache = client.cache.readQuery(query);
      // add if not exists in cache
      if (!cache.paginationEvents.results.find(({ id }) => id === event.id)) {
        cache.paginationEvents.results.unshift(event);
      }
      client.cache.writeQuery({ ...query, data: cache });
    } catch (error) {}
  });
};
